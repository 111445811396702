import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { RoleActionMenu } from "./RoleActionMenu";
import { fLocalTime,yearFirstDateTimeFormat } from "src/utils/formatTime";
import { AccessCheckBox } from "./AccessCheckBox";

export const roleListColumns = (isReadOnly)=>(
  [
    {
      field: "roleName",
      headerName: "Name",
      flex: 1,
      renderCell: ({ value, id }) => (<>
       {isReadOnly?value: <Link underline="none" component={RouterLink} to={`${id}`}>
          {value}
        </Link>}
      </>
      ),
    },
    {
      field: "createTime",
      headerName: "Creation Time",
      flex: 1,
      type: "date",
      renderCell: ({ value }) => (
        <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
          <span>{yearFirstDateTimeFormat(value)}</span>
        </Tooltip>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "Actions",
      width: 70,
      sortable: false,
      renderCell: RoleActionMenu,
    },
  ]
)

export const getRoleDefaultValue = () => ({
  companyName: "",
  roleName: "",
  feature: "",
});

export const featureColumns = [
  {
    field: "featureName",
    headerName: "Feature Name",
    flex: 1,
  },
  {
    field: "view",
    headerName: "Read",
    flex: 1,
    renderCell: AccessCheckBox,
  },
  {
    field: "create",
    headerName: "Create",
    flex: 1,
    renderCell: AccessCheckBox,
  },
  {
    field: "update",
    headerName: "Update",
    type: "date",
    flex: 1,
    renderCell: AccessCheckBox,
  },
  {
    field: "delete",
    headerName: "Delete",
    type: "date",
    flex: 1,
    renderCell: AccessCheckBox,
  },
];
