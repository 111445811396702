export const base64toBlob = (data) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64

  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
      out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};