import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Fragment, useEffect, useState } from "react";
import { Loader } from "src/components/Loader";
import { useList, useMutate } from "src/hooks/useFetch.hook";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { getTeamUrl, updateBulkPayoutsUrl } from "src/react-query/endPoints";
import { is } from "src/util/is";
import TransactionsPayoutForm from "./TransactionsPayoutForm";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1000px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflow: "auto",
  height: "570px",
};

const steps = [
  "SELECT THE CPO",
  "SELECT BULKID TO UPDATE",
  "FINAL PAYOUT ESTIMATION",
  "TRIGGER MAIL TO CPO",
];
const TransactionPayoutUpdModal = ({ open, onClose }) => {
  const [tableData, setTableData] = useState();
  const [isIntialDataFetched, setIsIntialDataFetched] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [bulkIdInfoToUpdate, setBulkIdInfoToUpdate] = useState({});
  const [bulkIdInfoRecepients, setBulkIdInfoRecepients] = useState({});

  const { data: bulkPayouts, isFetching } = useList("payoutBulkCal", open);
  const { teamId: currTeamId } = useSelectedTeam();
  const { mutate, isLoading } = useMutate("payoutBulkUpd");
  //CHECKS FOR THE PAYOUTS DETAILS MODAL
  const hasReadOnlyTransactionsAccess = useAccess(FEATURE.TRANSACTION, "R");
  const hasFullTransactionsAccess = useAccess(FEATURE.TRANSACTION, "FA");
  const hasReadOnlyPayoutsAccess = useAccess(FEATURE.FINANCE, "R");
  const hasFullPayoutsAccess = useAccess(FEATURE.FINANCE, "FA");

  const handleModalClose = (e, reason) => {
    if (reason === "backdropClick") {
      // Prevent closing on backdrop click
      return;
    }
    setActiveStep(0);
    setTableData();
    onClose();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!isFetching) {
      if (!is.empty(bulkPayouts)) {
        const data = { ...bulkPayouts };
        const bulkIds = Object.entries(data).map(([key, _]) => key);
        setTeamOptions(bulkIds);
        setBulkIdInfoToUpdate({
          selectedBulkId: bulkIds[0],
          teamsLeftOutArr: [],
        });
        setTableData(data);
      }
      setIsIntialDataFetched(true);
    }
  }, [isFetching]);

  const handleUpdateCall = () => {
    console.log("data", bulkIdInfoToUpdate);
    const { selectedTeam, selectedBulkId } = bulkIdInfoToUpdate;

    const selectedTeamId = tableData[selectedTeam][0].teamId;
    console.log(
      `final data set ${selectedBulkId} and ${selectedTeam} and ${selectedTeamId}`
    );

    const mutateURL = getTeamUrl(
      currTeamId,
      updateBulkPayoutsUrl,
      selectedBulkId
    );

    mutate(
      {
        url: mutateURL,
        method: "POST",
        body: {
          status: "PAYOUTCOMPLETED",
          CPO: selectedTeamId,
        },
      },
      {
        onSuccess: () => {
          handleNext();
        },
      }
    );
  };

  const handleTriggerEmail = () => {
    console.log("email triggered");
    const { selectedTeam, selectedBulkId } = bulkIdInfoToUpdate;
    const emails = bulkIdInfoRecepients;

    console.log(
      `final data set ${selectedBulkId} and ${selectedTeam} and ${emails}`
    );

    const mutateURL = `${getTeamUrl(
      currTeamId,
      updateBulkPayoutsUrl,
      selectedBulkId
    )}/mail`;

    mutate(
      {
        url: mutateURL,
        method: "POST",
        body: {
          emails,
          CPOId: tableData[selectedTeam][0].teamId,
        },
      },
      {
        onSuccess: () => {
          handleModalClose();
        },
      }
    );
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isIntialDataFetched && !is.empty(tableData) ? (
        <Box sx={style}>
          <Typography variant="h4" align="center">
            Transactions Details With Payouts Calculated{" "}
          </Typography>
          <Stack
            // spacing={2}
            rowGap={2}
            marginTop={1}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            sx={{ width: "100%", height: "90%" }}
          >
            <Fragment>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <TransactionsPayoutForm
                teamOptions={teamOptions}
                data={tableData}
                activeStep={activeStep}
                allSteps={steps}
                fillFinalData={setBulkIdInfoToUpdate}
                fillEmailIds={setBulkIdInfoRecepients}
              />

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0 || activeStep === steps.length - 1}
                  onClick={handleBack}
                  variant="contained"
                  startIcon={<Icon icon="lets-icons:back" />}
                  sx={{ mr: 1 }}
                >
                  BACK
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps.length - 2 ? (
                  <LoadingButton
                    size="medium"
                    type="submit"
                    loading={isLoading}
                    onClick={handleUpdateCall}
                    variant="contained"
                    endIcon={<Icon icon="material-symbols:upgrade-rounded" />}
                    disabled={
                      hasReadOnlyTransactionsAccess ||
                      !hasFullTransactionsAccess ||
                      !hasFullPayoutsAccess
                    }
                  >
                    UPDATE
                  </LoadingButton>
                ) : activeStep === steps.length - 1 ? (
                  <LoadingButton
                    size="medium"
                    type="submit"
                    loading={isLoading}
                    onClick={handleTriggerEmail}
                    variant="contained"
                    endIcon={<Icon icon="material-symbols:upgrade-rounded" />}
                    disabled={
                      hasReadOnlyTransactionsAccess ||
                      !hasFullTransactionsAccess ||
                      !hasFullPayoutsAccess
                    }
                  >
                    Trigger Email
                  </LoadingButton>
                ) : (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    endIcon={<Icon icon="lucide:forward" />}
                  >
                    NEXT
                  </Button>
                )}
              </Box>
            </Fragment>
          </Stack>
        </Box>
      ) : (
        <Stack
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100vh"}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              position: "relative",
              textAlign: "center",
              maxWidth: "400px", // Optional: limit the width of the Paper component
            }}
          >
            <Box position="absolute" top="8px" right="8px">
              <IconButton aria-label="delete" size="medium" onClick={handleModalClose}>
                <Icon icon="mdi:close" />
              </IconButton>
            </Box>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {"No Transactions to show here"}
            </Typography>
          </Paper>
        </Stack>
      )}
    </Modal>
  );
};

export default TransactionPayoutUpdModal;
