import { useEffect, useCallback, useState, useMemo } from "react";
import { useFormik, Form, FormikProvider, Field } from "formik";
import {
  Stack,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Select,
  FormHelperText,
  Card,
  Switch,
  IconButton,
  Button,
  Autocomplete,
  Box,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import * as Yup from "yup";
import PlusCircleFill from "@iconify/icons-eva/plus-circle-fill";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import downloadFill from "@iconify/icons-eva/download-fill";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useMatch,
  useLocation,
} from "react-router-dom";
import { Icon } from "@iconify/react";
import AlertCircleFill from "@iconify/icons-eva/alert-circle-fill";
import PlusFill from "@iconify/icons-eva/plus-fill";
import TrashOutline from "@iconify/icons-eva/trash-2-outline";
// import Countries from "../../data/countries.json";
import ConnectorType from "../../data/connectorType.json";
import { chargerFormValidationSchema } from "../../util/validationSchemas";
import "../../styles/charger.css";
import { useAccess } from "src/hooks/useAccess.hook";
import {
  ChargerFormTabs,
  NOTIFICATION_CONSTANTS,
  getChargerDefaultValues,
  hideForTabs,
  reportIssuesColumn,
} from "./charger.constants";
import {
  chargerIdUrl,
  chargerUrl,
  getTeamUrl,
  viewLogsUrl,
  logFilterData,
  getTeamV3Url
} from "src/react-query/endPoints";
import { Loader } from "../Loader";
import { is } from "src/util/is";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { useGetDetail, useMutate, useList } from "src/hooks/useFetch.hook";
import { useDispatch, useSelector } from "react-redux";
import enums, { FEATURE } from "src/util/enums";
import Address from "src/common/components/Address";
import ImgPreviewModal from "../ImagePreviewModal/ImgPreviewModal";
import { getIcon } from "src/utils/utility";

//actions
import { alertSlice } from "src/store/alert/alert.slice";
import { RootTabItemStyles, RootTabStyle } from "src/theme/overrides/Tabs";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { fetchInfinite } from "src/react-query/fetchWithError";
import { LogsData } from "./ViewLogsModal";
import { RemoteOperationForm } from "./RemoteOperation/RemoteOperationForm";
import { createYupSchema } from "src/utils/yupCreatorSchema";
import { remoteOperationConfig } from "./RemoteOperation/remoteOperationConfig";
import { remoteOperations } from "./RemoteOperation/remote.constants";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
import RemoteOpsStatusModal from "./RemoteOperation/RemoteOpsStatusModal";
import { remoteOpsStatusSlice } from "src/store/remoteOpsStatus/remoteOpsStatus.slice";
import { GridLabel } from "../Transactions/TransactionView";
import { BaseTable } from "../BaseTable/BaseTable";
import axios from "../../util/axios";
import { isValidTimeInterval } from "src/utils/formatTime";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
  DateTimePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format } from "date-fns";
import RefreshFill from "@iconify/icons-eva/refresh-fill";
import { downloadCSV } from "src/utils/convertAnddownloadCsv";
import { WEB_APP_ENDPOINT } from "../../react-query/endPoints";
import { PAYMENT_GATEWAY, PAYMENT_TYPE } from "src/util/enums";

const {
  actions: { setAlert },
} = alertSlice;

const initialConnectors = [
  { connectorId: "", kw: "", volt: "", connectorType: "", connectorName: "" },
];

const initialFaq = [{ question: "", answer: "" }];

const downloadRazorPayQR = (url) => {
  const link = document.createElement("a");
  link.download = "PaymentQR.jpeg";
  link.href = url;
  link.click();
};

const downloadCMQR = (value) => {
  const qrCode = document.getElementById(`chargerqr-${value}`);
  const svgData = new XMLSerializer().serializeToString(qrCode);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.onload = () => {
    const leftPadding = 40;
    const bottomPadding = 40;
    const textHeight = 30;
    const padding = 30;

    canvas.width = img.width + leftPadding * 2;
    canvas.height = img.height + padding + textHeight + bottomPadding;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw some text above the QR code
    let newText = `CHARGER - ${value}`;
    ctx.font = "bold 20px Arial";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.fillText(newText, canvas.width / 2, textHeight);

    ctx.drawImage(img, leftPadding, textHeight + padding / 2);

    const pngFile = canvas.toDataURL("image/jpeg");
    const downloadLink = document.createElement("a");
    downloadLink.download = `${value}.jpeg`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

const isValidDate = (input) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateRegex.test(input)) {
    return false;
  } else return true;
};
export default function ChargerForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const hasReadOnlyAccess = useAccess(FEATURE.CHARGER, "R");
  const { data: priceGroups } = useList("priceGroups");
  const isCreateCharger = useMatch("/charger/new");
  const isReadOnly = hasReadOnlyAccess && !isCreateCharger;
  const { teamId } = useSelectedTeam();
  const [logDetailData, setLogDetailData] = useState();
  const { data: charger, isFetching } = useGetDetail("charger", { id });
  const [timeInterval, setTimeInterval] = useState({
    startDate: "",
    endDate: "",
  });
  const [searchData, setSearchData] = useState(true);
  const {
    data: logsData,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching: isLogsFetching,
    isRefetching: isLogsRefetching,
  } = useInfiniteQuery(
    [
      "viewLogs",
      { url: getTeamUrl(teamId, viewLogsUrl(charger?.chargepoint_id)) },
    ],
    fetchInfinite,
    {
      enabled: !!charger,
      getNextPageParam: (lastPage, pages) => {
        if (searchData) {
          if (lastPage.length === 0) {
            return;
          }
          return pages.length + 1;
        }
      },
    }
  );

  const handleRefetch = async () => {
    setSearchData(true);
    await refetch();
    setLogDetailData(logsData);
  };

  const errorNotification = () => {
    return dispatch(
      setAlert({
        open: true,
        message: "Please select a valid time interval",
        severity: enums.SEVERITY.ERROR,
      })
    );
  };

  useEffect(() => {
    if (logsData) {
      setLogDetailData(logsData);
    }
  }, [logsData]);

  function getTime(time) {
    let date = new Date(time).toUTCString();
    return date.split(" ")[4];
  }

  const tabsToShow = useMemo(() => {
    if (id) {
      return ChargerFormTabs;
    }
    return ChargerFormTabs.filter(
      (tab) =>
        tab.value !== "config" &&
        tab.value !== "logs" &&
        tab.value !== "remote operations" &&
        tab.value !== "report_incidents"
    );
  }, [id]);

  const { mutate, isLoading } = useMutate("chargers");
  const { mutate: genQRMutate, isLoading: isGenQR } = useMutate("generateQR");
  const {
    tabOpened,
    tabOpenedFor,
    data: reduxData,
  } = useSelector(({ tabsCurrentlyOpenedFor }) => tabsCurrentlyOpenedFor);
  const { modalOpen, remoteOperationPerformed, operationDetails } = useSelector(
    ({ remoteOpStatus }) => remoteOpStatus
  );
  const [selectedTab, setSelectedTab] = useState(
    tabOpenedFor === "charger" && tabOpened ? tabOpened : "general"
  );
  const [razorPayQRCodePreview, setRazorPayQRCodePreview] = useState({
    type: "payment",
    open: false,
    value: "",
    buttons: [
      {
        btnLabel: "Download",
        action: downloadRazorPayQR,
        params: true,
      },
      {
        btnLabel: "Close",
        action: function () {
          setRazorPayQRCodePreview({ ...razorPayQRCodePreview, open: false });
        },
      },
    ],
  });

  useEffect(() => {
    if (reduxData.dataForLogs) {
      let startTime = reduxData.dataForLogs.starttime;
      let stopTime = reduxData.dataForLogs.stoptime;
      setTimeInterval((prev) => ({ startDate: startTime, endDate: stopTime }));
      fetchLogFilterData(startTime, stopTime);
    }
  }, [reduxData]);

  const fetchLogFilterData = async (sDate, eDate) => {
    let startDate =
      sDate && sDate !== ""
        ? format(new Date(sDate), "yyyy-MM-dd")
        : eDate && eDate !== ""
        ? format(new Date(eDate), "yyyy-MM-dd")
        : "";
    let endDate =
      eDate && eDate !== "" ? format(new Date(eDate), "yyyy-MM-dd") : "";
    if (
      startDate &&
      endDate &&
      isValidTimeInterval({ start: sDate, end: eDate })
    ) {
      setSearchData(false);
      let timeStart = sDate === "" ? "00:00:00" : getTime(sDate);
      let timeEnd = getTime(eDate);
      try {
        const response = await axios.get(
          logFilterData(
            teamId,
            charger.chargepoint_id,
            startDate,
            endDate,
            timeStart,
            timeEnd
          )
        );
        if (response.data) {
          setLogDetailData((prev) => ({
            ...prev,
            pages: [response.data.data],
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else errorNotification();
  };

  const [CMQRCodePreview, setCMQRCodePreview] = useState({
    type: "charger",
    open: false,
    templateVal: `${WEB_APP_ENDPOINT}/charger/`,
    value: "",
    buttons: [
      {
        btnLabel: "Download",
        action: downloadCMQR,
        params: true,
      },
      {
        btnLabel: "Close",
        action: function () {
          setCMQRCodePreview({ ...CMQRCodePreview, open: false });
        },
      },
    ],
  });

  const hasConnectorsAdded = useCallback((connectors = []) => {
    return connectors.some(
      (connector) =>
        (connector.volt !== "" && connector.connectorType !== "") ||
        (connector.kw !== "" && connector.connectorType !== "")
    );
  }, []);

  const handleRemoteOpsStatusModalClose = () => {
    dispatch(
      remoteOpsStatusSlice.actions.setRemoteOps({
        open: false,
        remoteOperationPerformed: null,
        operationDetails: { chargerId: null, response: null },
      })
    );
  };

  const onSubmit = (chargerData, { setSubmitting }) => {
    let hasConnectors = !0;
    const {
      actions: { setAlert },
    } = alertSlice;
    const {
      street,
      houseNumber,
      zipCode,
      city,
      country,
      state,
      connectors,
      locationLatitude,
      locationLongitude,
    } = chargerData;
    if (is.empty(locationLatitude) || is.empty(locationLongitude)) {
      dispatch(
        setAlert({
          open: true,
          message: "Address is required for the Charge Point",
          severity: enums.SEVERITY.ERROR,
        })
      );
      setSubmitting(false);
      return;
    }
    chargerData.address = {
      street,
      houseNumber,
      zipCode,
      city,
      country,
      state,
    };
    chargerData.insertConnectorStatusAfterTransactionMsg = Number(
      chargerData.insertConnectorStatusAfterTransactionMsg
    );
    chargerData.employeeOrResident = Number(chargerData.employeeOrResident);
    chargerData.payWithWallet = Number(chargerData.payWithWallet);
    chargerData.payWithCard = Number(chargerData.payWithCard);
    chargerData.notificationSettings = {
      notificationRecipients: chargerData.notificationRecipients,
      enableNotifications: Number(chargerData.enableNotifications),
      OcppStationBooted: Number(chargerData.OcppStationBooted),
      OcppStationStatusFailure: Number(chargerData.OcppStationStatusFailure),
      OcppStationWebSocketConnected: Number(
        chargerData.OcppStationWebSocketConnected
      ),
      OcppStationWebSocketDisconnected: Number(
        chargerData.OcppStationWebSocketDisconnected
      ),
      OcppTransactionStarted: Number(chargerData.OcppTransactionStarted),
      OcppTransactionEnded: Number(chargerData.OcppTransactionEnded),
    };
    const mutateObject = {
      url: getTeamUrl(teamId, chargerUrl),
      method: "POST",
    };
    if (!is.nullOrUndefined(id) && !isCreateCharger) {
      // update charger point if id is undefined
      mutateObject.method = "PUT";
      mutateObject.url = getTeamUrl(teamId, chargerIdUrl(id));
    }

    if (connectors.length === 1) {
      hasConnectors = hasConnectorsAdded(connectors);
      const hasConnectorsHaveNegativeValues = connectors.some((connector) => {
        return Number(connector.kw) < 0 || Number(connector.volt) < 0;
      });

      if (hasConnectorsHaveNegativeValues) {
        dispatch(
          setAlert({
            open: true,
            message: "Kilowatt and Volt cannot be negative",
            severity: enums.SEVERITY.ERROR,
          })
        );
        setSubmitting(false);
        return;
      }

      if (!hasConnectors) {
        dispatch(
          setAlert({
            open: true,
            message: "At least 1 connector is required",
            severity: enums.SEVERITY.ERROR,
          })
        );
        setSubmitting(false);
        return;
      }
    }

    if (connectors.length > 0 && hasConnectors) {
      const arePricegroupsPresent = connectors.every(
        ({ priceGroupId }) => !is.empty(priceGroupId)
      );
      if (!arePricegroupsPresent) {
        //TO CHECK WHETHER ALL THE CONNECTORS HAVE A PRICEGROUP SELECTED
        dispatch(
          setAlert({
            open: true,
            message:
              "Every connector should have a pricegroup specified for it",
            severity: enums.SEVERITY.ERROR,
          })
        );
        setSubmitting(false);
        return;
      }
      chargerData.connectors = connectors.map((connector, index) => {
        if (!connector?.connectorName) {
          connector["connectorName"] = index + 1;
        }
        return { ...connector, connectorId: index + 1 };
      });
    }

    chargerData.photos = !is.empty(chargerData.photos)
      ? chargerData.photos.filter((photo) => !is.empty(photo))
      : [];
    chargerData.amenities = !is.empty(chargerData.amenities)
      ? chargerData.amenities.filter((amenity) => !is.empty(amenity))
      : [];
    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: chargerData,
      },
      // { onSuccess:()=>successHandler(chargerData) }
      {
        onSuccess: (data) => {
          dispatch(
            tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
              detailsId: "",
              tabOpenedFor: "",
              tabOpened: "",
              data: {},
            })
          );
          navigate("/charger");
        },
      }
    );
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: getChargerDefaultValues({ teamId }),
    validationSchema: chargerFormValidationSchema,
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  const handleAddAndDeleteConnectors = (key = null) => {
    const { connectors } = values;
    if (is.null(key) && connectors.length !== 4) {
      setFieldValue("connectors", connectors.concat(initialConnectors));
    }
    if (!is.null(key)) {
      setFieldValue(
        "connectors",
        connectors.filter((c, i) => i !== key)
      );
    }
  };

  const handleAddAndDeleteFAQ = (key = null) => {
    const { faq } = values;
    if (is.null(key) && faq.length !== 10) {
      setFieldValue("faq", faq.concat(initialFaq));
    }

    if (!is.null(key)) {
      setFieldValue(
        "faq",
        faq.filter((c, i) => i !== key)
      );
    }
  };

  const handleShowRazorPayQRCode = (QRCodeUrl) => {
    const gateway = PAYMENT_GATEWAY[charger.paymentGatewayId];
    if (PAYMENT_TYPE.QRCODE === gateway.type) {
      const updatedPreviewObject = {
        ...razorPayQRCodePreview,
        value: QRCodeUrl,
        open: true,
      };
      setRazorPayQRCodePreview(updatedPreviewObject);
    } else {
      window.location.href = QRCodeUrl;
    }
  };

  const handleShowCMQRCode = (chargerId) => {
    const updatedPreviewObject = {
      ...CMQRCodePreview,
      value: chargerId,
      open: true,
    };

    setCMQRCodePreview(updatedPreviewObject);
  };

  const generateQRCode = (connectorId) => {
    const url =
      charger.paymentGatewayId === 1
        ? getTeamUrl(
            teamId,
            `/charger/${charger?.chargepoint_id}/${
              PAYMENT_GATEWAY[charger.paymentGatewayId].endpoint
            }`
          )
        : getTeamV3Url(teamId, `/charger/${charger?.chargepoint_id}/generate_payment_link`);
    genQRMutate(
      {
        url,
        method: "POST",
        body: { connectorId },
      },
      { onSuccess: () => queryClient.invalidateQueries("charger") }
    );
  };

  useEffect(() => {
    if (charger && priceGroups) {
      const {
        address,
        notificationSettings,
        insertConnectorStatusAfterTransactionMsg,
        employeeOrResident,
        payWithWallet,
        payWithCard,
        status,
        ...rest
      } = charger;
      const { zipcode } = address;
      dispatch(
        tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
          detailsId: charger.chargepoint_id,
          tabOpenedFor: "charger",
          tabOpened,
          data: reduxData,
        })
      );

      const visibiltyOnPortal = !!charger.showOnPortal;
      let formValues = {
        ...rest,
        ...address,
        showOnPortal: visibiltyOnPortal,
        zipCode: zipcode,
        insertConnectorStatusAfterTransactionMsg:
          !!insertConnectorStatusAfterTransactionMsg,
        employeeOrResident: !!employeeOrResident,
        payWithWallet: !!payWithWallet,
        payWithCard: !!payWithCard,
        status: status === "A" ? "Active" : "Inactive",
      };
      if (notificationSettings) {
        const {
          settings_pk,
          enableNotifications,
          notificationFeatures,
          notificationRecipients,
        } = notificationSettings;

        let allNotificationFeatures;
        if (!is.empty(notificationFeatures)) {
          const selectedFeatures = notificationFeatures.split(",");
          allNotificationFeatures = NOTIFICATION_CONSTANTS.reduce(
            (allNotificationFeatures, feature) => {
              allNotificationFeatures = {
                ...allNotificationFeatures,
                [feature]: false,
              };
              if (selectedFeatures.includes(feature)) {
                allNotificationFeatures[feature] = true;
              }
              return allNotificationFeatures;
            },
            {}
          );
        } else {
          allNotificationFeatures = NOTIFICATION_CONSTANTS.reduce(
            (allNotificationFeatures, feature) => {
              allNotificationFeatures = {
                ...allNotificationFeatures,
                [feature]: false,
              };
              return allNotificationFeatures;
            },
            {}
          );
        }

        formValues = {
          ...formValues,
          ...allNotificationFeatures,
          settings_pk,
          notificationRecipients,
          enableNotifications: !!enableNotifications,
        };
      } else {
        formValues = {
          ...formValues,
          enableNotifications: false,
          notificationRecipients: "",
          OcppStationBooted: false,
          OcppStationStatusFailure: false,
          OcppStationWebSocketConnected: false,
          OcppStationWebSocketDisconnected: false,
          OcppTransactionStarted: false,
          OcppTransactionEnded: false,
        };
      }
      // console.log(formValues);
      resetForm({ values: formValues });
    }
  }, [charger, priceGroups, resetForm, dispatch, tabOpened]);

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };

  /*
  REMOTE OPERATIONS ESSENTIALS
  */
  const [operation, setOperation] = useState("ChangeAvailability");

  const validationSchema = useMemo(() => {
    const schema =
      remoteOperationConfig[operation]?.reduce(createYupSchema, {}) ?? {};
    return operation && Yup.object().shape(schema);
  }, [operation]);

  const initialValues = useMemo(() => {
    const values =
      remoteOperationConfig[operation]?.reduce(
        (acc, { fieldName, value }) => ({ ...acc, [fieldName]: value ?? "" }),
        {}
      ) ?? {};
    return values;
  }, [operation]);

  const queryKeysDropdown = useMemo(() => {
    const values =
      remoteOperationConfig[operation]?.reduce(
        (acc, { queryKey, usePk, useEmail }) => {
          return queryKey ? [...acc, { queryKey, usePk, useEmail }] : acc;
        },
        []
      ) ?? [];
    return values;
  }, [operation]);

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreateCharger && is.empty(charger)) {
    navigate("/dashboard");
    return null;
  }

  function convertArrayOfObjectsToCSV(arrayOfObjects) {
    if (
      !arrayOfObjects ||
      !Array.isArray(arrayOfObjects) ||
      arrayOfObjects.length === 0
    ) {
      return "";
    }
    const csvContent = arrayOfObjects.map((objects) => {
      const rows = objects.map((object) => {
        const values = Object.values(object).map((value) => `"${value}"`);
        return values.join("; ");
      });
      return rows.join("\n");
    });

    return csvContent.join("\n\n");
  }

  const handleExport = () => {
    const csv = convertArrayOfObjectsToCSV(logDetailData.pages);
    downloadCSV(csv, "Logs");
  };

  return (
    <>
      <TabContext value={selectedTab}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <RootTabStyle
            indicatorColor="transparent"
            centered
            length={tabsToShow.length}
            onChange={handleTabChange}
          >
            {tabsToShow.map((tab) => (
              <RootTabItemStyles value={tab.value} label={tab.label} />
            ))}
          </RootTabStyle>
        </Box>
        <Card sx={{ p: 2 }}>
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              id="charger"
              noValidate
              onSubmit={handleSubmit}
            >
              <TabPanel value="general">
                <Grid container spacing={3} className="mb-5">
                  <Grid item xs={6} lg={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <FormControl fullWidth size="small">
                        <TextField
                          required
                          disabled={isReadOnly}
                          label="Chargebox Id"
                          size="small"
                          {...getFieldProps("chargepoint_id")}
                          error={Boolean(
                            touched.chargepoint_id && errors.chargepoint_id
                          )}
                          helperText={
                            touched.chargepoint_id && errors.chargepoint_id
                          }
                        />
                      </FormControl>
                      <span class="icon">
                        <Tooltip
                          title="Unique identifier used for system-level management and tracking of charging stations."
                          placement="right"
                        >
                          <Icon
                            icon={AlertCircleFill}
                            className="fs-5"
                            color="#626262"
                          />
                        </Tooltip>
                      </span>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={Boolean(
                        touched.registrationStatus && errors.registrationStatus
                      )}
                    >
                      <InputLabel id="registrationStatus">
                        Registration Status *
                      </InputLabel>
                      <Select
                        labelId="registrationStatus"
                        id="registrationStatus"
                        disabled={isReadOnly}
                        value={values.registrationStatus}
                        label="Registration Status"
                        {...getFieldProps("registrationStatus")}
                      >
                        <MenuItem selected value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Accepted">Accepted</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                      </Select>
                      {touched.registrationStatus && (
                        <FormHelperText>
                          {errors.registrationStatus}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <FormControl fullWidth size="small">
                        <TextField
                          required
                          disabled={isReadOnly}
                          label="Chargebox name"
                          size="small"
                          {...getFieldProps("chargepoint_name")}
                          error={Boolean(
                            touched.chargepoint_name && errors.chargepoint_name
                          )}
                          helperText={
                            touched.chargepoint_name && errors.chargepoint_name
                          }
                        />
                      </FormControl>
                      <span class="icon">
                        <Tooltip
                          title="User-assigned label for easy identification of a charging station."
                          placement="right"
                        >
                          <Icon
                            icon={AlertCircleFill}
                            className="fs-5"
                            color="#626262"
                          />
                        </Tooltip>
                      </span>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={Boolean(
                        touched.charger_type && errors.charger_type
                      )}
                    >
                      <InputLabel id="chargerType">Charger Type *</InputLabel>
                      <Select
                        labelId="chargerType"
                        id={`chargerTypeId-${values.charger_type}`}
                        disabled={isReadOnly}
                        value={values.charger_type}
                        label="Charger Type"
                        {...getFieldProps("charger_type")}
                      >
                        <MenuItem value="AC">AC</MenuItem>
                        <MenuItem value="DC">DC</MenuItem>
                      </Select>
                      {touched.charger_type && (
                        <FormHelperText>{errors.charger_type}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {!isCreateCharger && (
                    <Grid item xs={12} lg={2} sm={2}>
                      <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        startIcon={getIcon(downloadFill, 20, 20)}
                        // disabled={value.qrCodeUrl ? false : true}
                        onClick={() =>
                          handleShowCMQRCode(values["chargepoint_id"])
                        }
                        sx={{ fontSize: "10.5px" }}
                      >
                        Oaza QR Code
                      </LoadingButton>
                    </Grid>
                  )}
                  <Grid item xs={12} lg={12}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.insertConnectorStatusAfterTransactionMsg &&
                          errors.insertConnectorStatusAfterTransactionMsg
                      )}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...getFieldProps(
                              "insertConnectorStatusAfterTransactionMsg"
                            )}
                            checked={
                              values.insertConnectorStatusAfterTransactionMsg
                            }
                            disabled={isReadOnly}
                          />
                        }
                        label="Insert connector status after start/stop transaction"
                      />
                      {touched.insertConnectorStatusAfterTransactionMsg && (
                        <FormHelperText>
                          {errors.insertConnectorStatusAfterTransactionMsg}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  {values.connectors.map((value, index) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        lg={isCreateCharger ? 2 : 1}
                        sm={isCreateCharger ? 2 : 1}
                      >
                        <FormControl fullWidth size="small">
                          <Field name={`connectors[${index}].connectorName`}>
                            {({ field, form: { touched, errors }, meta }) => (
                              <TextField
                                disabled={isReadOnly}
                                label="Name"
                                size="small"
                                defaultValue={value.connectorName}
                                {...field}
                                error={touched.connectors && errors.connectors}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={2}>
                        <FormControl
                          fullWidth
                          size="small"
                          error={touched.connectors && errors.connectors}
                        >
                          <InputLabel id="priceGroupLabel">
                            Price Group
                          </InputLabel>
                          <Field name={`connectors[${index}].priceGroupId`}>
                            {({ field, form: { touched, errors }, meta }) => (
                              <>
                                <Select
                                  labelId="priceGroupLabel"
                                  id="priceGroup"
                                  disabled={isReadOnly}
                                  defaultValue={value.priceGroupId}
                                  label="Price Group"
                                  {...field}
                                >
                                  <MenuItem selected value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {priceGroups &&
                                    priceGroups.map((group) => (
                                      <MenuItem
                                        key={group.priceGroupId}
                                        value={group.priceGroupId}
                                      >
                                        {group.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {touched.priceGroupId && (
                                  <FormHelperText>
                                    {errors.priceGroupId}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={isCreateCharger ? 3 : 2}
                        md={3}
                        sm={isCreateCharger ? 4 : 2}
                      >
                        <FormControl
                          fullWidth
                          size="small"
                          error={touched.connectors && errors.connectors}
                        >
                          <InputLabel id="connectorTypeLabel">
                            Connector Type
                          </InputLabel>
                          <Field name={`connectors[${index}].connectorType`}>
                            {({ field, form: { touched, errors }, meta }) => (
                              <>
                                <Select
                                  labelId="connectorTypeLabel"
                                  id="connectorType"
                                  disabled={isReadOnly}
                                  defaultValue={value.connectorType}
                                  label="Connector Type"
                                  {...field}
                                >
                                  <MenuItem selected value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {ConnectorType.map((connector) => (
                                    <MenuItem
                                      key={connector.id}
                                      value={connector.value}
                                    >
                                      {connector.type}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.connectors && (
                                  <FormHelperText>
                                    {errors.connectors}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={2}>
                        <FormControl fullWidth size="small">
                          <Field name={`connectors[${index}].kw`}>
                            {({ field, form: { touched, errors }, meta }) => (
                              <TextField
                                disabled={isReadOnly}
                                type="number"
                                label="Kilo Watt"
                                size="small"
                                defaultValue={value.kw}
                                {...field}
                                error={touched.connectors && errors.connectors}
                                inputProps={{ min: 0 }}
                                InputProps={{
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      kW
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <FormControl fullWidth size="small">
                          <Field name={`connectors[${index}].volt`}>
                            {({ field, form: { touched, errors }, meta }) => (
                              <TextField
                                disabled={isReadOnly}
                                label="Volt"
                                type="number"
                                size="small"
                                {...field}
                                defaultValue={value.volt}
                                error={touched.connectors && errors.connectors}
                                inputProps={{ min: 0 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      V
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      {values.connectors.length > 1 && isCreateCharger && (
                        <Grid item xs={12} lg={1} sm={1}>
                          <Tooltip
                            title="Remove Connector"
                            placement="right-end"
                          >
                            <LoadingButton
                              size="medium"
                              type="button"
                              variant="contained"
                              onClick={() =>
                                handleAddAndDeleteConnectors(index)
                              }
                            >
                              <Icon
                                icon={TrashOutline}
                                fontSize="1.9em"
                                className="fs-5"
                              />
                            </LoadingButton>
                          </Tooltip>
                        </Grid>
                      )}
                      {!isCreateCharger && value.hasOwnProperty("status") && (
                        <GridLabel
                          xs={12}
                          sm={1}
                          lg={1}
                          label="Status"
                          value={value?.status}
                          color={value?.status === "Unavailable" ? "red" : "green"}
                        />
                      )}
                      {!isCreateCharger && value.hasOwnProperty("qrCodeUrl") ? (
                        !is.empty(value.qrCodeUrl) ? (
                          <Grid item xs={12} lg={2} sm={2}>
                            <LoadingButton
                              size="small"
                              type="button"
                              disabled={!value?.status.toLowerCase().includes('available')}
                              variant="contained"
                              startIcon={getIcon(downloadFill, 20, 20)}
                              // disabled={value.qrCodeUrl ? false : true}
                              onClick={() =>
                                handleShowRazorPayQRCode(value.qrCodeUrl)
                              }
                              sx={{ fontSize: "10.5px" }}
                            >
                              {
                                PAYMENT_GATEWAY[charger.paymentGatewayId]
                                  .afterButtonText
                              }
                            </LoadingButton>
                          </Grid>
                        ) : (
                          <Grid item xs={12} lg={2} sm={2}>
                            <LoadingButton
                              size="small"
                              type="button"
                              variant="contained"
                              // loading={isGenQR}
                              startIcon={
                                <Icon
                                  icon={PlusCircleFill}
                                  width={24}
                                  height={24}
                                  color="#ffff"
                                />
                              }
                              onClick={() => generateQRCode(value.connector_pk)}
                              sx={{ fontSize: "10px" }}
                            >
                              {
                                PAYMENT_GATEWAY[charger.paymentGatewayId]
                                  .beforeButtonText
                              }
                            </LoadingButton>
                          </Grid>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  {values.connectors.length !== 4 && isCreateCharger && (
                    <Grid item xs={12} lg={2} sm={2}>
                      <Tooltip
                        title="Add More Connectors"
                        placement="right-end"
                      >
                        <LoadingButton
                          size="medium"
                          type="button"
                          variant="contained"
                          onClick={() => handleAddAndDeleteConnectors()}
                        >
                          <Icon
                            icon={PlusFill}
                            fontSize="1.9em"
                            className="fs-5"
                          />
                        </LoadingButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={3} className="mb-5">
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="subtitle1"
                      className="mb-2 text-uppercase"
                    >
                      misc
                    </Typography>
                    <Divider className="text-muted" />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <TextField
                        label="Description"
                        size="small"
                        disabled={isReadOnly}
                        {...getFieldProps("description")}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <TextField
                        label="Admin Address"
                        size="small"
                        disabled={isReadOnly}
                        {...getFieldProps("adminAddress")}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <TextField
                        label="Additional Notes"
                        size="small"
                        disabled={isReadOnly}
                        {...getFieldProps("note")}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...getFieldProps("showOnPortal")}
                          checked={values.showOnPortal}
                        />
                      }
                      label="Make Charger public"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value="address">
                <Address
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  formValues={values}
                  notDisplayTitle={true}
                  displayedFor="charger"
                  errors={errors}
                  touched={touched}
                />
              </TabPanel>

              <TabPanel value="settings">
                <Grid container>
                  <Grid item xs={12} lg={12} marginBottom={2}>
                    <Typography
                      sx={{ ml: 2 }}
                      variant="subtitle1"
                      className="mb-2 text-uppercase"
                    >
                      Payment Options
                    </Typography>
                    <Divider className="text-muted" variant="middle" />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...getFieldProps("employeeOrResident")}
                          checked={values.employeeOrResident}
                        />
                      }
                      label="Employee / Resident (OTP)"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...getFieldProps("payWithWallet")}
                          checked={values.payWithWallet}
                        />
                      }
                      label="Pay With Wallet"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...getFieldProps("payWithCard")}
                          checked={values.payWithCard}
                        />
                      }
                      label="Pay With Card"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={12} marginBottom={2}>
                    <Typography
                      sx={{ ml: 2 }}
                      variant="subtitle1"
                      className="mb-2 text-uppercase"
                    >
                      Mail Notification Settings
                    </Typography>
                    <Divider className="text-muted" variant="middle" />
                  </Grid>
                  <Grid item xs={6} lg={12}>
                    <Stack>
                      <FormControlLabel
                        control={
                          <Switch
                            {...getFieldProps("enableNotifications")}
                            checked={values.enableNotifications}
                          />
                        }
                        label="Enable Notification"
                        labelPlacement="start"
                      />
                      {values?.enableNotifications && (
                        <>
                          <Stack direction="column" spacing={1} my={3} mx={2}>
                            <InputLabel
                              id="notification-recipients-label"
                              sx={{ color: "inherit" }}
                            >
                              Recepients Of All The Enabled Notifications
                            </InputLabel>
                            <FormControl>
                              <TextField
                                required
                                multiline
                                sx={{ width: "400px" }}
                                rows={4}
                                id="notification-recipients"
                                label="Recipients"
                                {...getFieldProps("notificationRecipients")}
                                error={Boolean(
                                  touched.notificationRecipients &&
                                    errors.notificationRecipients
                                )}
                                helperText={
                                  touched.notificationRecipients &&
                                  errors.notificationRecipients
                                }
                              />
                            </FormControl>
                          </Stack>
                          <Typography
                            sx={{ m: 2 }}
                            variant="subtitle2"
                            className="mb-2 text-uppercase"
                          >
                            Notify When....
                          </Typography>
                          <Grid container>
                            <Grid item xs={12} lg={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...getFieldProps("OcppStationBooted")}
                                    checked={values.OcppStationBooted}
                                  />
                                }
                                label="A Boot Notification Is Sent"
                                labelPlacement="start"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...getFieldProps(
                                      "OcppStationStatusFailure"
                                    )}
                                    checked={values.OcppStationStatusFailure}
                                  />
                                }
                                label="A Connector Gets Faulted"
                                labelPlacement="start"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...getFieldProps(
                                      "OcppStationWebSocketConnected"
                                    )}
                                    checked={
                                      values.OcppStationWebSocketConnected
                                    }
                                  />
                                }
                                label="Charging Station Connects"
                                labelPlacement="start"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...getFieldProps(
                                      "OcppStationWebSocketDisconnected"
                                    )}
                                    checked={
                                      values.OcppStationWebSocketDisconnected
                                    }
                                  />
                                }
                                label="Charging Station Disconnects"
                                labelPlacement="start"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...getFieldProps("OcppTransactionStarted")}
                                    checked={values.OcppTransactionStarted}
                                  />
                                }
                                label="A Transaction Starts"
                                labelPlacement="start"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    {...getFieldProps("OcppTransactionEnded")}
                                    checked={values.OcppTransactionEnded}
                                  />
                                }
                                label="A Transaction Stops"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} marginBottom={2}>
                    <Typography
                      sx={{ ml: 2 }}
                      variant="subtitle1"
                      className="mb-2 text-uppercase"
                    >
                      Admin Settings
                    </Typography>
                    <Divider className="text-muted" variant="fullWidth" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl size="small">
                      <TextField
                        required
                        disabled={isReadOnly}
                        label="Tolerance level (in watts)"
                        type="number"
                        size="small"
                        {...getFieldProps("tolerance_watt")}
                        error={Boolean(
                          touched.tolerance_watt && errors.tolerance_watt
                        )}
                        helperText={
                          touched.tolerance_watt && errors.tolerance_watt
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl size="small">
                      <TextField
                        required
                        disabled={isReadOnly}
                        label="Meter Interval (in sec)"
                        type="number"
                        size="small"
                        {...getFieldProps("meter_interval_sec")}
                        error={Boolean(
                          touched.meter_interval_sec &&
                            errors.meter_interval_sec
                        )}
                        helperText={
                          touched.meter_interval_sec &&
                          errors.meter_interval_sec
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="config"></TabPanel>

              <TabPanel value="logs">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={3}
                >
                  <Box
                    sx={{ display: "flex", gap: "1rem", placeItems: "center" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={12} md={6}>
                        <DateTimePicker
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              value={timeInterval.startDate}
                              error={false}
                            />
                          )}
                          inputFormat="yyyy-MM-dd hh:mm:ss a"
                          label="Start Date/Time"
                          value={timeInterval.startDate}
                          onChange={(newVal) =>
                            setTimeInterval((prev) => ({
                              ...prev,
                              startDate: newVal,
                            }))
                          }
                        />
                      </Grid>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={12} md={6}>
                        <DateTimePicker
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              value={timeInterval.endDate}
                              error={false}
                            />
                          )}
                          inputFormat="yyyy-MM-dd hh:mm:ss a"
                          label="End Date/Time"
                          value={timeInterval.endDate}
                          onChange={(newVal) =>
                            setTimeInterval((prev) => ({
                              ...prev,
                              endDate: newVal,
                            }))
                          }
                        />
                      </Grid>
                    </LocalizationProvider>
                    <LoadingButton
                      variant="contained"
                      onClick={() =>
                        fetchLogFilterData(
                          timeInterval.startDate,
                          timeInterval.endDate
                        )
                      }
                    >
                      Search
                    </LoadingButton>
                  </Box>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    {logsData?.pages[0]?.length > 0 && (
                      <Button
                        variant="contained"
                        startIcon={getIcon(downloadFill)}
                        onClick={handleExport}
                      >
                        Export CSV
                      </Button>
                    )}
                    <Tooltip placement="bottom" title="Reload">
                      <LoadingButton
                        variant="contained"
                        onClick={handleRefetch}
                        loading={isLogsFetching || isLogsRefetching}
                        loadingIndicator="Refreshing...."
                      >
                        <Icon icon={RefreshFill} />
                        <Typography sx={{ ml: 1 }}>Refresh</Typography>
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Stack>
                {logsData?.pages[0]?.length > 0 ? (
                  <LogsData
                    data={logDetailData}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                  />
                ) : (
                  <Typography sx={{ textAlign: "center", mt: 6 }}>
                    No Logs Found
                  </Typography>
                )}
              </TabPanel>

              <TabPanel value="operating_instruction">
                <Grid container spacing={3} className="mb-5">
                  <Grid item xs={12} lg={12} marginBottom={2}>
                    <Typography
                      sx={{ ml: 2 }}
                      variant="subtitle1"
                      className="mb-2 text-uppercase"
                    >
                      FAQ
                    </Typography>
                    <Divider className="text-muted" variant="middle" />
                  </Grid>
                  <Grid item xs={12} lg={12} textAlign="right">
                    {values?.faq?.length !== 10 && (
                      <Grid item xs={12} lg={12} sm={12}>
                        <Tooltip
                          title="Add More Connectors"
                          placement="right-end"
                        >
                          <LoadingButton
                            size="medium"
                            type="button"
                            variant="contained"
                            onClick={() => handleAddAndDeleteFAQ()}
                          >
                            <Icon
                              icon={PlusFill}
                              fontSize="1.9em"
                              className="fs-5"
                            />
                          </LoadingButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                  {values?.faq?.map((value, i) => (
                    <>
                      <Grid item xs={6} lg={6}>
                        <Stack direction="column" gap={2}>
                          <FormControl fullWidth size="small">
                            <Field name={`faq[${i}].id`}>
                              {({ field }) => (
                                <input
                                  type="hidden"
                                  defaultValue={value.id}
                                  {...field}
                                />
                              )}
                            </Field>
                            <Field name={`faq[${i}].question`}>
                              {({ field, form: { touched, errors } }) => (
                                <TextField
                                  disabled={isReadOnly}
                                  label={`Question-${i + 1}`}
                                  size="small"
                                  defaultValue={value.question}
                                  {...field}
                                  error={touched.question && errors.question}
                                />
                              )}
                            </Field>
                          </FormControl>
                          <FormControl fullWidth size="small">
                            <Field name={`faq[${i}].answer`}>
                              {({ field, form: { touched, errors } }) => (
                                <TextField
                                  multiline
                                  rows={4}
                                  id=""
                                  label={`Answer-${i + 1}`}
                                  error={touched.answer && errors.answer}
                                  {...field}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </TabPanel>

              <TabPanel value="report_incidents">
                <Grid container spacing={3} className="mb-5">
                  <Grid item xs={12} lg={12} marginBottom={2}>
                    <Typography
                      sx={{ ml: 2 }}
                      variant="subtitle1"
                      className="mb-2 text-uppercase"
                    >
                      REPORTED INCIDENTS
                    </Typography>
                    <Divider className="text-muted" variant="middle" />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <BaseTable
                      rows={values.report_issues ?? []}
                      columns={reportIssuesColumn}
                      loading={isFetching}
                      getRowId={(row) => row.serial_number}
                      showExportCsvBtn={false}
                      csvMorphemes="chargers"
                      pageSize={10}
                      isReadOnly={isReadOnly}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              {!hideForTabs.includes(selectedTab) && (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItem="center"
                  justifyContent="center"
                >
                  {!isReadOnly && (
                    <>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={isReadOnly}
                        loading={isSubmitting || isLoading}
                      >
                        Save
                      </LoadingButton>
                      <LoadingButton
                        size="large"
                        type="button"
                        variant="outlined"
                        component={RouterLink}
                        to="/charger"
                      >
                        Cancel
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              )}
            </Form>
          </FormikProvider>

          {razorPayQRCodePreview.open && (
            <ImgPreviewModal
              imageObj={razorPayQRCodePreview}
              buttonsArray={razorPayQRCodePreview.buttons}
            />
          )}
          {CMQRCodePreview.open && (
            <ImgPreviewModal
              imageObj={CMQRCodePreview}
              buttonsArray={CMQRCodePreview.buttons}
            />
          )}
          <TabPanel value="remote operations">
            <Box>
              <Typography variant="h4" gutterBottom mb={4}>
                Remote Operation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <FormControl>
                    <FormLabel
                      id="Remote-Operations-Radio-Btns-Group-Label"
                      sx={{ my: 2, color: "#00AB55" }}
                    >
                      Select Operation
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="Remote-Operations-Radio-Btns-Group-Label"
                      onChange={(event, newValue) => {
                        setOperation(newValue);
                      }}
                      value={operation}
                      name="remote-operations-radio-buttons-group"
                    >
                      {remoteOperations &&
                        remoteOperations.map(({ label, value }) => (
                          <FormControlLabel
                            value={value}
                            control={<Radio />}
                            label={label}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={8} md={8} spacing={2}>
                  {operation &&
                    validationSchema &&
                    !is.empty(initialValues) && (
                      <RemoteOperationForm
                        operation={operation}
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        chargerId={values.chargepoint_id}
                        chargerPk={id}
                        key={operation}
                        queryKeysDropdown={queryKeysDropdown}
                      />
                    )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </Card>
      </TabContext>
      {modalOpen && remoteOperationPerformed && (
        <RemoteOpsStatusModal
          open={modalOpen}
          onClose={handleRemoteOpsStatusModalClose}
          operation={
            remoteOperations.find(
              (operation) => operation.value === remoteOperationPerformed
            ).label
          }
          responseRow={
            operationDetails.response
              ? [
                  {
                    id: 1,
                    charge_box_id: operationDetails.chargerId,
                    response: operationDetails.response.response,
                    error: operationDetails.response.errorMessage,
                    details: operationDetails.response.details,
                  },
                ]
              : [
                  {
                    id: 1,
                    charge_box_id: operationDetails.chargerId,
                    response: "",
                    error: "",
                    details: "",
                  },
                ]
          }
        />
      )}
    </>
  );
}
