import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import sidebarConfig from "./SidebarConfig";
import { useAuth } from "src/hooks/useAuth.hook";
import { teamsSlice } from "src/store/teams/teams.slice";
import { useDispatch } from "react-redux";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { is } from "src/util/is";
import OazaLogo from "../../image/logo/logo_color_300x70.png";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// const AccountStyle = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[200],
// }));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const notASideBarLink = ["Finance", "ChargeZone", "License Payment", "Settings"]    //ARRAY OF FEATUREs NOT TO BE SHOWN AS A SIDEBAR LINK

const getCompanyLogo = (data, id) => {
  const { company, role } = data;
  const updatedTeamArr = [{ teamId: company.id, companyLogo: company.companyLogo }, ...role];
  const currentTeamObj = updatedTeamArr.find((team) => Number(team.teamId) === Number(id));
  return currentTeamObj.companyLogo ? currentTeamObj.companyLogo : null;
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const team = useSelectedTeam();
  const [logo, setLogo] = useState();
  const selectedTeamFromLS = JSON.parse(localStorage.getItem('selectedTeamId'))

  const company = useMemo(() => {
    return user?.role?.find((team) => team.teamId === user?.company?.id);
  }, [user]);

  const companyType = useMemo(() => {
    return user?.company?.companyType
  }, [user]);

  useEffect(() => {
    if(!is.empty(team)){
      if (companyType === "GP" && Number(team) !== Number(user?.company?.id)) {
        setLogo( () => getCompanyLogo(user, team.teamId))
      } else {
        setLogo(user?.company?.companyLogo);
      }
    }
  }, [companyType, team, user])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleChange = (event) => {
    // console.log(event.target.value);
    const {
      target: { value },
    } = event;
    dispatch(teamsSlice.actions.setTeamId(+value));
    localStorage.setItem('selectedTeamId', JSON.stringify(value));
    navigate("/dashboard")
  };
  useEffect(() => {
    if (selectedTeamFromLS) {
      dispatch(teamsSlice.actions.setTeamId(selectedTeamFromLS));
    } else {
      team?.teamId ??
        dispatch(
          teamsSlice.actions.setTeamId(
            company?.teamId ?? user?.role?.[0]?.teamId ?? null
          )
        );
    }
  }, [])
  // useEffect(() => {
  //   team?.teamId ??
  //     dispatch(
  //       teamsSlice.actions.setTeamId(
  //         company?.teamId ?? user?.role?.[0]?.teamId ?? null
  //       )
  //     );
  // }, [dispatch, user, company, team?.teamId]);

  const navConfig = useMemo(() => {
    //ADDED FOR NOW , AS BOTH FEATURES IS NOT AVAILABLE FROM BACKEND API CALL
    // const newFeatures = [ {
    //   create: "Y",
    //   delete: "Y",
    //   featureId : team.length+1,
    //   featureName:"ServiceRequest",
    //   update:"Y",
    //   view:"Y"
    // },
    // {
    //   create:"Y",
    //   delete:"Y",
    //   featureId: team.length+2,
    //   featureName:"ChargingProfile",
    //   update:"Y",
    //   view:"Y"
    // },]
    // const updatedAccess = team.hasOwnProperty("access")?[...team?.access,...newFeatures]:[]
    let teamType = !!team?.teamType
    return (
      team?.access?.reduce((finalConfig, item) => {
        if (item.view === "Y" && item.featureName === "ChargeZone" && teamType) {
          finalConfig = [...finalConfig, { ...sidebarConfig[item.featureName], access: item }];
        } else if (item.view === "Y" && item.featureName && !notASideBarLink.includes(item.featureName)) {
          finalConfig = [...finalConfig, { ...sidebarConfig[item.featureName], access: item }];
        }
        return finalConfig;
      }, []) ?? []
    );
  }, [team]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
        <img src={logo ? logo : OazaLogo} alt="Company Logo" style={{ height: "50px", width: "210px" }} />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <FormControl sx={{ m: 1 }}>
          <Select
            style={{ width: "204px", borderRadius: 4, height: "56px" }}
            value={team?.teamId ?? ""}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            <MenuItem
              key={user?.company?.id}
              value={user?.company?.id}
              sx={{
                fontWeight: 600,
                color: "#00AB55",
              }}
              disabled={!company}
            >
              {user?.company?.name ?? ""}
            </MenuItem>
            {user?.role
              ?.filter((team) => team.teamId !== user?.company?.id)
              ?.map((team) => (
                <MenuItem
                  key={team.teamId}
                  value={team.teamId}
                  sx={{
                    pl: 4,
                  }}
                >
                  {team.teamName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {/* <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {userDetails.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link> */}
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        {/* <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: "relative",
            bgcolor: "grey.200",
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: "absolute", top: -50 }}
          />

          <Box sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              From only $69
            </Typography>
          </Box>

          <Button
            fullWidth
            href="https://material-ui.com/store/items/minimal-dashboard/"
            target="_blank"
            variant="contained"
          >
            Upgrade to Pro
          </Button>
        </Stack> */}
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
