import editFill from "@iconify/icons-eva/edit-fill";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import { ActionsMenu } from "src/components/Actions/ActionsMenu";
import {useCallback, useMemo } from "react";

export const ChargeZoneActionMenu = (props) => {

  const { setCreateZoneBtn, rowData, handleAction } = props;

  const onEdit = useCallback(() => {
    setCreateZoneBtn(true);
    handleAction({action: "", id: rowData.zone_id})
  }, [setCreateZoneBtn]);

  const onDeleteZone = useCallback(() => {
    handleAction({action: "forDelete", id: rowData.zone_id})
  }, []);

  const actionList = useMemo(
    () => [
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onDeleteZone
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        action: onEdit
      },
    ],
    []
  );
  return <ActionsMenu menuList={actionList} />;
};
