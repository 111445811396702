import { memo, useState, useRef } from "react";
import { Icon } from "@iconify/react";

//icons
import editFill from "@iconify/icons-eva/edit-fill";
import viewFill from "@iconify/icons-eva/eye-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";

//material ui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { useAccess } from "src/hooks/useAccess.hook";

function DatatableActions({ onTableRowDelete, onTableRowEdit, row }) {
  const actionRef = useRef();
  const [openMenu, setMenuOpen] = useState(!1);
  const hasReadOnly = useAccess("RXX");
  const handleMenu = () => setMenuOpen(!openMenu);
  const handleDeleteRow = () => {
    onTableRowDelete(row);
    handleMenu();
  };
  const handleEditRow = () => {
    onTableRowEdit(row);
    handleMenu();
  };

  return (
    <Box className="datatable__actions">
      <IconButton ref={actionRef} onClick={handleMenu}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={openMenu}
        anchorEl={actionRef.current}
        onClose={handleMenu}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {!hasReadOnly && (
          <MenuItem onClick={handleDeleteRow} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <MenuItem onClick={handleEditRow} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon
              icon={hasReadOnly ? viewFill : editFill}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={hasReadOnly ? "View" : "Edit"}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}

DatatableActions.defaultProps = {
  onTableRowDelete: () => {},
  onTableRowEdit: () => {},
  row: {},
};

export default memo(DatatableActions);
