import forwardTrend from "../image/forwardTrend.svg";
import downwardTrend from "../image/downwardTrend.svg";
import dashboardStatsIcon from "../image/dashboardStatsIcon.svg";
import dashboardEllipsesGreen from "../image/dashboardEllipsesGreen.svg";
import dashboardEllipsesRed from "../image/dashboardEllipsesRed.svg";
import * as Yup from "yup";
import { differenceBetween } from "src/utils/formatTime";

export const STATISTICS_URL = {
  TOTAL_REVENUE: "dashboard_stats/total_revenue",
  TOTAL_ENERGY: "dashboard_stats/total_energy",
  TOTAL_SESSIONS: "dashboard_stats/total_sessions",
  ACTIVE_SESSIONS: "dashboard_stats/active_sessions",
  NEW_USERS: "dashboard_stats/new_users",
  NEW_CHARGERS: "dashboard_stats/new_chargers",
  CUMULATIVE_ENERGY_USAGE: "dashboard_stats/cumulative_energy_usage",
  FAULT_CONNECTIVITY_LOSS: "dashboard_stats/faults_connectivity_loss",
};

export const dropdownValues = [
  { label: "Last Week", value: "Last Week", selected: false },
  { label: "Last Month", value: "Last Month", selected: false },
  { label: "365 Days", value: "365 Days", selected: true },
];
export const topDropdownValues = [
  { label: "Current", value: "Current", selected: true },
  { label: "Week", value: "Week", selected: false },
  { label: "Month", value: "Month", selected: false },
  { label: "Year", value: "Year", selected: false },
  { label: "Custom", value: "Custom", selected: false },
];
export const dashboardCards = new Map([
  [
    "topCustomRangeCard",
    [
      {
        fieldName: "custom_time_dropdown",
        label: "Custom Time Dropdown",
        fieldType: "DROPDOWN",
        options: topDropdownValues,
        valueType: "string",
        validations: Yup.string().optional("This field is required"),
      },
      {
        fieldName: "custom_range_label",
        label: "Custom Range:",
        fieldType: "LABEL",
      },
      {
        fieldName: "custom_time_start",
        label: "Start",
        fieldType: "DATE/TIME",
        valueType: "date",
        // validations: Yup.date().required("This field is required"),
      },
      {
        fieldName: "custom_time_end",
        label: "End",
        fieldType: "DATE/TIME",
        valueType: "date",
        // validations: Yup.date()
        //   .required("This field is required")
        //   .test(
        //     "valid_to",
        //     "Value should be after valid from date",
        //     function (value) {
        //       const { custom_time_start } = this.parent;
        //       const custom_time_end = new Date(value);
        //       if (custom_time_start > custom_time_end) return false;
        //       return true;
        //     }
        //   ),
      },
    ],
  ],
  [
    "statisticCards",
    [
      {
        title: "Fault & Connectivity Loss",
        field: "faultAndConnectivityLoss",
        colorTheme: "success",
        bgColor: "lighter",
        fontColor: "darker",
        footer: "",
        count: 0,
        url: STATISTICS_URL["FAULT_CONNECTIVITY_LOSS"],
        isFetching: true,
      },
      {
        title: "Active Charging Sessions",
        field: "activeChargingSessions",
        colorTheme: "secondary",
        bgColor: "lighter",
        fontColor: "darker",
        footer: "",
        count: 0,
        url: STATISTICS_URL["ACTIVE_SESSIONS"],
        isFetching: true,
      },
      {
        title: "Charging Sessions",
        field: "totalChargingSessions",
        colorTheme: "warning",
        bgColor: "lighter",
        fontColor: "darker",
        footer: "",
        count: 0,
        url: STATISTICS_URL["TOTAL_SESSIONS"],
        isFetching: true,
      },
      {
        title: "Total Revenue",
        field: "totalRevenue",
        colorTheme: "violet",
        bgColor: "lighter",
        fontColor: "darker",
        footer: "",
        count: 0,
        url: STATISTICS_URL["TOTAL_REVENUE"],
        isFetching: true,
      },
      {
        title: "New Users",
        field: "newUsers",
        colorTheme: "error",
        bgColor: "light",
        fontColor: "darker",
        footer: "",
        count: 0,
        url: STATISTICS_URL["NEW_USERS"],
        isFetching: true,
      },
      {
        title: "New Charge Points",
        field: "newChargers",
        colorTheme: "error",
        bgColor: "lighter",
        fontColor: "main",
        footer: "",
        count: 0,
        url: STATISTICS_URL["NEW_CHARGERS"],
        isFetching: true,
      },
      {
        title: "Total Energy",
        field: "totalEnergy",
        colorTheme: "error",
        bgColor: "lighter",
        fontColor: "darker",
        footer: "",
        count: 0,
        url: STATISTICS_URL["TOTAL_ENERGY"],
        isFetching: true,
      },
    ],
  ],
  [
    "donutStatsCards",
    [
      {
        title: "Total Devices",
        field: "totalChargers",
        icon: dashboardStatsIcon,
        count: 0,
        isFetching: true,
        colorTheme: "warning",
        svgColor: "main",
        fontColor: "main",
      },
      {
        title: "Online Devices",
        field: "activeChargers",
        icon: dashboardEllipsesGreen,
        count: 0,
        isFetching: true,
        colorTheme: "success",
        svgColor: "main",
        fontColor: "main",
      },
      {
        title: "Offline Devices",
        field: "inActiveChargers",
        icon: dashboardEllipsesRed,
        count: 0,
        isFetching: true,
        colorTheme: "error",
        svgColor: "main",
        fontColor: "main",
      },
    ],
  ],
]);

export const customDateDropdownValidationSchema = (values) => {
  let schema = [...dashboardCards.get("topCustomRangeCard")]
    .filter((component) => component.fieldType !== "LABEL")
    .reduce((validationSchema, { fieldName, validations }) => {
      return { ...validationSchema, [fieldName]: validations };
    }, {});
  return Yup.object().shape(schema);
};

export const addDuplicatesValues = (statsObject) => {
  const updatedObject = { xaxis: [], yaxis: [] };
  const mapToremoveDup = new Map();
  for (let i = 0; i < statsObject.xaxis.length; i++) {
    const xaxis = statsObject.xaxis[i];
    const yaxis = statsObject.yaxis[i];
    if (mapToremoveDup.has(xaxis)) {
      mapToremoveDup.set(xaxis, mapToremoveDup.get(xaxis) + yaxis);
    } else {
      mapToremoveDup.set(xaxis, yaxis);
    }
  }

  for (const [xaxis, yaxis] of mapToremoveDup.entries()) {
    updatedObject.xaxis.push(xaxis);
    updatedObject.yaxis.push(yaxis);
  }
  return updatedObject;
};

export const requiredDateFormatForGraphXaxis = (selectedValue) => {
  let dateFormat = "";
  switch (selectedValue.type) {
    case "Current":
      dateFormat = "HH:mm";
      break;
    case "Week":
      dateFormat = "EEE";
      break;
    case "Month":
      dateFormat = "dd MMM";
      break;
    case "Year":
      dateFormat = "MMM";
      break;
    case "Custom":
      const daysDifference = differenceBetween(selectedValue);
      if (daysDifference === 0) {
        dateFormat = "HH:mm";
      } else if (daysDifference <= 7) {
        dateFormat = "EEE";
      } else if (daysDifference > 7 && daysDifference <= 34) {
        dateFormat = "dd MMM";
      } else if (daysDifference > 34 && daysDifference <= 62) {
        dateFormat = "yyyy-MM-dd";
      } else if (daysDifference > 31) {
        dateFormat = "MMM";
      }
      break;
    default:
      break;
  }

  return dateFormat;
};

export const statisticValueFormat = (toFormat, value, valueType = "") => {
  let formattedText;
  switch (toFormat) {
    case "count":
      formattedText =
        value > 1000
          ? `${(value / 1000).toFixed(2)}${
              valueType === "totalEnergy" ? "kWh" : "K"
            }`
          : `${value}`;
      break;
    case "percentChange":
      formattedText =
        value !== 0
          ? value > 0
            ? `${value}% increase ${valueType!=="Custom"?` since ${valueType!=="Current"?` last ${valueType.toLowerCase()}`:"yesterday"}`:''}`
            : `${Math.abs(value)}% decrease ${valueType!=="Custom"?`since ${valueType!=="Current"?` last ${valueType.toLowerCase()}`:"yesterday"}`:''}`
          : ``;
      break;
    case "footer":
      formattedText =
        value > 1000 ? `${(value / 1000).toFixed(2)}kWh` : `${value}kWh`;
      break;
    case "icon":
      formattedText =
        value !== 0 ? (value > 0 ? forwardTrend : downwardTrend) : "";
      break;
    default:
      break;
  }
  return formattedText;
};
