// react
import { useEffect } from "react";
//Formik
import { useFormik, Form, FormikProvider } from "formik";

// router
import {
  Link as RouterLink,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";

//MUI Components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

// custom components, constants and services
import { tagFormValidationSchema } from "src/util/validationSchemas";
import { BaseTable } from "../BaseTable/BaseTable";

//custom hooks
import { useAccess } from "src/hooks/useAccess.hook";
import { useUser } from "src/hooks/useUser.hook";
import { useMutate, useGetDetail } from "src/hooks/useFetch.hook";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { getTeamUrl, tagIdUrl, tagUrl } from "src/react-query/endPoints";
import { is } from "src/util/is";
import { getTagDefaultValues, tagFormChargersColumns, tagUsersColumn } from "./Tags.constants";
import { Button, Card } from "@mui/material";
import { FEATURE } from "src/util/enums";
import { Loader } from "../Loader";
import { f } from "src/utils/formatTime";
import { useSelector } from "react-redux";

function TagForm() {
  const navigate = useNavigate();
  const { teamId } = useSelectedTeam();
  const isCreateTag = useMatch("/tag/new");
  const { data: userData } = useUser();
  const { tagId } = useParams();
  const hasReadOnlyAccess = useAccess(FEATURE.TAG, "R");

  const isReadOnly = hasReadOnlyAccess && !isCreateTag;

  const { data, isFetching } = useGetDetail("tag", { id: tagId });
  const { data: tagUsers, isFetching: isTagUsersFetching } = useGetDetail(
    "tagsUser",
    { id: tagId }
  );
  const {
    data: tagsChargerList,
    isLoading: isTagsChargerFetching,
    refetch,
  } = useGetDetail("tagsCharger", { id: tagId });

  const { tabOpened, tabOpenedFor,detailsId } = useSelector(
    ({ tabsCurrentlyOpenedFor }) => tabsCurrentlyOpenedFor
  );
  const { formData,formDataOf} = useSelector(
    ({ formData }) => formData
  );
  const { mutate, isLoading } = useMutate("tags");
  const onSubmit = async (tagData, { setSubmitting }) => {
    const mutateObject = {
      url: getTeamUrl(teamId, tagUrl),
      method: "POST",
    };
    if (!is.nullOrUndefined(tagId) && !isCreateTag) {
      mutateObject.method = "PUT";
      mutateObject.url = getTeamUrl(teamId, tagIdUrl(tagId));
    }
    tagData.companyId = teamId;
    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: tagData,
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const onSuccess = () => {
    if(formDataOf==="customer" && !is.empty(formData) && tabOpenedFor==="customer" && tabOpened==="tags"){
        !is.empty(detailsId)? navigate(`/customer/${detailsId}`): navigate(`/customer/new`);
        return;
    }  
    navigate("/tag")
  };

  const formik = useFormik({
    initialValues: getTagDefaultValues(teamId, userData?.data?.id),
    validationSchema: tagFormValidationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const expiryDateChangeHandler = (date) => {
    // console.log(date)
    let selectedDateOfExpiry = date??"" ;
    if(typeof date ==="object" && date!==null && date.toString()!=='Invalid Date'){
      selectedDateOfExpiry = f(date.toString(), "yyyy-MM-dd");

    }
    setFieldValue("expirydate", selectedDateOfExpiry);
  };

  useEffect(() => {
    if (data) {
      const [tag] = data;
      if (tag.expirydate) tag.expirydate = f(tag.expirydate, "yyyy-MM-dd");
      if (is.null(tag.expirydate)) tag.expirydate = "";
      if (is.null(tag.emailId)) tag.emailId = "";
      resetForm({ values: tag });
    }
  }, [data, resetForm]);

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreateTag && is.empty(data)) {
    navigate("/tag");
    return null;
  }
  // console.log(errors)
  // console.log(values)

  return (
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          id="authcodes"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3} className="cms-grid-mb">
            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl fullWidth size="small">
                  <TextField
                    required
                    label="ID Tag"
                    size="small"
                    disabled={isReadOnly}
                    {...getFieldProps("tag")}
                    error={Boolean(touched.tag && errors.tag)}
                    helperText={touched.tag && errors.tag}
                  />
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Balance Amount"
                  size="small"
                  disabled={!isCreateTag}
                  {...getFieldProps("balance_amount")}
                  error={Boolean(
                    isCreateTag &&
                      touched.balance_amount &&
                      errors.balance_amount
                  )}
                  helperText={
                    isCreateTag &&
                    touched.balance_amount &&
                    errors.balance_amount
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="parentIdTag">Parent ID Tag</InputLabel>
                <Select
                  labelId="parentIdTag"
                  id="parentTag"
                  disabled={isReadOnly}
                  value={values.parenttag}
                  label="Registration Status"
                  {...getFieldProps("parenttag")}
                >
                  <MenuItem selected value="">
                    None
                  </MenuItem>
                  {!isFetching &&
                    data &&
                    data.map((tag) => (
                      <MenuItem key={tag.parenttag} value={tag.parenttag}>
                        {tag.parenttag}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  {...getFieldProps("max_active_trans_count")}
                  size="small"
                  disabled={isReadOnly}
                  label="Max. Active Transaction Count"
                  id="max_active_trans_count"
                  helperText={`Set to 0 to block this tag.
                                    Set to a negative value to disable concurrent transaction check
                                    (i.e every transaction will be allowed). Set to a positive value
                                    to control the number of active transaction that is allowed`}
                />
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth size="small">
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onBlur={() => setFieldTouched("expirydate", true)}
                        error={
                          !!(touched["expirydate"] && errors["expirydate"])
                        }
                        helperText={
                          touched["expirydate"] && errors["expirydate"]
                        }
                      />
                    )}
                    onClose={() => setFieldTouched("expirydate", true)}
                    {...getFieldProps("expirydate")}
                    name="expirydate"
                    size="small"
                    label="Expiry Date"
                    sx={{ fontSize: "8px" }}
                    PopperProps={{ placement: "auto" }}
                    // disablePast={true}
                    inputFormat="yyyy-MM-dd"
                    value={
                      values["expirydate"] === "" ? null : values["expirydate"]
                    }
                    onChange={expiryDateChangeHandler}
                  />
                </FormControl>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid container mb={3} mt={3} className="mb-5">
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="cms-title-h6">
                MISC.
              </Typography>
              <Divider className="text-muted" />
            </Grid>
            <Grid item xs={12} lg={6} mt={3}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Notes"
                  size="small"
                  disabled={isReadOnly}
                  multiline
                  rows={3}
                  {...getFieldProps("note")}
                />
              </FormControl>
            </Grid>
          </Grid>
          {!isCreateTag && (
            <Grid container mb={3} mt={3} className="mb-5">
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle1" className="cms-title-h6">
                  EV Driver
                </Typography>
                <Divider className="text-muted"  sx={{ mt: 2, mb: 4 }}/>
              </Grid>
              <Grid item xs={12} lg={12}>
                {!is.empty(tagUsers)? 
                <>  {
                  tagUsers.map(({ userPk,email,firstName,lastName},index)=>(
                    <Grid container spacing={3} className="cms-grid-mb" key={`${index}-${firstName}-${index}`}>
            <Grid item xs={12} lg={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl fullWidth size="small">
                  <TextField
                    label="First Name"
                    size="small"
                    disabled={true}
                    value={firstName}
                  />
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl fullWidth size="small">
                  <TextField
                   label="Last Name"
                   size="small"
                   disabled={true}
                   value={lastName}
                  />
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl fullWidth size="small">
                  <TextField
                  label="Email"
                  size="small"
                  disabled={true}
                  value={email}
                  />
                </FormControl>
              </Stack>
            </Grid>
            </Grid>
                  ))
                }
                </>
                :
                "No EV Driver associated with the RFID tag"
                }
              </Grid>
            </Grid>
          )}
           {!isCreateTag && (
            <Grid container mb={3} mt={3} className="mb-5">
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle1" className="cms-title-h6">
                  Attached Chargers
                </Typography>
                <Divider className="text-muted"  sx={{ mt: 2, mb: 4 }}/>
              </Grid>
              <Grid item xs={12} lg={12}>
                {tagsChargerList && (
                  <BaseTable
                    height={250}
                    rows={tagsChargerList ?? []}
                    columns={tagFormChargersColumns()}
                    getRowId={(row) => row.charge_box_id}
                    loading={isTagsChargerFetching}
                  />
                )}
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Stack direction="row" spacing={2}>
                {isReadOnly ? (
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    component={RouterLink}
                    to={`/pricegroup`}
                  >
                    Go back
                  </Button>
                ) : (
                  <>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={isReadOnly}
                      loading={isSubmitting || isLoading}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      component={RouterLink}
                      to="/tag"
                    >
                      Cancel
                    </LoadingButton>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Card>
  );
}

export default TagForm;
