import { Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export const  serviceRequestListColumns = (isReadOnly,companyId) => {
    const columns = [
        {
            field: "ticket_id",
            headerName: "Ticket ID",
            renderCell: ({ value, row: { ticket_id } }) => (
                <>
                    {isReadOnly ? value : <Link underline="none" component={RouterLink} to={`edit/${ticket_id}`}>{value}</Link>}
                </>
            )
        },
        
        {
            field: "issueType",
            headerName: "Issue Type",
            flex: 1,
        },
        {
            field: "createAt",
            headerName: "Date/Time",
            flex: 1,
            type: "date",
            renderCell: ({ value }) => (
                <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
                    <span>{yearFirstDateTimeFormat(value)}</span>
                </Tooltip>
            )
        },
        {
            field: "statusType",
            headerName: "Status",
            flex: 1,
        },
        // {
        //     field: "actions",
        //     headerName: "Actions",
        //     flex: 1,
        //     renderCell: ServiceRequestActionMenu
        // }
    ];

    if(companyId === 1) {
        columns.splice(1,0,{
            field: "email",
            headerName: "Email",
            flex: 1,
        },{
            field: "teamName" || "companyName",
            headerName: "Company Name / Team Name",
            flex: 1,
            renderCell: ({ row }) => (
                <>
                    <span>{row.companyName} / {row.teamName}</span>
                </>
            )
        })
    }

    return columns;
};