import React, { useState, useEffect, useRef, memo } from "react";
import * as Yup from "yup";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

// material
import { styled } from "@mui/material/styles";
import { Box, Card, TextField, Typography, Grid, Button, CssBaseline } from "@mui/material";

// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { RegisterForm } from "../components/authentication/register";
import Logo from "src/components/Logo";
import "./pages.css"

import EmailSvg from "../image/email.svg";
import { Formik, FormikProvider, useFormik } from "formik";
import loginLogo from "src/image/logo/loginPageLogo.jpg"

const EmailVerification = memo(() => {
  const [email, setEmail] = useState("");
  const [otp,setOTP] = useState("");
  const optInputRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEmail(searchParams.get("email"));
  }, []);

  const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }));

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: "100%",
    maxWidth: 464,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 2),
  }));

  const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
  }));

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string()
      .matches(/^\d{4}$/, 'OTP must be exactly 4 digits')
      .required('OTP is required'),
    }),
    onSubmit: (otpData, { setSubmitting, resetForm }) => {
      // console.log(otpData);
      /*  (async function () {
        try {
          const registerHelper = async () => {
            console.log(process.env.REACT_APP_SERVER_URL);
            const response = await axios(`${process.env.REACT_APP_SERVER_URL}auth/register`, {
              method: 'post',
              data: registrationData
            });
            const registerHelperResp = response.data;
            if (registerHelperResp && registerHelperResp.success) {
              setSubmitting(false);
              resetForm();
              setSuccessMessage(registerHelperResp.message);
              // formik.values = initialFormValues;
            }
          };
          await registerHelper();
        } catch (error) {
          console.error(error.response);
          if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      })();*/
    },
  });

  const handleVerifyOTP=()=>{
    // console.log(otp);
    // verify otp;
    
    navigate('/login')
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;

  return (
    <RootStyle title="Register | Smart-CMS">
      <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100vh" }}>
      <CssBaseline />

      <Box container className="container" spacing={2} sx={{ display: "grid", gridTemplateColumns: "60% 40%", placeItems: "center"}}>

        <Box sx={{ position: "relative", width: "100%", height: { xs: "auto", md: "100%" }, display: "flex", justifyContent: "center", alignItems: "center" }} className="image_container">
          <img
            src={loginLogo}
            alt="login"
            style={{height: "inherit"}}
          />
            <Box as="div" sx={{
              position: 'absolute',
              top: 0,
              left: 50,
              height: '100%',
              width: '46.5%',
              backgroundColor: 'rgba(0, 85, 164, 0.56)',
              color: 'white',
              padding: '0 15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontFamily: 'Poppins, sans-serif',
            }} className="transparent_container">
            <Typography variant="h2" className="logo_heading">SMART Charging Management System (SMART CMS)</Typography>
            <Typography variant="body1" className="logo_para">Smart Charging Management System provides you complete control over operating your EV Charging infrastructure, eases the management of your charging networks through one control panel, and offers comprehensive insights into EV drivers' charging activities. This enables you to analyze usage patterns and optimize operations to maximize profitability.</Typography>
          </Box>
        </Box>

        <Grid className='form_container_email_verification' item xs={12} md={8} sx={{width: "70%", backgroundColor:'white', borderRadius:'15px', display: 'flex', flexDirection: 'column', padding: '0 20px'}}>
          <ContentStyle>
            <Box sx={{display:'flex', justifyContent:'center', marginBottom:'25px'}}>
              <Logo />
            </Box>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" marginBottom={3} align="center">
                Verify your email
              </Typography>
              <Box as="div" sx={{display:'flex', justifyContent:'center'}}>
                <img src={EmailSvg} />
              </Box>
              <Typography sx={{ color: "text.secondary" }}>
                We've sent an email with your code to your email
              </Typography>
            </Box>
            {/* <FormikProvider value={form}> */}

              <Box as="div">
                <Box as="div">
                  <TextField
                    // sx={{width: {xs: '90%', sm: '100%'}}}
                    fullWidth
                    type="number"
                    label="Enter OTP"
                    onChange={(e)=>{
                      setOTP(e.target.value)
                    }}
                    value={otp}
                    required
                    // ref={optInputRef}
                    // {...getFieldProps('otp')}
                    // error={Boolean(touched.otp && errors.otp)}
                    // helperText={touched.otp && errors.otp}
                    // FormHelperTextProps={{ sx: { ml:0} }}
                  />
                </Box>
                <Box as="div">
                  <Button
                    sx={{ height: "100%", mt: 4.5, mb: 3, py: 1.5 }}
                    fullWidth
                    variant="contained"
                    onClick={handleVerifyOTP}
                  >
                    Verify
                  </Button>
                </Box>
                <CountDown />
              </Box>

              {/* </FormikProvider> */}

          </ContentStyle>
        </Grid>
      </Box>
      </Box>
    </RootStyle>
  );
});

const CountDown = memo(() => {
  const [count, setCount] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [count]);

  const handleResendOTP = () => {
    if(count==0) {
      setCount(30);
    }
    // send otp to email
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Typography
        onClick={handleResendOTP}
        sx={{ cursor: `${count==0 ? "pointer": "not-allowed"}` }}
        fontWeight={600}
        color="rgba(0, 160, 140, 1)"
        variant="body2"
        align="center"
      >
        Resend OTP in {count} sec
      </Typography>
    </Box>
  );
});

export default EmailVerification;
