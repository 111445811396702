import { useAccess } from "src/hooks/useAccess.hook";
import deleteFill from "@iconify/icons-eva/trash-2-outline";

import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { tagChargerUrl, getTeamUrl } from "src/react-query/endPoints";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FEATURE } from "src/util/enums";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";

export const TagChargerActionMenu = ({ id, refetch }) => {
  const hasEditAccess = useAccess(FEATURE.TAG, "U");
  const { teamId } = useSelectedTeam();
  const { data } = useSelector(({ dialog }) => dialog);
  const { mutate } = useMutate("tagsCharger");

  const onDelete = useCallback(() => {
    const { row } = data;
    mutate({ url: 
        getTeamUrl(teamId, tagChargerUrl(row.id, id)), 
        method: "DELETE" 
    }, {
        onSuccess: () => refetch()
    });
  }, [data, mutate]);

  const actionList = useMemo(
    () => [
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onDelete,
        hidden: !hasEditAccess,
      },
    ],
    [hasEditAccess, onDelete]
  );

  return <ActionsMenu menuList={actionList} />;
};
