import viewFill from "@iconify/icons-eva/eye-fill";
import stopFill from '@iconify/icons-eva/stop-circle-fill'
import { ActionsMenu } from "src/components/Actions/ActionsMenu";
import { useCallback, useMemo,  } from "react";
import { useMutate } from "src/hooks/useFetch.hook";
import { remoteOperationTaskUrl, remoteOperationUrl } from "src/react-query/endPoints";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
export const TransactionActionMenu = ({id,row}) => {

  const { mutate, reset } = useMutate("chargers", {}, false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient()
  const onOperationSent = (data) => {
    reset();
    mutate(
      {
        url: remoteOperationTaskUrl(row.chargepoint_id, data?.data?.taskId),
        method: "GET",
      },
      {
        onSuccess: ()=>{
          // console.log("remote stop operation done")
          queryClient.invalidateQueries("chargers")
        },
        onSettled: () => reset(),
      }
    );
  };

  const stopTransaction =()=>{    
        mutate(
          {
            url: remoteOperationUrl,
            method: "POST",
            body: { parameters: {transactionId: id}, chargerId:row.chargepoint_id, remoteOperationType: "RemoteStop" },
          },
          {
            onSuccess:onOperationSent,
          }
        );
    
  };

  const viewLogs = useCallback(() => {
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      data:{dataForLogs: row},
      tabOpenedFor:"charger",
      tabOpened:"logs"
    }))
    navigate(`/charger/${row?.charge_box_pk}`)
  }, [row,navigate,dispatch]);
  
  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        routerLink: `/transactions/${id}`,
      },{
        id:"stop",
        title:"Stop",
        icon:stopFill,
        action:stopTransaction
      },
      {
        id: "viewLogs",
        title: "View  Logs",
        icon: viewFill,
        action: viewLogs,
      },
    ],
    [id]
  );
  return <ActionsMenu menuList={actionList} />;
};
