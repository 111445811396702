import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { serviceRequestUrl, uploadImgUrl } from "src/react-query/endPoints";
import { fetchWithError } from "src/react-query/fetchWithError";
import { queryKeys } from "src/react-query/queryKeys";
import { selector } from "src/react-query/select";
import { is } from "src/util/is";
import Token from "src/util/Token";



export const useServiceRequests = (teamId = "", id = "") => {
    // Determine the query keys and options based on the parameters
    let keys, options;
    if (teamId === id) {
      keys = queryKeys.serviceRequest();
      options = {
        select: selector
      };
    } else {
      keys = queryKeys.serviceRequestForParent(teamId);
      options = {
        enabled: !is.empty(teamId),
        select: selector
      };
    }
  
    // Call the useQuery hook with the determined keys and options
    return useQuery(keys, fetchWithError, options);
  };
export const useServiceRequestsForParent =(id="")=>{
    let keys = queryKeys.serviceRequestForParent(id)

    return useQuery(keys,fetchWithError,{
        enabled:!is.empty(id),
        select:selector
    })
}
export const useServiceRequestDetails =(id="")=>{

    let keys = queryKeys.serviceRequestDetails(id)

    return useQuery(keys,fetchWithError,{
        enabled:!is.empty(id),
        select:selector
    })
}

export const issueImgUpload = async(img)=>{
return await axios.post(uploadImgUrl,img)
}

export const issueSubmission = async(formData)=>{
    // let url = `https://csmsapi.chargemiles.com:444/cms/v1/support/1`
    const serviceRequestId = formData.ticketId
    // console.log( "-> *******" ,formData) 
    if(serviceRequestId){
        let editServiceRequestUrl = `${serviceRequestUrl}/${serviceRequestId}`
        return  await axios.put(editServiceRequestUrl,formData,{ headers: {
            'Authorization': `Bearer ${Token.get()}`
          }})
    }else{
        return await axios.post(serviceRequestUrl,formData,{ headers: {
            'Authorization': `Bearer ${Token.get()}`
          }})
    }
}