import { useState, useMemo } from "react";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
//  context
import { Context } from "./context";
import { MuiSnackBar } from "./components/@material-extend";

//mui components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useAuth } from "./hooks/useAuth.hook";
import { useCategories } from "./hooks/useCategories.hook";
import { useUserTypes } from "./hooks/useUserTypes.hook";
import { useUser } from "./hooks/useUser.hook";
import { Loader } from "./components/Loader";
import "src/styles/main.css";
// ----------------------------------------------------------------------

export default function App() {
  const [userDetail, setUserDetail] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageOption, setMessageOption] = useState({
    open: false,
    message: "",
    variant: "success",
  });
  const { isAuthenticated: isLoggedIn } = useAuth();
  const { isFetching, isError } = useUser();
  // useCategories();
  // useUserTypes();
  const alert = useSelector(({ alert }) => alert);
  const userDetailMemo = useMemo(
    () => ({ userDetail, setUserDetail }),
    [userDetail, setUserDetail]
  );

  const authMemo = useMemo(
    () => ({ isAuthenticated, setIsAuthenticated }),
    [isAuthenticated, setIsAuthenticated]
  );

  const messageMemo = useMemo(
    () => ({ messageOption, setMessageOption }),
    [messageOption, setMessageOption]
  );

  const categoryMemo = useMemo(
    () => ({ categoryList, setCategoryList }),
    [categoryList, setCategoryList]
  );

  const userTypeMemo = useMemo(
    () => ({ userTypeList, setUserTypeList }),
    [userTypeList, setUserTypeList]
  );

  const loadingTypeMemo = useMemo(
    () => ({ loading, setLoading }),
    [loading, setLoading]
  );

  return (
    <>
      <Context.Provider
        value={{
          user: userDetailMemo,
          auth: authMemo,
          message: messageMemo,
          category: categoryMemo,
          userType: userTypeMemo,
          spinner: loadingTypeMemo,
        }}
      >
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          {isFetching || isError ? (
            <Loader />
          ) : (
            <Router isLoggedIn={isLoggedIn} />
          )}

          <MuiSnackBar {...alert} />
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        </ThemeConfig>
      </Context.Provider>
    </>
  );
}
