import { alertReducerKey } from "./alert/alert.constants";
import { alertSlice } from "./alert/alert.slice";
import { authReducerKey } from "./auth/auth.constants";
import { authSlice } from "./auth/auth.slice";
import { commonReducerKey } from "./common/common.constants";
import { commonSlice } from "./common/common.slice";
import { dialogReducerKey, dialogSlice } from "./dailog/dailog.slice";
import { setupReducerKey } from "./setup/setup.constants";
import { setupSlice } from "./setup/setup.slice";
import { teamsReducerKey } from "./teams/teams.constants";
import { teamsSlice } from "./teams/teams.slice";
import {remoteOpsStatusReducerKey} from "./remoteOpsStatus/remoteOpsStatus.constants"
import {remoteOpsStatusSlice} from "./remoteOpsStatus/remoteOpsStatus.slice";
import { tabsCurrentlyOpenedForReducerKey } from "./tabsOpened/tabsOpened.constants";
import { tabsCurrentlyOpenedForSlice } from "./tabsOpened/tabsOpened.slice";
import { formDataReducerKey } from "./formData/formData.constants";
import { formDataSlice } from "./formData/formatData.slice";
import { chargingProfileReducerKey } from "./chargingProfile/chargingProfile.constants";
import { chargingProfileSlice } from "./chargingProfile/chargingProfile.slice";

export const rootReducer = {
  [authReducerKey]: authSlice.reducer,
  [setupReducerKey]: setupSlice.reducer,
  [alertReducerKey]: alertSlice.reducer,
  [teamsReducerKey]: teamsSlice.reducer,
  [dialogReducerKey]: dialogSlice.reducer,
  [commonReducerKey]: commonSlice.reducer,
  [remoteOpsStatusReducerKey]:remoteOpsStatusSlice.reducer,
  [tabsCurrentlyOpenedForReducerKey]:tabsCurrentlyOpenedForSlice.reducer,
  [formDataReducerKey]:formDataSlice.reducer,
  [chargingProfileReducerKey]:chargingProfileSlice.reducer,
};
