import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { Tooltip } from "@mui/material";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export const ServiceRequestFormSelectItems = [
  {
    label: "Device not charging",
    value: "Device not charging",
  },
  {
    label: "Dashboard",
    value: "Dashboard",
  },
  {
    label: "Charger issues",
    value: "Charger issues",
  },
  {
    label: "CMS user",
    value: "CMS user",
  },
  {
    label: "EV driver",
    value: "EV driver",
  },
  {
    label: "Price Group",
    value: "Price Group",
  },
  {
    label: "Refund",
    value: "Refund",
  },
  {
    label: "Invoice",
    value: "Invoice",
  },
  {
    label: "Reporting",
    value: "Reporting",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const ServiceRequestFormSelectCurrentStatus = [
  {
    label: "New",
    value: "New",
  },
  // {
  //     label:"Acknowledged",
  //     value:"Acknowledged"
  // },
  {
    label: "Assigned",
    value: "Assigned",
  },
  {
    label: "In Progress",
    value: "In Progress",
  },
  {
    label: "Waiting your acceptance",
    value: "Waiting your acceptance",
  },
  {
    label: "Closed",
    value: "Closed",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
];

export const ServiceRequestAssignedStaff = [
  {
    label: "No One",
    value: "No One",
  },
  {
    label: "Staff 1",
    value: "Staff 1",
  },
  {
    label: "Staff 2",
    value: "Staff 2",
  },
  {
    label: "Staff 3",
    value: "Staff 3",
  },
  {
    label: "Staff 4",
    value: "Staff 4",
  },
  {
    label: "Staff 5",
    value: "Staff 5",
  },
  {
    label: "Staff 6",
    value: "Staff 6",
  },
  {
    label: "Staff 7",
    value: "Staff 7",
  },
  {
    label: "Staff 8",
    value: "Staff 8",
  },
  {
    label: "Staff 9",
    value: "Staff 9",
  },
];

export const profileHeaderBar = [
  {
    value: "Service Request",
    label: "Service Request",
  },
  {
    value: "Audit History",
    label: "Audit History",
  },
];

export const roleColumns = [
  {
    field: "username",
    headerName: "Username",
    flex: 1,
  },
  {
    field: "valueTimestamp",
    headerName: "Date and Time",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "previousValue",
    headerName: "Previous Value",
    flex: 1,
  },
  {
    field: "newValue",
    headerName: "New Value",
    flex: 1,
  },
];
