import { createSlice } from "@reduxjs/toolkit";
import { authInitialState, authReducerKey } from "./auth.constants";

export const authSlice = createSlice({
  name: authReducerKey,
  initialState: authInitialState,
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
    setIsAutheticated(state, { payload }) {
      state.isAuthenticated = payload;
    },
    setAuth(state, { payload }) {
      state.isAuthenticated = true;
      state.user = payload;
    },
  },
});
