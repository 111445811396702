import { QueryClient } from "@tanstack/react-query";
import { getStore } from "src/store";
import { alertSlice } from "src/store/alert/alert.slice";

const errorHandler = (error) => {
  getStore().dispatch(
    alertSlice.actions.setAlert({
      severity: "error",
      open: true,
      message: error?.response?.data?.message ?? "Some Error Occured!",
    })
  );
};
const createQueryClient = () => {
  let queryClient;
  return () => {
    if (!queryClient) {
      queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            onError: errorHandler,
          },
        },
      });
    }
    return queryClient;
  };
};

export const getQueryClient = createQueryClient();
