import { memo } from "react";
import capitalize from "lodash/capitalize";
// Material MUI
import Modal from "@mui/material/Modal";
import Switch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

//redux
import { useSelector } from "react-redux";

// user defined components, css and constants
import enums from "src/util/enums";
import { is } from "src/util/is";

function PriceGroupCopyModal({ checkedChange, copiedDays, isAllSelected, onClose, onCopy }) {
    const { open, data } = useSelector(({ dialog }) => dialog);

    return (
        <Modal
            open={open}
            aria-labelledby="modal-copy"
            aria-describedby="#modal-copy"
            onClose={onClose}
        >
            <Box sx={enums.MODAL_STYLE} className="price-group-modal">
                <Typography variant="h4" gutterBottom>
                    Copy Schedule
                </Typography>
                <fieldset className="day-fieldset">
                    <legend>Copy {!is.null(data) && capitalize(data?.day)} to all</legend>
                    <Grid container rowSpacing={2}>
                        <Grid className="switch-all" item md={12}>
                            <FormControlLabel
                                value="all"
                                control={<Switch color="primary" />}
                                label="All"
                                name="isAllSelected"
                                labelPlacement="start"
                                checked={isAllSelected}
                                onChange={checkedChange}
                            />
                        </Grid>
                        {enums.DAYS_IN_STRING.map((day, i) => (
                            day !== data?.day && 
                            <Grid className="text-center" item xs={12} md={6}>
                                <FormControlLabel onChange={checkedChange} name={`day_${day}`} className="day-checkbox" checked={copiedDays[i]} value={enums.DAYS[day]} control={<Checkbox />} label={day} labelPlacement="start" />
                            </Grid>
                        ))}
                    </Grid>
                </fieldset>
                <Box className="text-right">
                    <Button variant="contained" onClick={onCopy} size="large" sx={{ margin: 1 }}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default memo(PriceGroupCopyModal);