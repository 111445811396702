import react, { createContext } from 'react';

const appContext = {
  user: [],
  auth: [],
  message: [],
  category: [],
  userType: [],
  spinner: false
};
export const Context = createContext(appContext);
