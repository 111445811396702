import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { PriceGroupActionMenu } from "./PriceActionMenu";

export const priceGroupListColumns =(isReadOnly)=>( [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: ({ value, id }) => (
      <>
      {isReadOnly?value:<Link underline="none" component={RouterLink} to={`${id}`}>
        {value}
      </Link>}
      </>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "priceGroupType",
    headerName: "Price Type",
    flex: 1,
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: PriceGroupActionMenu,
  },
]);

export const initialState = {
  SUNDAY: false,
  MONDAY: false,
  TUESDAY: false,
  WEDNESDAY: false,
  THURSDAY: false,
  FRIDAY: false,
  SATURDAY: false,
  isAll: false,
  copy: [!1, !1, !1, !1, !1, !1, !1],
}

export const getPriceGroupDefaultValue = (teamId) => ({
  priceGroupId: "",
  name: "",
  description: "",
  currency: "",
  paymentGateway:"",
  paymentGatewayId:"",
  transactionPercent:"",
  taxPercent:"",
  companyId: teamId,
  priceGroupType: "fixed",
  priceDetails: [
    {      
      day: 1,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      // currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    },
    {      
      day: 2,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    },
    {      
      day: 3,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    },
    {      
      day: 4,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    },
    {      
      day: 5,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    },
    {      
      day: 6,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    },
    {      
      day: 0,
      priceDetailsId: 0,
      priceperMin: 0,
      priceperKW: 0,
      fixedFeeStarting: 0,
      fixedFeeCharging: 0,
      minuteFeeCharging: 0,
      idealFeeFullyCharged: 0,
      currency: "INR",
      startTime: "00:00",
      endTime: "23:59",
      priceType: "kwh",
    }
  ]
});
