import { Icon } from "@iconify/react";
import TrashOutline from "@iconify/icons-eva/trash-2-outline";
import { DateTimePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import {
  chargingProfileFormValidationSchema,
  formConfig,
} from "./ChargingProfileConfig";
import AddNewBtn from "src/components/AddNewBtn";
import { useNavigate, useParams } from "react-router-dom";
import {
  useChargingProfileDetails,
  useChargingProfileMutate,
} from "../ChargingProfile.hook";
import { Loader } from "src/components/Loader";
import {
  chargingProfileDetailsUrl,
  chargingProfileListUrl,
  newGetTeamUrl,
} from "src/react-query/endPoints";
import { useDispatch } from "react-redux";
import { changeToISODateTimeString } from "src/utils/formatTime";
import { is } from "src/util/is";
import { alertSlice } from "src/store/alert/alert.slice";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { useChargingProfile } from "src/hooks/useChargingProfile.hook";

const CustomPaper = styled(Paper)({
  textAlign: "center",
  padding: "20px",
  lineHeight: "60px",
});

const initialSchedulePeriods = [
  { start_period: "", power_limit: "", number_phase: "" },
];

const ChargingProfileForm = ({ currentPage }) => {
  const { teamId } = useSelectedTeam()

  //STATES AND HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chargingProfileId } = useParams();
  const { data, isLoading } = useChargingProfileDetails(
  chargingProfileId,
  currentPage
  );
  // const { data, isLoading } = useChargingProfile({
  //   teamId,
  //   chargingProfileId
  // });
  const { mutate: chargingProfileMutate } =
    useChargingProfileMutate("chargingProfile");
  const secondaryBtnValue =
    currentPage === "edit" && chargingProfileId ? "Cancel" : "Reset";
  const formFieldsContainerArr = useMemo(() => [...formConfig.values()], []);
  const initialValues = useMemo(() => {
    let initial = { schedulePeriods: initialSchedulePeriods };
    let restInitial = formFieldsContainerArr.flat().reduce(
      (valObj, { fieldName, value }) => ({
        ...valObj,
        [fieldName]: value ?? "",
      }),
      {}
    );
    return { ...initial, ...restInitial };
  }, [formFieldsContainerArr]);

  //SUBMIT HANDLER FUNC
  const onSubmit = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      valid_from: values["valid_from"]
      ? changeToISODateTimeString(values["valid_from"])
      : null,
      valid_to: values["valid_to"]
      ? changeToISODateTimeString(values["valid_to"])
      : null,
      start_schedule: values["start_schedule"]
      ? changeToISODateTimeString(values["start_schedule"])
      : null,
    };
    const mutationObject = { url: "", method: "", body: updatedValues };
    if (currentPage === "add") {
      mutationObject.url = newGetTeamUrl(teamId);
      mutationObject.method = "POST";
    } else {
      if (chargingProfileId) {
        mutationObject.url = newGetTeamUrl(teamId, chargingProfileId);
        mutationObject.method = "PUT";
      }
    }
    chargingProfileMutate(mutationObject, {
      onSuccess: () => navigate("/chargingprofile"),
    });
    setSubmitting(false);
  };

  //RESET AND CANCEL HANDLER
  const onCancel = () => {
    if (secondaryBtnValue === "Cancel") {
      navigate("/chargingprofile");
    }
    return;
  };

  //FORM STATE AND VALIDATION INITIALISATION
  const formik = useFormik({
    initialValues,
    validationSchema: chargingProfileFormValidationSchema,
    onSubmit,
  });

  //FORM RELATED STATES AND METHODS
  const {
    errors,
    touched,
    handleSubmit,
    handleReset,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    setErrors,
    setTouched,
    setFieldTouched,
    resetForm,
  } = formik;

  //SIDE-EFFECT TO LOAD DETAILS OF SAVED CHARGING PROFILE FOR EDITING
  useEffect(() => {
    if (data) {
      const [chargingProfileDetails] = data;
      resetForm({ values: chargingProfileDetails });
    }
  }, [data, resetForm]);

  //FUNCTION TO UPDATE SCHEDULE PERIODS DATA ARRAY
  const handleSchedulePeriodsDataChange = (index, key, value) => {
    const { schedulePeriods } = values;
    const updatedSchedulePeriods = schedulePeriods.reduce(
      (updatedSchedulePeriods, currentPeriod, i) => {
        let udpdatedPeriod;
        if (i === index) {
          udpdatedPeriod = { ...currentPeriod, [key]: value };
        } else udpdatedPeriod = { ...currentPeriod };
        updatedSchedulePeriods = [...updatedSchedulePeriods, udpdatedPeriod];
        return updatedSchedulePeriods;
      },
      []
    );
    setFieldValue("schedulePeriods", updatedSchedulePeriods);
  };

  //FUNCTION TO UPDATE TOUCHED OBJECT FROM FORMIK
  const handleBlurEvent = (index, key) => {
    if (index === null) setFieldTouched(key, true);
    else setFieldTouched(`schedulePeriods[${index}].${key}`, true);
  };

  //FUNCTION TO ADD OR DELETE SCHEDULE PERIOD
  const handleSchedulePeriodsAddAndDelete = (index = null) => {
    const { schedulePeriods } = values;
    let newSchedulePeriods;
    if (index === null) {
      newSchedulePeriods = [...schedulePeriods, ...initialSchedulePeriods];
    } else if (schedulePeriods.length === 1) {
      return;
    } else {
      newSchedulePeriods = schedulePeriods.filter((period, i) => i !== index);
      if (touched.schedulePeriods) {
        let updatedSchedulePeriodsTouched = touched?.schedulePeriods?.filter(
          (period, i) => i !== index
        );
        setTouched({
          ...touched,
          schedulePeriods: updatedSchedulePeriodsTouched,
        });
      }
    }
    setFieldValue("schedulePeriods", [...newSchedulePeriods]);
  };

  //FUNC TO CALL DELETE API FOR SCHEDULE PERIOD ALREADY SAVED FOR A PROFILE
  const deletePeriod = (periodId, index) => {
    const periodMutateObj = {
      url: `${newGetTeamUrl(teamId)}/schedule_periods/${periodId}`,
      method: "DELETE",
      data: periodId,
    };
    chargingProfileMutate(
      periodMutateObj,
      {
        onSuccess: () => handleSchedulePeriodsAddAndDelete(index),
      },
      {
        onError: (error) =>
          dispatch(
            alertSlice.actions.setAlert({
              open: true,
              message: error?.response?.data?.message ?? error?.message,
              severity: "error",
            })
          ),
      }
    );
  };

  if (isLoading && chargingProfileId) {
    return <Loader />;
  }

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        id="chargingProfile"
        noValidate
        onSubmit={handleSubmit}
        onReset={secondaryBtnValue === "Cancel" ? onCancel : handleReset}
      >
        <Stack spacing={2}>
          {formFieldsContainerArr.length > 0 &&
            formFieldsContainerArr.map((formFieldsContainer, index) => {
              return (
                <>
                  {formFieldsContainer.length > 0 && (
                    <CustomPaper
                      elevation={3}
                      key={`form-fields-container-${index}`}
                    >
                      <Grid container spacing={2}>
                        {formFieldsContainer.map(
                          (
                            {
                              fieldName,
                              fieldType,
                              label,
                              placeholder,
                              valueType,
                              options,
                              readOnly,
                            },
                            i,
                            array
                          ) => {
                            const changedGridMeasures = array.includes(
                              "additionalForm"
                            )
                              ? { xs: 12, lg: 4 }
                              : { xs: 12, lg: 6 };

                            const isReadOnly = readOnly && currentPage === "edit";
                            switch (fieldType) {
                              case "TEXT":
                              case "TEXTAREA":
                                if (!(currentPage === "add" && fieldName === "charging_profile_pk")){
                                  return (
                                    <Grid item {...changedGridMeasures}>
                                      <FormControl fullWidth size="small">
                                        <TextField
                                          name={fieldName}
                                          label={label}
                                          placeholder={placeholder ?? ""}
                                          size="small"
                                          {...getFieldProps(fieldName)}
                                          {...(fieldType === "TEXTAREA" && {
                                            multiline: true,
                                            rows: 4,
                                          })}
                                          InputProps={{
                                            readOnly: isReadOnly,
                                          }}
                                          error={
                                            !!(
                                              touched[fieldName] &&
                                              errors[fieldName]
                                            )
                                          }
                                          helperText={
                                            touched[fieldName] &&
                                            errors[fieldName]
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  );
                                }
                                else return <></>
                              case "DROPDOWN":
                                return (
                                  <Grid item {...changedGridMeasures}>
                                    <FormControl
                                      fullWidth
                                      size="small"
                                      error={
                                        !!(
                                          touched[fieldName] &&
                                          errors[fieldName]
                                        )
                                      }
                                    >
                                      <InputLabel id={fieldName}>
                                        {label}
                                      </InputLabel>
                                      <Select
                                        labelId={fieldName}
                                        label={label}
                                        placeholder={placeholder ?? ""}
                                        {...getFieldProps(fieldName)}
                                      >
                                        <MenuItem selected value="">
                                          <em>NONE</em>
                                        </MenuItem>
                                        {options &&
                                          options.map(({ label, value }) => (
                                            <MenuItem value={value}>
                                              {label}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      {touched[fieldName] && (
                                        <FormHelperText>
                                          {errors[fieldName]}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                );
                              case "DATE/TIME":
                                return (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <Grid item {...changedGridMeasures}>
                                      <FormControl fullWidth size="small">
                                        <DateTimePicker
                                          renderInput={(props) => (
                                            <TextField
                                              {...props}
                                              onBlur={() =>
                                                handleBlurEvent(null, fieldName)
                                              }
                                              error={
                                                !!(
                                                  touched[fieldName] &&
                                                  errors[fieldName]
                                                )
                                              }
                                              helperText={
                                                touched[fieldName] &&
                                                errors[fieldName] && "Please enter valid date"
                                              }
                                            />
                                          )}
                                          {...getFieldProps(fieldName)}
                                          onClose={() =>
                                            handleBlurEvent(null, fieldName)
                                          }
                                          name={fieldName}
                                          label={label}
                                          size="small"
                                          PopperProps={{ placement: "auto" }}
                                          disabled={fieldName!=="start_schedule" && values["charging_profile_purpose"]==="TxProfile" }
                                          disablePast={true}
                                          value={
                                            values[fieldName] === ""
                                              ? null
                                              : values[fieldName]
                                          }
                                          onChange={(newVal) => {
                                            setFieldValue(fieldName, newVal);
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </LocalizationProvider>
                                );
                              default:
                                return <></>;
                            }
                          }
                        )}
                      </Grid>
                    </CustomPaper>
                  )}
                </>
              );
            })}
          <CustomPaper elevation={3} key={`schedule-periods`}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              marginBottom={2}
            >
              <Typography variant="h5"> Schedule Periods</Typography>
              <AddNewBtn
                label="Add Period"
                action={() => handleSchedulePeriodsAddAndDelete()}
              />
            </Stack>
            <Stack>
              {values?.schedulePeriods?.map((schedulePeriod, index) => {
                return (
                  <Grid container spacing={2} marginBottom={2}>
                    {Object.entries(schedulePeriod).map(([key, value]) => {
                      if (key === "schedule_period_pk") return;
                      let attributes = { name: key, size: "small" };
                      switch (key) {
                        case "start_period":
                          attributes = { label: "Start Period(in sec)" };
                          break;
                        case "power_limit":
                          attributes = {
                            label:
                              "Power Limit(In charging rate unit selected above)",
                            placeholder: "In charging rate unit selected above",
                          };
                          break;
                        case "number_phase":
                          attributes = {
                            label: "Number Phases",
                            placeholder: "If empty, 3 will be assumed",
                          };
                          break;
                        default:
                          break;
                      }
                      return (
                        <Grid item xs={12} lg={key === "power_limit" ? 4 : 3}>
                          <FormControl fullWidth size={attributes.size}>
                            <TextField
                              {...attributes}
                              value={value}
                              //  {...getFieldProps(key)}
                              onChange={(e) =>
                                handleSchedulePeriodsDataChange(
                                  index,
                                  key,
                                  e.target.value
                                )
                              }
                              onBlur={() => handleBlurEvent(index, key)}
                              error={
                                !!(
                                  touched.schedulePeriods &&
                                  touched.schedulePeriods[index] &&
                                  touched.schedulePeriods[index][key] &&
                                  errors.schedulePeriods &&
                                  errors.schedulePeriods[index] &&
                                  errors.schedulePeriods[index][key]
                                )
                              }
                              helperText={
                                touched.schedulePeriods &&
                                touched.schedulePeriods[index] &&
                                touched.schedulePeriods[index][key] &&
                                errors.schedulePeriods &&
                                errors.schedulePeriods[index] &&
                                errors.schedulePeriods[index][key]
                              }
                            />
                          </FormControl>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} lg={2}>
                      <Tooltip
                        title="Remove Schedule Period"
                        placement="right-end"
                      >
                        <LoadingButton
                          size="medium"
                          type="button"
                          variant="contained"
                          onClick={() =>
                            !is.empty(schedulePeriod["schedule_period_pk"])
                              ? deletePeriod(
                                  schedulePeriod["schedule_period_pk"],
                                  index
                                )
                              : handleSchedulePeriodsAddAndDelete(index)
                          }
                          color="error"
                        >
                          <Icon
                            icon={TrashOutline}
                            fontSize="1.4rem"
                            className="fs-5"
                          />
                        </LoadingButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </Stack>
          </CustomPaper>
          <CustomPaper>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2}>
                  <LoadingButton size="large" type="reset" variant="outlined">
                    {secondaryBtnValue}
                  </LoadingButton>
                  <LoadingButton size="large" type="submit" variant="contained">
                    Save
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </CustomPaper>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default ChargingProfileForm;
