import { createSlice } from "@reduxjs/toolkit";

export const dialogReducerKey = "dialog";
export const dialogInitialState = {
  open: false,
  data: null,
  refetch:false
};

export const dialogSlice = createSlice({
  name: dialogReducerKey,
  initialState: dialogInitialState,
  reducers: {
    setDialog(state, { payload }) {
      state.open = payload.open;
      state.data = payload.id;
      if(payload.refetch) state.refetch = payload.refetch
    },
  },
});
