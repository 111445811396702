import { createSlice } from "@reduxjs/toolkit";
import { setupInitialState, setupReducerKey } from "./setup.constants";

export const setupSlice = createSlice({
  name: setupReducerKey,
  initialState: setupInitialState,
  reducers: {
    setCategories(state, { payload }) {
      state.categories = payload;
    },
    setUserTypes(state, { payload }) {
      state.userTypes = payload;
    },
  },
});
