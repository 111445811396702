import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithError } from "src/react-query/fetchWithError";
import { queryKeys } from "src/react-query/queryKeys";
import { authSlice } from "src/store/auth/auth.slice";

export const useUser = () => {
  const { isAuthenticated, user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  return useQuery(queryKeys.user(), fetchWithError, {
    enabled: isAuthenticated && !user,
    onSuccess: (data) => {
      dispatch(authSlice.actions.setUser(data?.data));
    },
  });
};
