import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import { alertSlice } from "src/store/alert/alert.slice";
import { FEATURE } from "src/util/enums";
import Page from "../Page";
import { CompanyForm } from "./CompanyForm";

// import { TeamForm } from "./TeamForm";

export const Company = () => {
  const navigate = useNavigate();
  const isCreateCompany = useMatch("/company/new");
  const isReadOnly = useAccess(FEATURE.COMPANY, "R");
  const notAuthorized = useAccess(FEATURE.COMPANY, "N");
  const createAccess = useAccess(FEATURE.COMPANY, "C");
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (isCreateCompany && !createAccess) ||
      (!isCreateCompany && notAuthorized)
    ) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate(sidebarConfig[FEATURE.COMPANY].path);
    }
  }, [dispatch, isCreateCompany, createAccess, navigate, notAuthorized]);

  return (
    <Page title="Company | Smart-CMS">
      <BackButton
        text={
          isCreateCompany
            ? "Create Company"
            : isReadOnly
            ? "Company Details"
            : "Edit Company Details"
        }
        path="/company"
      />
      <CompanyForm />
    </Page>
  );
};
