import { useAccess } from "src/hooks/useAccess.hook";
import deleteFill from "@iconify/icons-eva/trash-2-outline";

import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { getTeamUrl, getUserIdUrl } from "src/react-query/endPoints";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FEATURE } from "src/util/enums";

export const TeamMembersActionMenu = ({ id }) => {
  const hasEditAccess = useAccess(FEATURE.TEAM, "U");
  const { data: teamId } = useSelector(({ dialog }) => dialog);
  const { mutate } = useMutate("usersByTeam");
  const onDelete = useCallback(() => {
    mutate({ url: getTeamUrl(teamId, getUserIdUrl(id)), method: "DELETE" });
  }, [id, teamId, mutate]);
  const actionList = useMemo(
    () => [
      // {
      //   id: "view",
      //   title: "View",
      //   icon: viewFill,
      //   routerLink: `/team/${id}`,
      //   hidden: !isReadOnly,
      // },
      // {
      //   id: "edit",
      //   title: "Edit",
      //   icon: editFill,
      //   routerLink: `/team/${id}`,
      //   hidden: isReadOnly,
      // },
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onDelete,
        hidden: !hasEditAccess,
      },
    ],
    [hasEditAccess, onDelete]
  );

  return <ActionsMenu menuList={actionList} />;
};
