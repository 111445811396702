export const downloadExcel = (response, type) =>{
    const url = window.URL.createObjectURL(new Blob([response]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${type}.xlsx`);

    document.body.appendChild(link);

    link.click();
    link.parentNode.removeChild(link);
}