import { createSlice } from "@reduxjs/toolkit";
import { formDataInitialState, formDataReducerKey } from "./formData.constants";

export const formDataSlice = createSlice({
    name:formDataReducerKey,
    initialState:formDataInitialState,
    reducers:{
        setFormData(state,{payload}){
            // console.log(payload)
            state.formData ={...payload.formData};
            state.formDataOf =payload.formDataOf;
        }
    }
})