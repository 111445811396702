import { useCallback, useMemo } from "react";
import { ActionsMenu } from "../Actions/ActionsMenu";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import { useMutate } from "src/hooks/useFetch.hook";
import { customerTagsById } from "src/react-query/endPoints";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";
import { useParams } from "react-router-dom";

const CustomerTagActionMenu = ({id,refetch,removeTagFn,...rest}) => {
  const dispatch = useDispatch();
  const { id:customerId } = useParams(); 
  const hasDeleteAccess = useAccess(FEATURE.CUSTOMERS, "D");
  const { mutate } = useMutate("customerTags");
  const onDelete = useCallback(()=>{
    if(removeTagFn){
      removeTagFn(id)
      return;
    }
    mutate({
        url:customerTagsById(customerId,id),
        method: "DELETE",
    }, {
        onSuccess: () => {
            dispatch(
                alertSlice.actions.setAlert({
                  open: true,
                  message: "Successfully disassociated user with selected tag",
                  severity: "success",
                })
              )
            refetch();
        }
    }
    );
  },[id,customerId,mutate,refetch,dispatch])
    const actionList = useMemo(
        () => [    
          {
            id: "delete",
            title: "Remove",
            icon: deleteFill,
            action: onDelete,
            hidden: !hasDeleteAccess,
          },
        ],
        [hasDeleteAccess, onDelete]
      );

      return <ActionsMenu menuList={actionList} />;
}

export default CustomerTagActionMenu