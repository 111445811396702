import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducers";

const createStore = () => {
  let store;
  return () => {
    if (!store) {
      store = configureStore({
        reducer: combineReducers(rootReducer),
        devTools: true,
      });
    }
    return store;
  };
};

export const getStore = createStore();
