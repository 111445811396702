
export const fileToBase64 =(file)=>{
    let reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((res,rej)=>{
        reader.onload =  ()=> {
                    res(`${reader.result}`)
        };
        reader.onerror = (error)=>{
          console.log('Error: ', error);
          rej(error)
        };

    })
}