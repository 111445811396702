// material
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import { alertSlice } from "src/store/alert/alert.slice";
import { FEATURE } from "src/util/enums";
//
import Page from "../Page";
import ChargerForm from "./ChargerForm";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
// ----------------------------------------------------------------------

export const Charger = () => {
  const navigate = useNavigate();
  const match = useMatch("/charger/new");
  const isReadOnly = useAccess(FEATURE.CHARGER, "R");
  const notAuthorized = useAccess(FEATURE.CHARGER, "N");
  const createAccess = useAccess(FEATURE.CHARGER, "C");
  const dispatch = useDispatch();
  const { detailsId } = useSelector(
    ({ tabsCurrentlyOpenedFor }) => tabsCurrentlyOpenedFor
  );

  useEffect(() => {
    if ((match && !createAccess) || (!match && notAuthorized)) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate("/charger", { replace: true });
    }
  }, [dispatch, match, createAccess, navigate, notAuthorized]);

  const onBackButtonClick = ()=>{
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:"",
      tabOpenedFor:"",
      tabOpened:""
    }))
  }
  return (
    <Page title="Charge Point | Smart-CMS">
      <BackButton
        text={
          match
            ? "Add new Charge Point"
            : isReadOnly
            ? "Charge Point Details"
            : `Edit Charge Point (${detailsId ? detailsId : ""})`
        }
        path="/charger"
        action={onBackButtonClick}
      />
      <ChargerForm />
    </Page>
  );
};
