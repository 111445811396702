import { memo, useMemo } from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

// custom components
import SearchNotFound from "../SearchNotFound";
import DatatableActions from "./DatatableActions";

function DatatableBody({
  bodyData,
  page,
  rowsPerPage,
  selectedData,
  onRowSelected,
  selectedColumnName,
  columnAlign,
  onRowEdit,
  renderCell,
  actions,
  emptyRows,
  isDataNotFound,
  filterText,
  columnSchema,
  onRowDelete,
  primaryKey,
}) {
  const slicedBodyData = useMemo(() => {
    return bodyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [bodyData, rowsPerPage, page]);

  return (
    <>
      <TableBody>
        {slicedBodyData.map((body) => (
          <TableRow
            hover
            key={body.id}
            tabIndex={-1}
            role="checkbox"
            selected={selectedData.indexOf(body[selectedColumnName]) !== -1}
            aria-checked={selectedData.indexOf(body[selectedColumnName]) !== -1}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectedData.indexOf(body[selectedColumnName]) !== -1}
                onChange={(event) =>
                  onRowSelected(event, body[selectedColumnName])
                }
              />
            </TableCell>
            {columnSchema.map((schema) => (
              <>
                <TableCell key={JSON.stringify(body)} align={columnAlign}>
                  {renderCell(body[schema], schema, body)}
                </TableCell>
              </>
            ))}
            {actions && (
              <TableCell align="right">
                <DatatableActions
                  row={body}
                  onTableRowDelete={onRowDelete}
                  onTableRowEdit={onRowEdit}
                />
              </TableCell>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableRow>
        ))}
      </TableBody>
      {isDataNotFound && (
        <TableBody>
          <TableRow>
            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
              <SearchNotFound searchQuery={filterText} />
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  );
}

DatatableBody.defaultProps = {
  bodyData: [],
  page: 0,
  rowsPerPage: 5,
  selectedData: [],
  selectedColumnName: "id",
  columnAlign: "left",
  onRowSelected: () => {},
  actions: null,
  emptyRows: 0,
  isDataNotFound: false,
  filterText: "",
  columnSchema: [],
  actions: false,
  primaryKey: "",
  renderCell: (col) => col,
  onRowDelete: () => {},
  onRowEdit: () => {},
};

export default memo(DatatableBody);
