import Tooltip from "@mui/material/Tooltip";
export const remoteOpsStatusReducerKey = "remoteOpStatus"
export const remoteOpsStatusInitialState = {
    remoteOperationPerformed:"",
    modalOpen:false,
    operationDetails:{
      chargerId:null,
      response:null
    }
}

export const statusColumns =[
    {
        field: "charge_box_id",
        headerName: "Charge Box ID",
        flex: 0.8,
      },
      {
        field: "response",
        headerName: "Response",
        flex: 1,
        renderCell:({value})=><Tooltip title={value} placement="top">
          <span>{value}</span>
        </Tooltip>
      },
      {
        field: "error",
        headerName: "Error",
        flex: 1,
        renderCell:({value})=><Tooltip title={value} placement="top">
          <span>{value}</span>
        </Tooltip>
      },
      {
        field: "details",
        headerName: "Details",
        flex: 1,
        renderCell:({value})=><Tooltip title={value} placement="top">
          <span>{value}</span>
        </Tooltip>
      },
]