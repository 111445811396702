import { Box, ImageListItem, Button, Modal, Typography } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

const ImgPreviewModal = ({ buttonsArray, imageObj, currentSavedImg }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
  };

  const imgStyle = {
    maxHeight: "90vh",
    maxWidth: "100%",
  };
  return (
    <Modal
      open={imageObj.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ImageListItem sx={modalStyle}>
        {imageObj?.imgType === "terms_n_condition_url" ? (
          <Typography sx={{
            color: "#00AB55",
            fontWeight: "700",
          }}>{imageObj?.value?.name}</Typography>
        ) : imageObj.type !== "charger" ? (
          <img
            style={imgStyle}
            src={`${
              imageObj.value
                ? imageObj.value instanceof File
                  ? URL.createObjectURL(imageObj.value)
                  : imageObj.value
                : ""
            }`}
            alt="img"
          />
        ) : (
          <>
            <Typography
              variant="h5"
              sx={{ marginBottom: 1 }}
            >{`CHARGER - ${imageObj.value}`}</Typography>
            <QRCode
              id={`chargerqr-${imageObj.value}`}
              value={`${imageObj.templateVal}${imageObj.value}`}
              style={imgStyle}
            />
          </>
        )}
        <Box sx={{ mt: 1, display: "flex", gap: "1rem"}}>
          {buttonsArray.length > 0 &&
            buttonsArray.map((buttonObj, index) => (
              <Button
                 variant={buttonObj.btnLabel === "Cancel" ? "outlined":"contained"}
                onClick={() => {
                  if (buttonObj.params) {
                    if (buttonObj.btnLabel === "Cancel" && currentSavedImg) {
                      buttonObj.action(currentSavedImg);
                    } else {
                      buttonObj.action(imageObj.value, imageObj?.imgType);
                    }
                  } else buttonObj.action(imageObj?.imgType);
                }}
                key={`${index}-qr-${imageObj.type}`}
              >
                {buttonObj.btnLabel}
              </Button>
            ))}
        </Box>
      </ImageListItem>
    </Modal>
  );
};

export default ImgPreviewModal;
