import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import { useServiceRequests } from "../serviceRequest.hook";
import { serviceRequestListColumns } from "./ServiceRequestList.constants";
import { useNavigate } from "react-router-dom";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import AddNewBtn from "src/components/AddNewBtn";
import { useAuth } from "src/hooks/useAuth.hook";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ServiceRequestList = () => {
  const navigate = useNavigate();
  const { teamId } = useSelector((state) => state.teams);
  const hasCreateAccess = useAccess(FEATURE.SERVICEREQUEST, "C");
  const isReadOnly = useAccess(FEATURE.SERVICEREQUEST, "R");
  const {
    user,
    user: {
      company: { id },
    },
  } = useAuth();
  const isAdmin = useMemo(() => user?.user?.id === 1, [user]);
  const [tableData, setTableData] = useState([]);
  const rowOrCellClickRedirect = ({ id }) => {
    if (isReadOnly) return;
    navigate(`${id}`);
  };
  const { data, isFetching } = useServiceRequests(teamId, id);
  const [filterArr, setFilterArr] = useState({
    companyList: [],
    teamList: [],
    selectedCompany: [],
    selectedTeam: [],
  });

  useEffect(() => {
    if (!isFetching) {
      let arr = [...data];
      setTableData(arr);

      let companyList = [...new Set(data?.map((item) => item.companyName))];
      let teamList = [...new Set(data?.map((item) => item.teamName))];
      setFilterArr((prev) => ({
        ...prev,
        companyList: companyList,
        teamList: teamList,
      }));
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = data;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "summary",
            "email",
            "issueType",
            "phone",
            // "status",
            "statusType",
            "ticket_id",
            "createAt",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "createAt"
                ? yearFirstDateTimeFormat(value).toString()
                : value
                ? value.toString().toLowerCase()
                : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      // console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...data]);
  };

  const handleChangeCompanyName = (event) => {
    const {
      target: { value },
    } = event;
    let dataArr = typeof value === "string" ? value.split(",") : value;
    setFilterArr((prev) => ({
      ...prev,
      selectedCompany: dataArr,
    }));
    let filteredTableData =
      dataArr.length > 0 || filterArr.selectedTeam.length > 0
        ? filterArr.selectedTeam.length > 0
          ? data.filter(
              (data) =>
                dataArr.includes(data.teamName) ||
                filterArr.selectedTeam.includes(data.teamName)
            )
          : data.filter((data) => dataArr.includes(data.companyName))
        : data;

    // console.log(filteredTableData);
    setTableData(filteredTableData);

    if (dataArr.length > 0) {
      let teamList = [
        ...new Set(filteredTableData?.map((item) => item.teamName)),
      ];
      setFilterArr((prev) => ({
        ...prev,
        teamList: teamList,
      }));
    } else{
      let teamList = [
        ...new Set(data.map((item) => item.teamName)),
      ];
      setFilterArr((prev) => ({
        ...prev,
        teamList: teamList,
      }));
    }
  };

  const handleChangeTeamName = (event) => {
    const {
      target: { value },
    } = event;
    let dataArr = typeof value === "string" ? value.split(",") : value;
    setFilterArr((prev) => ({
      ...prev,
      selectedTeam: dataArr,
    }));
    let filteredTableData =
      dataArr.length > 0 || filterArr.selectedCompany.length > 0
        ? dataArr.length > 0
          ? data.filter((data) => dataArr.includes(data.teamName))
          : data.filter((data) =>
              filterArr.selectedCompany.includes(data.companyName)
            )
        : data;
    // console.log(filteredTableData);
    setTableData(filteredTableData);
  };

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ placeContent: "end" }}>
        {hasCreateAccess && (
          <AddNewBtn label="Add Service Request" to="/servicerequest/new" />
        )}
      </Stack>
      <Card sx={{ p: 2 }}>
        <Box>
          <CommonSearchbar placeholder="Search" searchFunc={handleSearching} />
          {isAdmin && filterArr.companyList.length > 0 && filterArr.teamList.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">
                  Select Company
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={filterArr.selectedCompany}
                  onChange={handleChangeCompanyName}
                  input={<OutlinedInput label="Company Name" />}
                  MenuProps={MenuProps}
                >
                  {filterArr?.companyList?.length > 0 &&
                    filterArr.companyList.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        //   style={getStyles(name,chargeBoxName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={filterArr.selectedTeam}
                  onChange={handleChangeTeamName}
                  input={<OutlinedInput label="Team Name" />}
                  MenuProps={MenuProps}
                >
                  {filterArr?.teamList?.length > 0 &&
                    filterArr.teamList.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        //   style={getStyles(name,chargeBoxName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        <BaseTable
          rows={tableData}
          loading={isFetching}
          columns={serviceRequestListColumns(isReadOnly, teamId)}
          getRowId={(row) => row.ticket_id}
          pageSize={10}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          isReadOnly={isReadOnly}
          showExportCsvBtn={true}
          csvMorphemes="service_request"
          csvColumns={serviceRequestListColumns(isReadOnly)}
        />
      </Card>
    </>
  );
};

export default ServiceRequestList;
