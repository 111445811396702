import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Page from "src/components/Page";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import { refundColumns } from "./Refund.constant";
import enums, { FEATURE } from "src/util/enums";
import { differenceInHours } from "date-fns";

//hooks, actions
import { useAccess } from "src/hooks/useAccess.hook";
import { useList } from "src/hooks/useFetch.hook";
import { useMutate } from "src/hooks/useFetch.hook";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";

//urls
import { refundUrl } from "src/react-query/endPoints";

const Refund = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const refundRef = useRef({ selectedRows: [] });
  const { data, isLoading, refetch } = useList("refund");
  const { mutateAsync } = useMutate('refund');
  const hasReadOnlyAccess = useAccess(FEATURE.REFUND, "V");

  const onRefundAllClick = async () => {
    const { actions: { setAlert } } = alertSlice;
    const { current: { selectedRows } } = refundRef;
    const rows = selectedRows.filter((r) => !r.refundStatus);
    if (!rows.length) {
      dispatch(setAlert({
        open: true,
        message: "Please select row to bulk refund",
        severity: enums.SEVERITY.ERROR,
      }));
      return;
    }

    await mutateAsync({
      url: refundUrl,
      method: 'POST',
      body: { 
        refundBody: selectedRows.map((select) => ({ 
          transactionId: select.transactionId,  
          ocppIdTag: select.tag
        }))
      }
    }, { onSuccess: () => refetch() });
  }

  const onTableStateChange = (state) => {
    const { selection, rows: { idRowsLookup } } = state;
    let { current } = refundRef;
    current.selectedRows = selection.map((select) => idRowsLookup[select]);
  }

  const isRowSelectable = (params) => (hasReadOnlyAccess && 
    differenceInHours(new Date(), new Date(params.row.startDate)) <= 24 && 
    enums.REFUND_STATUS.PROCESSED !== params.row.refundStatus);

    const isReadOnly = useAccess(FEATURE.REFUND, "R");

    const rowOrCellClickRedirect = ({ id, field }) => {
      if(isReadOnly) return;
      if (field !== 'Refund')
        navigate(`/refund/${id}`);
    };

  return (
    <Page title="Refund | Smart-CMS">
      <Card sx={{ p: 2 }}>
        <Box className="data-grid-actions mb-3 text-right">
          {hasReadOnlyAccess && 
          <Button variant="contained" size="medium" onClick={onRefundAllClick}>
            Refund All
          </Button>}
        </Box>
        <BaseTable
          rows={data || []}
          columns={refundColumns(refetch, hasReadOnlyAccess,isReadOnly)}
          loading={isLoading}
          getRowId={(row) => row.transactionNumber}
          pageSize={10}
          onStateChange={onTableStateChange}
          checkboxSelection
          disableSelectionOnClick
          isRowSelectable={isRowSelectable}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          isReadOnly={isReadOnly}
        />
      </Card>
    </Page>
  );
};

export default Refund;