import { Box, Button, Modal, Typography } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { getTeamUrl, viewLogsUrl } from "src/react-query/endPoints";
import { fetchInfinite } from "src/react-query/fetchWithError";
import { fLocalTime,yearFirstDateTimeFormat } from "src/utils/formatTime";
import InfiniteScroll from "react-infinite-scroll-component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "500px",
};

export const ViewLogsModal = ({ open, onClose, chargerId }) => {
  const { teamId } = useSelectedTeam();
  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["viewLogs", { url: getTeamUrl(teamId, viewLogsUrl(chargerId)) }],
    fetchInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === 0) {
          return;
        }
        return pages.length + 1;
      },
    }
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box id="scrollableDiv" sx={style}>
        <LogsData data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage}/>
        <Button
          variant="outlined"
          onClick={onClose}
          size="large"
          sx={{ marginTop: 3 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};


export const LogsData = ({data,fetchNextPage,hasNextPage})=>{
  return(
    <InfiniteScroll
          dataLength={data?.pages?.flat()?.length ?? 0}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<h4>Loading more logs...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {data?.pages.map((page, index) => (
            <Fragment key={index}>
              {page?.map(({ messageTime, messageType, message }, index) => (
                <Box mb={2} key={index}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      color: messageType === "INCOMING" ? "blue" : "green",
                      marginRight: "10px",
                      fontSize: "16px",
                    }}
                  >
                    {messageType}:
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ marginRight: "10px", fontSize: "16px" }}
                  >
                    {yearFirstDateTimeFormat(messageTime)} -
                  </Typography>

                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ fontSize: "16px", wordWrap: "break-word" }}
                  >
                    {message}
                  </Typography>
                </Box>
              ))}
            </Fragment>
          ))}
        </InfiniteScroll>
  )
}