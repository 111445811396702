export const chargingProfileReducerKey = "chargingProfile";
export const chargingProfileInitialState = [{
    "charging_profile_pk": null,
    "stack_level": null,
    "charging_profile_purpose": "",
    "charging_profile_kind": "",
    "recurrency_kind": "",
    "valid_from": "",
    "valid_to": "",
    "duration_in_seconds": null,
    "start_schedule": "",
    "charging_rate_unit": "",
    "min_charging_rate": "",
    "description": "",
    "note": "",
    "charging_profile_id": "",
    "companyId": null
}]