import { createSlice } from "@reduxjs/toolkit";
import { commonReducerKey, commonInitialState } from "./common.constants";

export const commonSlice = createSlice({
  name: commonReducerKey,
  initialState: commonInitialState,
  reducers: {
    setFeatures(state, { payload }) {
      state.features = payload;
    },
    setFeatureAccess(state, { payload }) {
      const updatedFeatures = state.features.map((feature) => {
        if (payload.id === feature.featureId) {
          return { ...feature, [payload.accessName]: payload.value };
        }
        return feature;
      });
      state.features = updatedFeatures;
    },
  },
});
