// formik
import { useFormik, Form, FormikProvider } from "formik";
//mui components
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { LoadingButton } from "@mui/lab";
// custom components, services and constants
import Page from "src/components/Page";
import { verficationValidationSchema } from "src/util/validationSchemas";
import { BackButton } from "src/common/components/BackButton";
import { authService } from "src/services";
import enums from "src/util/enums";
// hooks
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
//actions
import { alertSlice } from "src/store/alert/alert.slice";

function ChangePassword() {
    const dispatch = useDispatch();
    const { mutateAsync, isLoading } = useMutation(authService.changePassword);

    const onSubmit = async (values) => {
        const { actions: { setAlert } } = alertSlice;
        const { old_password, new_password } = values;
        await mutateAsync({ 
            new_password: window.btoa(new_password),
            old_password: window.btoa(old_password),
         }, {
            onSuccess: () => {
                dispatch(setAlert({
                    open: true,
                    message: "Password updated successfully",
                    severity: enums.SEVERITY.SUCCESS,
                }));
            },
            onError: (error) => {
                dispatch(setAlert({
                    open: true,
                    message: error?.response?.data?.message ?? error?.message,
                    severity: enums.SEVERITY.ERROR,
                }));
            },
        });
    }

    const formik = useFormik({
        initialValues: {
          otp: 1234,
          old_password: "",
          new_password: "",
          confirm_password: ""
        },
        validationSchema: verficationValidationSchema,
        onSubmit,
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
    <Page
        title="Change Password | Smart-CMS"
    >
        <BackButton
        text="Change Password"
        path="/dashboard"
        />
        <Container maxWidth="sm">
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" id="change-password" onSubmit={handleSubmit}>
                <FormControl fullWidth variant="standard">
                        <Typography variant="caption">Old Password</Typography>
                        <TextField 
                            size="small"
                            type="password"
                            error={Boolean(touched.old_password && errors.old_password)}
                            helperText={touched.old_password && errors.old_password}
                            {...getFieldProps("old_password")}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="standard">
                        <Typography variant="caption">New Password</Typography>
                        <TextField 
                            size="small"
                            type="password"
                            error={Boolean(touched.new_password && errors.new_password)}
                            helperText={touched.new_password && errors.new_password}
                            {...getFieldProps("new_password")}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="standard">
                        <Typography variant="caption">Confirm Password</Typography>
                        <TextField 
                            size="small"
                            type="password"
                            error={Boolean(touched.confirm_password && errors.confirm_password)}
                            helperText={touched.confirm_password && errors.confirm_password}
                            {...getFieldProps("confirm_password")}
                        />
                    </FormControl>
                    <LoadingButton type="submit" loading={isLoading} fullWidth variant="contained">Change Password</LoadingButton>
                </Form>
            </FormikProvider>
        </Container>
    </Page>
    )
}

export default ChangePassword;