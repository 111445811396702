import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {FEATURE} from "src/util/enums"
import ServiceRequestList from './ServiceRequestList/ServiceRequestList'
import sidebarConfig from 'src/layouts/dashboard/SidebarConfig'
import { useAccess } from 'src/hooks/useAccess.hook'
import { Stack } from '@mui/material'
import Page from 'src/components/Page'

const ServiceRequestView = () => {
const dispatch = useDispatch();
const navigate = useNavigate();

useEffect(()=>{
 navigate(sidebarConfig[FEATURE.SERVICEREQUEST].path)
},[navigate])
  return (
    <Page title="Service Request | Smart-CMS">
    <Stack spacing={2} >
        <ServiceRequestList/>
        </Stack>
        </Page>

  )
}

export default ServiceRequestView