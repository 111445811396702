import { UserListToolbar } from "../_dashboard/user";
import ChargerListToolbar from "../charger/ChargerListToolbar";
import enums from "src/util/enums";

function DatatableToolbar({ useToolbar, ...props }) {
  return (
    <>
      {useToolbar === enums.toolbars.USER && <UserListToolbar {...props} />}
      {useToolbar === enums.toolbars.CHARGER && (
        <ChargerListToolbar {...props} />
      )}
    </>
  );
}

DatatableToolbar.defaultProps = {
  useToolbar: enums.toolbars.USER,
  totalSelected: 0,
  filterText: "",
  onFilter: () => {},
};

export default DatatableToolbar;
