import { Tooltip, Checkbox } from "@mui/material";
import { ChargeZoneActionMenu } from "./ChargeZoneActionMenu";


export const chargeZoneColumn = ({ createZoneBtn, setCreateZoneBtn, handleAction }) => ([
  {
    field: "zone_name",
    headerName: "Zone Name",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: "load_balancing_value",
    headerName: "Value",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: "load_balancing_unit",
    headerName: "Unit",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: "Actions",
    headerName: "Actions",
    flex: 0.5,
    renderCell: (params) => (
      <ChargeZoneActionMenu
        setCreateZoneBtn={setCreateZoneBtn}
        rowData={params.row}
        handleAction={handleAction}
      />
    ),
  },
]);

export const availableChargerColumn = ({handleSelectionChange, tableData}) => ([
  {
    field: 'checkbox',
    headerName: 'Checkbox',
    renderCell: (params) => ( 
      <Checkbox
        checked={params.row.selected} // Check if the row is selected
        onChange={(event) => {
          const updatedRows = [params.row];
          const rowIndex = updatedRows.findIndex(row => row.id === params.row.id); // Find the index of the row
          updatedRows[rowIndex].selected = event.target.checked; // Update the selected property
          const result = tableData.map((data) => ({
            ...data,
            selected: data.chargerId === params.row.id ? true : data.selected
          }));
          handleSelectionChange(result); // Call the callback to update the selected rows
        }}
      />
    ),
    sortable: false,
    width: 100,
  },
  {
    field: "chargerId",
    headerName: "Charger Id",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  }
]);

export const chargeZoneTab =[
  { value: "CHARGING ZONE", label: "CHARGING ZONE" },
  // { value: "LOAD BALANCING", label: "LOAD BALANCING" }
]