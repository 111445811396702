import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import plusFill from "@iconify/icons-eva/plus-fill";

// ----------------------------------------------------------------------

const Page = forwardRef(
  (
    { children, title = "", path, action, actionButtonText = "", ...other },
    ref
  ) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="xl" 
        sx={{ "@media screen and (min-width:600px)": { padding: "10px" } }}
      >
        {(path || action) && actionButtonText && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            mb={5}
          >
            <Button
              variant="contained"
              {...(path && { to: path, component: RouterLink })}
              {...(action && { onClick:()=>action() })}
              startIcon={<Icon icon={plusFill} />}
            >
              {actionButtonText}
            </Button>
          </Stack>
        )}
        {children}
      </Container>
    </Box>
  )
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
