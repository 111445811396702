// Custom Components
import Page from "../Page";
import { useState, useEffect } from "react";
import { Card, Stack } from "@mui/material";
import { BaseTable } from "../BaseTable/BaseTable";
import { teamsColumns } from "./teams.constants";
import { useAccess } from "src/hooks/useAccess.hook";
import { useList } from "src/hooks/useFetch.hook";
import { TeamUserModal } from "./TeamUserModal";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import { useDispatch, useSelector } from "react-redux";
import { FEATURE } from "src/util/enums";
import { useNavigate } from "react-router-dom";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export const TeamList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const hasCreateAccess = useAccess(FEATURE.TEAM, "C");
  const isReadOnly = useAccess(FEATURE.TEAM, "R");
  const { data: teams, isFetching } = useList("teams");
  const { open, data } = useSelector(({ dialog }) => dialog);
  const [tableData, setTableData] = useState();
  const handleClose = () => {
    dispatch(dialogSlice.actions.setDialog(false));
  };
  const rowOrCellClickRedirect = ({ id, row }) => {
    if (isReadOnly) return;
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId: row?.name,
      tabOpenedFor: "team",
      tabOpened: "general"
    }))
    navigate(`/team/${id}`);
  };

  useEffect(() => {
    if (!isFetching) {
      let data = [...teams];
      setTableData(data);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = teams;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "name",
            "active",
            "description",
            "creationTime",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "creationTime"
                ? yearFirstDateTimeFormat(value).toString()
                : value === "Y" ? "active" : value === "N" ? "inactive" : value ? value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      // console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...teams]);
  };

  return (
    <Page
      title="Team | Smart-CMS"
      {...(hasCreateAccess && { path: `/team/new` })}
      actionButtonText="Add Team"
    >
      <Card sx={{ p: 2 }}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
        <BaseTable
          rows={tableData ?? []}
          columns={teamsColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.teamId}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          pageSize={10}
          isReadOnly={isReadOnly}
          showExportCsvBtn={true}
          csvMorphemes="teams"
          csvColumns={teamsColumns(isReadOnly)}
        />
        {open && (
          <TeamUserModal open={open} teamId={data} onClose={handleClose} />
        )}
      </Card>
    </Page>
  );
};
