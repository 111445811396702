import { getLicensePaymentStatus } from "src/react-query/endPoints";
import axios from "../../util/axios";
import { PAYMENT_ENDPOINTS } from "./payment.constant";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export async function DisplayRazorpay({
    amount, orderId, currency,
    setPaymentStatus }
) {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    const checkPaymentStatus = async (orderId) => {
        try {
            const url = getLicensePaymentStatus();
            const payload = {
                orderId: orderId
            }
            const response = await axios.post(url, payload);
            return { success: true, data: response.data };
        } catch (err) {
            return { success: false, error: err };
        }
    }

    let contact;
    let email;
    const options = {
        key: process.env.REACT_APP_CHARGEMILES_RAZORPAY_KEY,
        amount: amount.toString(),
        currency: currency,
        name: "Oaza Energy Pvt. Ltd.",
        description: "Payment for getting License",
        order_id: orderId,
        handler: async function (response) {
            const requestData = {
                orderCreationId: orderId,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpaySignature: response.razorpay_signature,
            };

            const { data } = await axios.post(
                PAYMENT_ENDPOINTS.getVerifyPaymentUrl,
                requestData
            );

            if (data?.success) {
                let counter = 0;
                const intervalId = setInterval(async () => {
                    if (counter >= 5) {
                        clearInterval(intervalId);
                        setPaymentStatus({
                            isDone: true,
                            status: false,
                            message: "Payment is failed. Please try again!",
                        });
                    } else {
                        const status = await checkPaymentStatus(orderId);
                        // console.log(status)
                        if (status?.success) {
                            setPaymentStatus({
                                isDone: true,
                                status: true,
                                message: "Payment is successful.",
                            });
                            clearInterval(intervalId);
                        } else {
                            counter++;
                        }
                    }
                }, 2000);
            }
        },
        prefill: {
            email,
            contact,
        },
        hidden: {
            email: !email,
            contact: !contact,
        },
        readonly: {
            email: true,
            contact: true,
        },
        notes: {
        },
        theme: {
            color: "#86bb45",
        },
        config: {
            display: {
                hide: [
                    {
                        method: "cardless_emi",
                    },
                    {
                        method: "paylater",
                    },
                ],
                preferences: {
                    show_default_blocks: true,
                },
            },
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}
