import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useDashboardTitle = () => {
  const { pathname } = useLocation();
  const title = useMemo(() => {
    if (pathname?.startsWith("/dashboard")) {
      return "Dashboard";
    } else if (pathname?.startsWith("/charger")) {
      return "Charge Points";
    }else if(pathname?.startsWith("/chargingprofile")){
      return "Charging Profile Overview"
    } else if (pathname?.startsWith("/team")) {
      return "Teams";
    } else if (pathname?.startsWith("/tag")) {
      return "RFID Tags";
    } else if (pathname?.startsWith("/pricegroup")) {
      return "Price Group";
    } else if (pathname?.startsWith("/profile")) {
      return "Profile";
    } else if (pathname?.startsWith("/users")) {
      return "CMS System Users";
    } else if (pathname?.startsWith("/transactions")) {
      return "Transactions";
    } else if (pathname?.startsWith("/settings")) {
      return "Settings";
    } else if (pathname?.startsWith("/changepassword")) {
      return "Password";
    } else if (pathname?.startsWith("/roles")) {
      return "Roles";
    }else if (pathname?.startsWith("/customer")) {
      return "EV Drivers";
    }else if(pathname?.startsWith("/servicerequest")){
      return "Service Request";
    }else if(pathname?.startsWith("/refund")) {
      return "Refund";
    }else if(pathname?.startsWith("/cm-customer")) {
      return "Customer";
    }else if(pathname?.startsWith("/report")) {
      return "Report";
    }else if(pathname?.startsWith("/charge-zone")) {
      return "Zone & Load Balancing";
    }
  }, [pathname]);

  return title;
};
