import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";

export const reportListColumn = (isReadOnly) => ([
    {
        field: "transactionId",
        headerName: "Transaction ID",
        flex: 0.5,
        renderCell: ({ value, id }) => (
            <>
            {isReadOnly?value:<Link underline="none" component={RouterLink} to={`/transactions/${id}`}>
              {value}
            </Link>}
            </>
          ),
    },
    {
        field: "chargeboxId",
        headerName: "Charger Box",
        flex: 0.5,
        renderCell: ({ value }) => (
            <Tooltip title={value} placement="top">
              <span>{value}</span>
            </Tooltip>
          ),
    },
    {
        field: "connectorId",
        headerName: "Connector ID",
        flex: 0.5,
    },
    {
        field: "startValue",
        headerName: "Start Value",
        flex: 0.5,
    },
    {
        field: "stopValue",
        headerName: "Stop Value",
        flex: 0.5,
    },
    {
        field: "totalEnergy",
        headerName: "Total Energy Consumed",
        flex: 0.7,
    },
    {
        field: "paymentType",
        headerName: "Transaction Method",
        flex: 0.5,
    },
    {
        field: "amountPaid",
        headerName: "Amount Paid",
        flex: 0.5,
    },
    {
        field: "chargingCost",
        headerName: "Transaction Cost",
        flex: 0.5,
    },
    {
        field: "cmCommission",
        headerName: "CM Commission",
        flex: 0.5,
    },
    {
        field: "payoutWithTax",
        headerName: "Payout With GST",
        flex: 0.5,
    },
    {
        field: "payoutWithoutTax",
        headerName: "Payout Without GST",
        flex: 0.5,
    },
    {
        field: "status",
        headerName: "Payout Status",
        flex: 0.5,
        renderCell: ({ value }) => (
            <Tooltip title={value} placement="top">
              <span>{value}</span>
            </Tooltip>
          ),
    }
]);

export const reportTabListToShow = () => ([
    "transactionId",
    "chargeboxId",
    "connectorId",
    "startValue",
    "stopValue",
    "totalEnergy",
    "paymentType",
    "amountPaid",
    "chargingCost",
    "cmCommission",
    "payoutWithTax",
    "payoutWithoutTax",
    "status",
])

export const reportStatusList = [
    'AUTHORIZED',
    'PAYOUTPENDING',
    'PAYOUTCALCULATED',
    'PAYOUTCOMPLETED',
    'CAPTURED'
];
