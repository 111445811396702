import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { Tooltip } from "@mui/material";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export const roleColumns = [
  {
    field: "teamId",
    headerName: "ID",
    width: 100,
    renderCell: ({ value, id }) => (
      <Link underline="none" component={RouterLink} to={`/team/${id}`}>
        {value}
      </Link>
    ),
  },
  {
    field: "teamName",
    headerName: "Team Name",
    flex: 1,
  },
  {
    field: "accesstype",
    headerName: "Access Type",
    flex: 1,
  },
];

export const getUserProfileDefaultValues = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  street: "",
  houseNumber: "",
  zipCode: "",
  city: "",
  country: ""
}

export const profileHeaderBar = [
  { value: "general", label: "General" },
  { value: "license", label: "License" },
]

export const getLicenseSettingDefaultValues = {
  existingAcCharger: "",
  existingAcChargerValidDate: "",
  licenseCost: "",
  existingDcCharger: "",
  existingDcChargerValidDate: "",
  plusGst: "",
  numAcChargerReq: "",
  numAcChargerReqValidDate: "",
  paymentGatewayCharges: "",
  numDcChargerReq: "",
  numDcChargerReqValidDate: "",
  totalCost: ""
};

export const paymentHistoryColumn = (isReadOnly) => ([
  {
    field: "num_ac_charger_req",
    headerName: "AC License",
    flex: 0.5,
  },
  {
    field: "num_ac_charger_req_valid_date",
    headerName: "AC Valid Date",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "num_dc_charger_req",
    headerName: "DC License",
    flex: 0.5,
  },
  {
    field: "num_dc_charger_req_valid_date",
    headerName: "DC Valid Date",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "total_cost",
    headerName: "Total Cost",
    flex: 0.5,
  }
]);