import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMemo, useState } from "react";
import { useUsers } from "src/hooks/useUsers.hook";
import { useGetDetail, useMutate } from "src/hooks/useFetch.hook";
import { teamMembersColumns } from "./teams.constants";
import { BaseTable } from "../BaseTable/BaseTable";
import { getTeamUrl, teamUsersUrl } from "src/react-query/endPoints";
import { useRoles } from "src/hooks/useRoles.hook";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "500px",
};

export const TeamUserModal = ({ open, onClose, teamId,enableRedirection  }) => {
  const navigate = useNavigate()
  const { data } = useUsers();
  const { data: roles } = useRoles();
  const { data: teamUsers, isFetching: isUserFetching } = useGetDetail(
    "usersByTeam",
    { id: teamId }
  );

  const { mutate } = useMutate("usersByTeam");

  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");

  const handleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = () => {
    mutate(
      {
        url: getTeamUrl(teamId, teamUsersUrl),
        method: "POST",
        body: { users, roleId: role },
      },
      {
        onSuccess: () => onClose(),
      }
    );
  };

  const filteredUsers = useMemo(
    () =>
      data?.filter(
        (user) =>
          !teamUsers?.some((teamUser) => teamUser.userId === user.userId)
      ),
    [data, teamUsers]
  );
  const rowOrCellClickRedirect = ({ id }) => {
    navigate(`/team/${id}`);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h4" gutterBottom>
          New Member
        </Typography>
        <FormControl sx={{ m: 1, width: 400 }}>
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={filteredUsers ?? []}
            onChange={(event, newValue) => {
              setUsers(newValue?.map(({ userId }) => userId) ?? []);
            }}
            getOptionLabel={(option) => option.username}
            renderInput={(params) => (
              <TextField {...params} label="Users" placeholder="Select Users" />
            )}
            sx={{ width: "500px" }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 500 }}>
          <InputLabel id="demo-simple-select-label">User Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="User Role"
            onChange={handleChange}
          >
            {roles?.map((role) => (
              <MenuItem value={role?.roleId}>{role?.roleName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ margin: "5px" }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            size="large"
            sx={{ margin: 1 }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            size="large"
            sx={{ margin: 1 }}
          >
            Cancel
          </Button>
        </div>
        <BaseTable
          rows={teamUsers ?? []}
          columns={teamMembersColumns(true,enableRedirection?enableRedirection:false)}
          getRowId={(row) => row.userId}
          loading={isUserFetching}
          // rowOrCellClickRedirect={rowOrCellClickRedirect}
        />
      </Box>
    </Modal>
  );
};
