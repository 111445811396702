import React from 'react'
import { useMatch } from 'react-router-dom'
import { BackButton } from 'src/common/components/BackButton'
import Page from 'src/components/Page'
import ServiceRequestForm from './ServiceRequestForm'
// import ServiceRequestComments from './ServiceRequestComments'

const ServiceRequestFormView = () => {

    const match = useMatch('/servicerequest/new')

    return (
        <Page title="Service Request | Smart-CMS">
            <BackButton
                path="/servicerequest"
                text={
                    match ? "Add New Request" : "Edit Request"
                }
            />
            <ServiceRequestForm />
            {/* <ServiceRequestComments/> */}
        </Page>
    )
}

export default ServiceRequestFormView