import axios from "axios";
import Token from "./Token";

const instance = axios.create({
  baseURL: process.env.REACT_APP_CSMS_API_ENDPOINT,
  timeout: 30000,
});

instance.interceptors.request.use(async function (axiosConfig) {
  // Do something before request is sent
  const token = Token.get("_flst");
  if (!token) {
    window.location.href = "/login";
    throw new Error("User is not authorized", { cause: 401 });
  }
  const newConfig = {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      Authorization: `Bearer ${token}`,
    },
  };
  return newConfig;
});

//  Add a response interceptor
instance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response && error.response.status === 401) {
      Token.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default instance;
