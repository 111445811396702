// react
import { memo } from "react";
//formik
import { useFormik, Form, FormikProvider } from "formik";
// mui components
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
// user defined components, services, constants
import { authService } from "src/services";
import enums from "src/util/enums";
import { verficationValidationSchema } from "src/util/validationSchemas";
// hooks
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

//actions
import { alertSlice } from "src/store/alert/alert.slice";

function VerificationAndReset(props) {
    const { user, ...rest } = props;
    const dispatch = useDispatch();
    const { mutateAsync, isLoading, reset } = useMutation(authService.verificationAndReset);

    const onSubmit = async (values, { setSubmitting }) => {
        const { otp, new_password } = values;
        const { actions: { setAlert } } = alertSlice
        await mutateAsync({ 
            username: user,
            otp,
            new_password: window.btoa(new_password)
         }, {
            onSuccess: () => {
                dispatch(setAlert({
                    open: true,
                    message: "Password reset successfully",
                    severity: enums.SEVERITY.SUCCESS,
                }));
                setTimeout(() => {
                    window.location.href = enums.path.LOGIN;
                }, 2000);
            },
            onError: (error) => {
                dispatch(setAlert({
                    open: true,
                    message: error?.response?.data?.message ?? error?.message,
                    severity: enums.SEVERITY.ERROR,
                }));
            },
            onSettled: () => reset()
        });
        setSubmitting(false);
    }

    const formik = useFormik({
        initialValues: {
          otp: "",
          old_password: "abc",
          new_password: "",
          confirm_password: ""
        },
        validationSchema: verficationValidationSchema,
        onSubmit,
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (
        <Slide {...rest}>
            <CardContent>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Typography variant="h4">Verification Required</Typography>
                        <Typography variant="body2">
                            To continue, complete this verification step. 
                            We've sent an OTP to the email associated with the username
                            <Typography className="username" variant="caption" fontSize="0.875rem" fontWeight={600}>{' '+ user}.</Typography>
                        </Typography>
                        <FormControl fullWidth variant="standard">
                            <Typography variant="caption">Enter OTP</Typography>
                            <TextField 
                                size="small"
                                disabled={isLoading}
                                error={Boolean(touched.otp && errors.otp)}
                                helperText={touched.otp && errors.otp}
                                {...getFieldProps("otp")}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="standard">
                            <Typography variant="caption">Enter New Password</Typography>
                            <TextField 
                                size="small"
                                type="password"
                                disabled={isLoading}
                                error={Boolean(touched.new_password && errors.new_password)}
                                helperText={touched.new_password && errors.new_password}
                                {...getFieldProps("new_password")}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="standard">
                            <Typography variant="caption">Confirm Password</Typography>
                            <TextField 
                                size="small"
                                type="password"
                                disabled={isLoading}
                                error={Boolean(touched.confirm_password && errors.confirm_password)}
                                helperText={touched.confirm_password && errors.confirm_password}
                                {...getFieldProps("confirm_password")}
                            />
                        </FormControl>
                        <LoadingButton type="submit" fullWidth variant="contained" loading={isLoading}>Continue</LoadingButton>
                    </Form>
                </FormikProvider>
            </CardContent>
        </Slide>
    )
}

VerificationAndReset.defaultProps = {
    container: null,
    in: false,
    direction: "left",
    timeout: 500,
    mountOnEnter: !0,
    unmountOnExit: !0
}

export default memo(VerificationAndReset);