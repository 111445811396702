import { createSlice } from "@reduxjs/toolkit";
import {remoteOpsStatusInitialState,remoteOpsStatusReducerKey} from "./remoteOpsStatus.constants";

export const remoteOpsStatusSlice = createSlice({
    name:remoteOpsStatusReducerKey,
    initialState:remoteOpsStatusInitialState,
    reducers:{
        setRemoteOps(state,{payload}){
            state.modalOpen = payload.open;
            state.remoteOperationPerformed = payload.operation;
            state.operationDetails = payload.data
        }
    }
})