import { useAccess } from "src/hooks/useAccess.hook";
import editFill from "@iconify/icons-eva/edit-fill";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import viewFill from "@iconify/icons-eva/eye-fill";
import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { getRoleUrlId } from "src/react-query/endPoints";
import { useCallback, useMemo } from "react";
import { FEATURE } from "src/util/enums";
export const RoleActionMenu = ({ id }) => {
  const isReadOnly = useAccess(FEATURE.ROLES, "R");
  const hasEditAccess = useAccess(FEATURE.ROLES, "U");
  const hasDeleteAccess = useAccess(FEATURE.ROLES, "D");
  const { mutate } = useMutate("roles");
  const onDelete = useCallback(() => {
    mutate({ url: getRoleUrlId(id), method: "DELETE" });
  }, [id, mutate]);
  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        routerLink: `${id}`,
        hidden: !isReadOnly,
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        routerLink: `${id}`,
        hidden: !hasEditAccess,
      },
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onDelete,
        hidden: !hasDeleteAccess,
      },
    ],
    [id, isReadOnly, hasEditAccess, hasDeleteAccess, onDelete]
  );

  return <ActionsMenu menuList={actionList} />;
};
