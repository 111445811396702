import {
  addTimeToDateString,
  calculateBaseDateRange,
} from "src/utils/formatTime";
import axios from "../util/axios";
import Token from "../util/Token";

//DASHBOARD URL
const URL = "/cms/v1/team";

async function getStatistics(teamId, timeInterval) {
  Token.setAuthToken();
  return await axios.get(`${URL}/${teamId}/dashboard_stats`, {
    params: { ...timeInterval },
  });
}
async function getIndividualStatistics(teamId, statsUrl, timeInterval, field) {
  try {
    Token.setAuthToken();
    const updatedTimeInterval =
      field !== "faultAndConnectivityLoss"
        ? calculateBaseDateRange(timeInterval)
        : {
            start: addTimeToDateString(timeInterval.start),
            end: addTimeToDateString(timeInterval.end),
          };
    const { status, data } = await axios.get(`${URL}/${teamId}/${statsUrl}`, {
      params: { ...updatedTimeInterval },
    });
    if (status === 200 && data.success) {
      return { ...data.data, field };
    }
  } catch (error) {
    console.log(error);
  }
}
const dashboardService = {
  getStatistics,
  getIndividualStatistics,
};
export default dashboardService;
