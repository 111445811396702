import { useCallback } from 'react';
import Button from "@mui/material/Button";
import { differenceInHours } from "date-fns";
import enums from "src/util/enums";
import { refundUrl } from "src/react-query/endPoints";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";

// hooks
import { useMutate } from "src/hooks/useFetch.hook";

const { REFUND_STATUS: { PROCESSED } } = enums;

export const Refund = ({ row, refetch }) => {
  const dispatch = useDispatch();  
  const { mutateAsync, isLoading } = useMutate('refund');
  

    const onRefundClick = useCallback(async () => {
      const { actions: { setAlert } } = alertSlice;
        await mutateAsync({
          url: refundUrl,
          method: 'POST',
          body: { 
            refundBody: [{ 
              transactionId: row.transactionNumber, ocppIdTag: row.tag
           }]
          }
        }, { 
          onSuccess: () => {
            dispatch(setAlert({
              open: true,
              message: "Refund has been successfully",
              severity: enums.SEVERITY.SUCCESS,
            }));
            refetch();
        }});
    }, [row]);
    return (<>
      {(PROCESSED === row.refundStatus || differenceInHours(new Date(), new Date(row.startDate)) <= 24) &&
      <Button variant="contained" disabled={PROCESSED === row.refundStatus || isLoading} onClick={onRefundClick}>
        {row.refundStatus ? row.refundStatus.toUpperCase() : isLoading ? 'Refunding...' : 'Refund'}
      </Button>}
    </>)
  }