import * as Yup from "yup";
import { Box, FormControl, Grid, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMemo, useState } from "react";
import { RemoteOperationForm } from "./RemoteOperationForm";
import { remoteOperations } from "./remote.constants";
import { remoteOperationConfig } from "./remoteOperationConfig";
import { createYupSchema } from "src/utils/yupCreatorSchema";
import { is } from "src/util/is";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "500px",
};

export const RemoteOperationModal = ({
  open,
  onClose,
  chargerId,
  chargerPk,
}) => {
  const [operation, setOperation] = useState("");

  const validationSchema = useMemo(() => {
    const schema =
      remoteOperationConfig[operation]?.reduce(createYupSchema, {}) ?? {};
    return operation && Yup.object().shape(schema);
  }, [operation]);

  const initialValues = useMemo(() => {
    const values =
      remoteOperationConfig[operation]?.reduce(
        (acc, { fieldName, value }) => ({ ...acc, [fieldName]: value ?? "" }),
        {}
      ) ?? {};
    return values;
  }, [operation]);

  const queryKeysDropdown = useMemo(() => {
    const values =
      remoteOperationConfig[operation]?.reduce((acc, { queryKey, usePk }) => {
        return queryKey ? [...acc, { queryKey, usePk }] : acc;
      }, []) ?? [];
    return values;
  }, [operation]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h4" gutterBottom mb={2}>
          Remote Operation ({chargerId})
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl>
              <Autocomplete
                options={remoteOperations}
                onChange={(event, newValue) => {
                  setOperation(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Remote Operation"
                    placeholder="Select Operation"
                  />
                )}
                sx={{ width: "500px" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} spacing={2}>
            {operation && validationSchema && !is.empty(initialValues) && (
              <RemoteOperationForm
                operation={operation}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onClose={onClose}
                chargerId={chargerId}
                chargerPk={chargerPk}
                key={operation}
                queryKeysDropdown={queryKeysDropdown}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
