import { useAccess } from "src/hooks/useAccess.hook";
import editFill from "@iconify/icons-eva/edit-fill";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import PersonOutline from "@iconify/icons-eva/person-outline";
import viewFill from "@iconify/icons-eva/eye-fill";
import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { teamUrlId } from "src/react-query/endPoints";
import { useCallback, useMemo } from "react";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import { useDispatch, useSelector } from "react-redux";
import { FEATURE } from "src/util/enums";
import { authSlice } from "src/store/auth/auth.slice";
export const TeamActionMenu = ({ id }) => {
  const dispatch = useDispatch();
  const isReadOnly = useAccess(FEATURE.TEAM, "R");
  const hasDeleteAccess = useAccess(FEATURE.TEAM, "D");
  const hasUpdateAccess = useAccess(FEATURE.TEAM, "U");
  const { mutate } = useMutate("teams");

  const { user } = useSelector(({ auth }) => auth);
  const updateProfileRoles = useCallback(() => {
    const updatedRoles = user?.role.filter(
      (team) => Number(team.teamId) !== Number(id)
    );
    const updatedUser = { ...user, role: updatedRoles };
    dispatch(authSlice.actions.setUser(updatedUser));
  }, [id,dispatch,user]);

  const onDelete = useCallback(() => {
    mutate(
      { url: teamUrlId(id), method: "DELETE" },
      { onSuccess: updateProfileRoles }
    );
  }, [id, mutate, updateProfileRoles]);

  const manageUser = useCallback(() => {
    dispatch(dialogSlice.actions.setDialog({ open: true, id }));
  }, [dispatch, id]);

  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        routerLink: `/team/${id}`,
        hidden: !isReadOnly,
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        routerLink: `/team/${id}`,
        hidden: !hasUpdateAccess,
      },
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onDelete,
        hidden: !hasDeleteAccess,
      },
      {
        id: "manageUser",
        title: "Manage Users",
        icon: PersonOutline,
        action: manageUser,
      },
    ],
    [id, isReadOnly, hasDeleteAccess, hasUpdateAccess, onDelete, manageUser]
  );

  return <ActionsMenu menuList={actionList} />;
};
