import { Icon } from "@iconify/react";
import { is } from "src/util/is";

export const getIcon = (name,width,height) => <Icon icon={name} width={width??22} height={height??22} />;

export const getImageIcon = (name) => (
  <img src={name} width={22} height={22} alt="Icon" />
);

export const timeDiffInHours = (timeA, timeB) => {
  if (timeA && timeB) {
    const timeAArr = timeA.split(":");
    const timeAInMinutes = parseInt(timeAArr[0])*60+parseInt(timeAArr[1]);
    const timeBArr = timeB.split(":");
    const timeBInMinutes = parseInt(timeBArr[0])*60+parseInt(timeBArr[1]);
    const diff = timeBInMinutes - timeAInMinutes;
    return Math.floor(100 * diff / 60) / 100;
  }
}

export const calculateTotalHours = (prices, day) => {
  return prices.reduce((prev, curr) => {
    const hours = timeDiffInHours(curr.startTime, curr.endTime);
    const totalHours = prev + (day === -1 ? hours : (day === curr.day ? Math.ceil(hours) : 0));
    return totalHours
  }, 0);
}

export const isObjectWithNonNullValues=(obj,keysToignore)=>{
  
    for(let [key,value] of Object.entries(obj)){
      if(!keysToignore.includes(key) && is.empty(value)) return false;
    }

  return true
}