import { memo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from "@mui/lab";

function MuiConfirmationDialog(props) {
    const { 
        title,
        contentText,
        closeText,
        okText,
        onOk,
        isLoading,
        ...rest
     } = props;

    return (
        <Dialog
          {...rest}
        >
            <DialogTitle id="alert-dialog-title"> {title} </DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" onClick={rest.onClose}>{closeText}</Button>
                <LoadingButton type="button" variant="contained" size="large" onClick={onOk} loading={isLoading}>{okText}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

MuiConfirmationDialog.defaultProps = {
    open: false,
    maxWidth: 'sm',
    title: "Mui Dialog",
    closeText: 'Cancel',
    okText: 'Ok',
    isLoading: false,
    contentText: "Mui Content text dialog", 
    'aria-labelledby': 'alert-dialog-title',
    'aria-describedby': 'alert-dialog-description',
    onClose: () => {},
    onOk: () => {}
}

export default memo(MuiConfirmationDialog);