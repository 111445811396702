import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import { is } from "src/util/is";
import { infoEmail, transactionsPayoutColumns } from "./TransactionsList.constants";

const calculateTotalPayoutDetails = (
  initialPayoutData,
  selectedTeam,
  selectedBulkId
) => {
  const selectedTeamPayoutsDetails = initialPayoutData[selectedTeam].reduce(
    (finalData, curr) => {
      const { payoutId, payoutAmount } = curr;
      if (payoutId === selectedBulkId) {
        finalData = {
          total: finalData.total + Number(payoutAmount),
          transactions: [...finalData.transactions, curr],
        };
      }

      return finalData;
    },
    { total: 0, transactions: [] }
  );

  return selectedTeamPayoutsDetails;
};

/**
 * THE COMPONENT
 **/

const TransactionsPayoutForm = ({
  data,
  activeStep,
  teamOptions,
  allSteps,
  fillFinalData,
  fillEmailIds
}) => {
  const [selectedTeam, setSelectedTeam] = useState(() => teamOptions[0]);
  const [bulkIdsOptions, setBulkIdsOptions] = useState();
  const [selectedBulkId, setSelectedBulkId] = useState();

  const [
    selectedTeamsTotalPayoutsDetails,
    setSelectedTeamsTotalPayoutsDetails,
  ] = useState();
  const [emailRecepients,setEmailRecepients] = useState(infoEmail)

  const onTeamsRadiosChange = (value) => {
    setSelectedTeam(value);
  };

  const onPayoutBulkIdRadioChange = (value) => {
    setSelectedBulkId(value);
  };

  const handleEmailRecepientsChange =(event) => {
    const {
      target: { value },
    } = event;
    fillEmailIds(value)
   setEmailRecepients(value)
  }

  useEffect(() => {
     if(activeStep===1){

      const allBukIdsOptions = [
        ...new Set(data[selectedTeam].map(({ payoutId }) => payoutId)),
      ];
      setBulkIdsOptions(allBukIdsOptions);

     }else if(activeStep===2){
      const selectedTeamPayoutsDetails = calculateTotalPayoutDetails(
        data,
        selectedTeam,
        selectedBulkId
      );
      fillFinalData({ selectedTeam, selectedBulkId });
      setSelectedTeamsTotalPayoutsDetails(selectedTeamPayoutsDetails);
     }else if(activeStep===3){
       const selectedTeamEmailArr = data[selectedTeam][0].payoutBanks.map(({email})=>email)
       const emails= `${emailRecepients},${selectedTeamEmailArr.join(",")}`;
       fillEmailIds(emails)
      setEmailRecepients(emails);
     }
  }, [activeStep])
  



  const formBuilder = (active) => {
    const formArr = allSteps.map((step, index) => {
      let form = <></>;
      switch (index) {
        case 0:
        case 1:
          const label = index === 0 ? "CPOs To Be Paid Out" : "BULK IDs";
          const ariaLabel =
            index === 0
              ? "Transactions-Teams-Radio-Btns-Group-Label"
              : "Transactions-Payouts-Radio-Btns-Group-Label";
          const name =
            index === 0
              ? "transactions-teams-radio-buttons-group"
              : "transactions-payouts-radio-buttons-group";
          const onChange =
            index === 0 ? onTeamsRadiosChange : onPayoutBulkIdRadioChange;
          const dataToShow = index === 0 ? teamOptions : bulkIdsOptions;
          const currVal = index === 0 ? selectedTeam : selectedBulkId;
          form = (
            <Paper elevation={5} variant="elevation" sx={{ mx: 5 }}>
              <FormControl
                sx={{ m: 3, width: "100%" }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend" sx={{ my: 2 }}>
                  {label}
                </FormLabel>
                <RadioGroup
                  aria-labelledby={ariaLabel}
                  onChange={(event, newValue) => onChange(newValue)}
                  value={currVal}
                  name={name}
                >
                  <Grid container>
                    {!is.empty(dataToShow) && dataToShow.map((val) => (
                      <Grid itemxs={12} sm={6} md={4}>
                        <FormControlLabel
                          value={val}
                          control={<Radio />}
                          label={val}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "& .MuiFormControlLabel-label": {
                              whiteSpace: "nowrap",
                            },
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Paper>
          );
          break;
        case 2:
          form = (
            <Paper elevation={5} variant="elevation" sx={{ mx: 5, p: 1 }}>
              {!is.empty(selectedTeamsTotalPayoutsDetails) && (
                <BaseTable
                  rows={selectedTeamsTotalPayoutsDetails.transactions ?? []}
                  columns={transactionsPayoutColumns}
                  loading={false}
                  getRowId={(row) => row.transactionId}
                  pageSize={5}
                  customFooterData={{
                    title: "Total Payout Amount",
                    data: selectedTeamsTotalPayoutsDetails?.total,
                  }}
                />
              )}
            </Paper>
          );

          break;

         case 3:
          form=(
            <Paper elevation={5} variant="elevation" sx={{ mx: 5, p: 1 }}>
              {!is.empty(selectedTeamsTotalPayoutsDetails) && (
                <Stack direction="column" spacing={1} my={3} alignItems={"center"}>
                <InputLabel
                  id="payouts-email-recipients-label"
                  sx={{ color: "inherit" }}
                >
                  Recepients Of Payouts Notifications
                </InputLabel>
                <FormControl>
                  <TextField
                    required
                    multiline
                    sx={{ width: "400px" }}
                    rows={6}
                    value={emailRecepients}
                    id="payouts-email-recipients"
                    // label="Recipients"
                    onChange={handleEmailRecepientsChange}
                  />
                </FormControl>
              </Stack>
              )}
            </Paper>
          )
          break; 

        default:
          form = <></>;
          break;
      }

      return form;
    });

    return formArr[active];
  };

  return <Box>{formBuilder(activeStep)}</Box>;
};

export default TransactionsPayoutForm;
