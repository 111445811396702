import { useEffect, useMemo, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Stack, TextField, FormControl, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { customerValidationSchema } from "../../util/validationSchemas";
import "../../styles/charger.css";
import { useAccess } from "src/hooks/useAccess.hook";
import { useSelectedCompany } from "src/hooks/useSelectedTeam";
import { customerFormTabs, hideForTabs, getCustomerDefaultValues } from "./customer.constant";
import { useMutate, useGetDetail } from "src/hooks/useFetch.hook";
import { customerTagsById, customerUrl, getCustomerById } from "src/react-query/endPoints";
import { Loader } from "../Loader";
import { is } from "src/util/is";
import Address from "src/common/components/Address";
import { FEATURE } from "src/util/enums";
import { alertSlice } from "src/store/alert/alert.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootTabItemStyles, RootTabStyle } from "src/theme/overrides/Tabs";
import CustomerTagsForms from "./CustomerTagsForms";
import { formDataSlice } from "src/store/formData/formatData.slice";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";

export default function UserForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: customerTags, isFetching:isFetchingCustomerTags,refetch:refetchCustomerTags } = useGetDetail("customerTags",{id});
  const { data: availableTagsList, isFetching:isFetchingAvailableTagsList,refetch:refetchAvailableTagsList } = useGetDetail("availableTags",{id});
  const newCustomerMatch = useMatch("/customer/new");
  const hasReadOnlyAccess = useAccess(FEATURE.CUSTOMERS, "R");
  const isReadOnly = hasReadOnlyAccess && !newCustomerMatch;
  const company = useSelectedCompany();
  const { mutate, isLoading } = useMutate("customer");
  const { mutate:mutateCustomerTags, isLoading:isMutatingCustomerTags } = useMutate("customerTags");
  const { data: customerData, isFetching } = useGetDetail("customer", { id });
  
  const onSuccess = () =>{
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:"",
      tabOpenedFor:"",
      tabOpened:""
    }));  
    navigate("/customer")};

  const tabsToShow = useMemo(() =>customerFormTabs, []);
  const hideSaveFor = useMemo(()=>{
    let hideSaveFor=[...hideForTabs]; 
    if(newCustomerMatch && !id ){
      hideSaveFor = hideForTabs.filter(tabName=>tabName==="tabs")
     }
     return hideSaveFor
  },[id,newCustomerMatch])

  const { open, data } = useSelector(({ dialog }) => dialog);
  const { tabOpened, tabOpenedFor } = useSelector(
    ({ tabsCurrentlyOpenedFor }) => tabsCurrentlyOpenedFor
  );
  const { formData,formDataOf} = useSelector(
    ({ formData }) => formData
  );
  const [selectedTab, setSelectedTab] = useState( tabOpenedFor === "customer" && tabOpened ? tabOpened : "general");
  const [selectedTagsToAttach,setSelectedTagsToAttach] = useState([])   //USED ONLY DURING CREATING A NEW CUSTOMER
  const [AreTagsGettingAttach,setAreTagsGettingAttached] = useState(false)   //USED ONLY DURING CREATING A NEW CUSTOMER
  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };
  const refetchAllTheLists = ()=>{
    refetchCustomerTags();
    refetchAvailableTagsList();
  }
  const handleTagSubmit = async (selectedTags,clearSelected) => {
    const ids = selectedTags.map((t) =>t.tag );
    if(is.empty(ids)){
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Please select tags to be added",
          severity: "error",
        })
      )
      return
    }
    await mutateCustomerTags(
      {
        url:customerTagsById(id),
        method: "POST",
        body: { tagIds: ids },
      },
      { onSuccess: () => {
         dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Successfully associated user with selected tags",
          severity: "success",
        })
      )
      clearSelected();
      refetchAllTheLists();
    }}
    );
  };
 const handleTagAdding = async (selectedTags,clearSelected) =>{
  setAreTagsGettingAttached(true)
  const allSelectedTags = [...selectedTags]
  setSelectedTagsToAttach([...selectedTagsToAttach,...allSelectedTags]);
  clearSelected()
  setAreTagsGettingAttached(false)
 }

 const handleTagRemove = (tagId) =>{
   const updatedTagsToAttach = selectedTagsToAttach.filter(({id})=>id!==tagId);
   setSelectedTagsToAttach(updatedTagsToAttach) 
 }

  const onSubmit = async (customerData, { setSubmitting }) => {
     if(!customerData.email && !customerData.phone){
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Please specify either email or phone number. Both can't be left blank.",
          severity: "error",
        })
      )
      setSubmitting(false)
      return
     }
     if(!customerData.locationLatitude && !customerData.locationLongitude){
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Please specify address as well",
          severity: "error",
        })
      )
      setSubmitting(false)
      return
     }
    const mutateObject = {
      url: customerUrl,
      method: "POST",
    };

    const { 
      locationLatitude: lat, 
      locationLongitude: lng, 
      city, state, street, 
      zipCode: zip_code,
      country, companyId,
      firstName, lastName, 
      email, phone, 
    } = customerData;

    const customerReqBody = {
      firstName, lastName, email, phone, companyId,
      address : {
        lat, lng, city, state, street, zip_code, country
      },
    }

    if(newCustomerMatch && is.nullOrUndefined(id)) {
      customerReqBody.ocppTagIds= selectedTagsToAttach.map((t) =>t.tag )
    }
    if (!is.nullOrUndefined(id) && !newCustomerMatch) {
      mutateObject.method = "PUT";
      mutateObject.url = getCustomerById(id);
    }

    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: customerReqBody,
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: getCustomerDefaultValues(company.id),
    validationSchema: customerValidationSchema,
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    resetForm,
    values,
    setErrors
  } = formik;
  //  console.log(errors)

  const handleCreatingNewTag = () =>{
    dispatch(formDataSlice.actions.setFormData({
      formData:{
        ...values                                       //TEMPORARILY STORE FORM DATA IN REDUX
      },
      formDataOf:"customer"
    }))
   }

   useEffect(()=>{
    if(!isFetching){
      if(tabOpenedFor==="customer" && !is.empty(formData) && formDataOf==="customer"){
        
        resetForm({values:{...formData}})
        dispatch(formDataSlice.actions.setFormData({
          formData:{},
          formDataOf:""
        }))
      }
    }

   },[formData,resetForm,tabOpenedFor,dispatch,formDataOf,isFetching])
   
  useEffect(() => {
    if (customerData) {
      const { lat, lng, ...c } = customerData;
      resetForm({ values: { ...c, locationLatitude: lat, locationLongitude: lng }});
    }
  }, [customerData]);

  if (isFetching) {
    return <Loader />;
  }

  // if (!newCustomerMatch && is.empty(data)) {
  //   navigate("/dashboard");
  //   return null;
  // }

  return (<>
    <TabContext value={selectedTab}>
    <Stack
    direction="row"
    justifyContent="center"
    alignItem="center"
    sx={{ width: "100%" }}
  >
    <RootTabStyle
      indicatorColor="transparent"
      centered
      length={tabsToShow.length}
      onChange={handleTabChange}
    >
      {tabsToShow.map((tab) => (
        <RootTabItemStyles value={tab.value} label={tab.label} />
      ))}
    </RootTabStyle>
  </Stack>
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          id="customer"
          noValidate
          onSubmit={handleSubmit}
        >
          <TabPanel value="general">
          <Grid container spacing={3} className="mb-5">
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="mb-2 text-uppercase">
                User Info
              </Typography>
              <Divider className="text-muted" />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="First Name"
                  size="small"
                  {...getFieldProps("firstName")}
                  disabled={isReadOnly}
                  required
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Last Name"
                  size="small"
                  {...getFieldProps("lastName")}
                  disabled={isReadOnly}
                  required
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Email"
                  size="small"
                  // required
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={isReadOnly}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Phone"
                  size="small"
                  {...getFieldProps("phone")}
                  disabled={isReadOnly}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={(touched.phone && errors.phone) || "Phone number must start with a country code, e.g., +919876543210"}
                />
              </FormControl>
            </Grid>
          </Grid>
          </TabPanel>
          <TabPanel value="address">
          <Address getFieldProps={getFieldProps} setFieldValue={setFieldValue} formValues={values} displayedFor="customer" />
          </TabPanel>
          <TabPanel value="tags">
          <CustomerTagsForms availableTagsList={availableTagsList} customerTags={id?customerTags:selectedTagsToAttach} isMutatingCustomerTags={isMutatingCustomerTags} refetchAllTheLists={refetchAllTheLists} isFetchingCustomerTags={id?isFetchingCustomerTags:AreTagsGettingAttach} handleTagSubmit={id?handleTagSubmit:handleTagAdding} isNewCustomer={newCustomerMatch}  saveFormData={handleCreatingNewTag} removeFunction={handleTagRemove}/>
          </TabPanel>
             {!hideSaveFor.includes(selectedTab) && (<Stack  direction="row"
                  spacing={2}
                  alignItem="center"
                  justifyContent="center">
                {!isReadOnly && (
                  <>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={isReadOnly}
                      loading={isSubmitting || isLoading}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      component={RouterLink}
                      to="/customer"
                    >
                      Cancel
                    </LoadingButton>
                  </>
                )}
              </Stack>)}
        </Form>
      </FormikProvider>
    </Card>
    </TabContext>
    </>
  );
}
