import { useEffect, useMemo, useState } from "react";
//formik
import { Form, FormikProvider, useFormik } from "formik";
// mui components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { LoadingButton } from "@mui/lab";
import { queryKeys } from "src/react-query/queryKeys";

// custom components, services and constants
import { BaseTable } from "src/components/BaseTable/BaseTable";
import { licensePaymentFormValidationSchema, userFormValidationSchema } from "src/util/validationSchemas";
import { Loader } from "src/components/Loader";
import Countries from "src/data/countries.json";
import { getchargerPricing, getLicensePaymentHistory, getUserProfile, updateLicensePayment } from "src/react-query/endPoints";
import enums, { FEATURE } from "src/util/enums";
import {
  getLicenseSettingDefaultValues,
  getUserProfileDefaultValues,
  paymentHistoryColumn,
  profileHeaderBar,
  roleColumns,
} from "./UserProfile.constant";
//actions
import { alertSlice } from "src/store/alert/alert.slice";

// hooks
import { useGetDetail, useMutate } from "src/hooks/useFetch.hook";
import { useAccess } from "src/hooks/useAccess.hook";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Card, Box, Button, Stack, Typography, FormControl, InputLabel, Select, MenuItem, Link, OutlinedInput } from "@mui/material";
import { RootTabItemStyles } from 'src/theme/overrides/Tabs';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';
import { DisplayRazorpay } from "./razorpay";
import axios from "../../util/axios";
import { PAYMENT_ENDPOINTS } from "./payment.constant";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { format } from "date-fns";

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
  width: '98%',
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  '& .MuiTabs-flexContainer': {
    justifyContent: 'left',
  },
  '& .MuiTab-root': {
    width: `${100 / length}%`
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UserProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const team = useSelectedTeam();
  const queryClient = useQueryClient();
  const { isReadOnly } = useAccess("RXX");
  const { mutateAsync, isLoading } = useMutate("user");
  const { data, isFetching } = useGetDetail("profile", { id: 0 });
  const tabsToShow = profileHeaderBar;
  const [selectedTab, setSelectedTab] = useState(tabsToShow[0].value);
  const [tableData, setTableData] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState({
    isDone: undefined,
    status: undefined,
    message: "",
  });
  const { teamId } = useSelectedTeam();
  const finalTableColumns = paymentHistoryColumn();
  const [licensePricing, setLicensePricing] = useState();
  const [licenseFormValue, setLicenseFormValue] = useState({
    "acLicenseCost": 0,
    "dcLicenseCost": 0,
    "gst": 0,
    "totalCost": 0
  });
  const [monthsDiff, setMonthsDiff] = useState();


  const hasAccess = useMemo(() => {
    const hasReadOnlyAccess = team?.access?.filter(
      (item) => item.featureName === "License Payment"
    )[0];
    return hasReadOnlyAccess ? true : false;
  }, [team]);

  const updatedTeamsFormTab = hasAccess && team?.teamType === "T" ? [...tabsToShow] : tabsToShow.filter((tab) => tab.value !== "license");

  const {
    actions: { setAlert },
  } = alertSlice;

  const errorNotification = () => {
    return dispatch(
      setAlert({
        open: true,
        message: "Please configure Finanace Configuration",
        severity: enums.SEVERITY.ERROR,
      })
    );
  };

  const getPaymentHistory = async () => {
    const response = await axios.get(getLicensePaymentHistory(teamId))
    if (response.data.data) {
      setTableData(response.data.data);
    }
  }

  useEffect(() => {
    if (teamId && hasAccess && team?.teamType === "T") {
      getPaymentHistory();
    }
  }, [teamId])

  const onSubmit = async (values) => {
    const {
      actions: { setAlert },
    } = alertSlice;
    const {
      id,
      zipCode,
      street,
      country,
      houseNumber,
      username,
      email,
      lastName,
      firstName,
      city,
      language,
    } = values;
    const profileReqBody = {
      username,
      email,
      firstName,
      lastName,
      language,
      id,
      address: {
        zipCode,
        street,
        country,
        houseNumber,
        city,
        addressId: null,
      },
    };
    await mutateAsync(
      {
        url: getUserProfile(),
        method: "PUT",
        body: profileReqBody,
      },
      {
        onSuccess: () => {
          dispatch(
            setAlert({
              open: true,
              message: "Profile Updated Successfully",
              severity: enums.SEVERITY.SUCCESS,
            })
          );
          queryClient.invalidateQueries(queryKeys.user());
        },
        onError: (error) => {
          dispatch(
            setAlert({
              open: true,
              message: error?.response?.data?.message ?? error?.message,
              severity: enums.SEVERITY.ERROR,
            })
          );
        },
      }
    );
  };

  useEffect(() => {
    const chargerPricing = async () => {
      const response = await axios.get(getchargerPricing(teamId));
      setLicensePricing(response.data.data[0]);
    }
    if (teamId && hasAccess && team?.teamType === "T") {
      chargerPricing();
    }
  }, [teamId])

  useEffect(() => {
    if (paymentStatus?.status) {
      formikLicense.resetForm();
      getPaymentHistory();
      return dispatch(
        setAlert({
          open: true,
          message: "Payment is successfull",
          severity: enums.SEVERITY.SUCCESS,
        })
      );
    } else if (paymentStatus?.status === false) {
      return dispatch(
        setAlert({
          open: true,
          message: "Payment is failed. Please try again!",
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  }, [paymentStatus])

  const onSubmitLicense = async (values, { setErrors, setSubmitting }) => {
    const {
      actions: { setAlert },
    } = alertSlice;

    if (!values.numAcChargerReq && !values.numDcChargerReq) {
      setErrors({
        numAcChargerReq: 'Both fields cannot be empty',
        numDcChargerReq: 'Both fields cannot be empty',
      });
      setSubmitting(false);
      return;
    }

    const {
      licenseCost,
      numAcChargerReq,
      numAcChargerReqValidDate,
      numDcChargerReq,
      numDcChargerReqValidDate,
      totalCost
    } = values;

    const licenseReqBody = {
      userId: data.company.id,
      teamId,
      licenseCost,
      plusGst: licensePricing?.tax,
      numAcChargerReq,
      numAcChargerReqValidDate,
      paymentGatewayCharges: licensePricing?.payment_gateway_percent,
      numDcChargerReq,
      numDcChargerReqValidDate,
      totalCost
    };

    const isStripeGateway =
      process.env.REACT_APP_CHARGEMILES_PAYMENT_GATEWAY === "STRIPE";

    try {
      const url = isStripeGateway
        ? PAYMENT_ENDPOINTS.getClientIdUrl
        : PAYMENT_ENDPOINTS.getPaymentOrderUrl;

      const response = await axios(url, {
        method: "post",
        data: {
          ...licenseReqBody,
          "licensePayment": true
        },
      });

      if (response?.data) {
        const licenseUrl = updateLicensePayment(teamId);
        await axios(licenseUrl, {
          method: "post",
          data: {
            ...licenseReqBody,
            status: 0,
            orderId: response?.data?.id
          },
        });
        await DisplayRazorpay(
          {
            amount: totalCost,
            orderId: response?.data?.id,
            currency: "INR",
            setPaymentStatus: setPaymentStatus,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: getUserProfileDefaultValues,
    validationSchema: userFormValidationSchema,
    onSubmit,
  });

  const monthsDifference = (date) => {
    const currentDate = new Date();
    const futureDate = new Date(date);
    return (futureDate.getFullYear() - currentDate.getFullYear()) * 12 +
      (futureDate.getMonth() - currentDate.getMonth());
  }

  useEffect(() => {

    const formatDate = (getAnnualYear) => {
      const date = format(new Date(getAnnualYear), "yyyy-MM-dd");
      return date;
    }
    if (tableData[0]?.num_ac_charger_req_valid_date && tableData[0]?.num_dc_charger_req_valid_date) {
      setLicenseFieldValue("numAcChargerReqValidDate", formatDate(tableData[0]?.num_ac_charger_req_valid_date));
      setLicenseFieldValue("numDcChargerReqValidDate", formatDate(tableData[0]?.num_dc_charger_req_valid_date));
      setMonthsDiff(monthsDifference(tableData[0]?.num_ac_charger_req_valid_date));
    } else {
      const today = new Date();
      const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const getAnnualYear = new Date(monthEnd.getFullYear(), monthEnd.getMonth() + 12, 0);
      let date = formatDate(getAnnualYear);
      setLicenseFieldValue("numAcChargerReqValidDate", date);
      setLicenseFieldValue("numDcChargerReqValidDate", date);
      setMonthsDiff(12);
    }
  }, [tableData, licenseFormValue]);

  const handleLicenseChange = (e) => {
    if (!licensePricing) {
      errorNotification();
      return;
    }
    const { name, value } = e.target;
    if (name === "numAcChargerReq") {
      setLicenseFieldValue(name, value);
      setLicenseFormValue(prevState => ({
        ...prevState,
        "acLicenseCost": value * licensePricing.ac_charges_monthly * monthsDiff
      }));
    } else if (name === "numDcChargerReq") {
      setLicenseFieldValue(name, value);
      setLicenseFormValue(prevState => ({
        ...prevState,
        "dcLicenseCost": value * licensePricing.dc_charges_monthly * monthsDiff
      }));
    }
  };

  const calculateTotalCost = () => {
    const gst = (licenseFormValue.acLicenseCost + licenseFormValue.dcLicenseCost) + ((licenseFormValue.acLicenseCost + licenseFormValue.dcLicenseCost) * (licensePricing?.tax / 100));
    const totalCost = gst + (gst * (licensePricing?.payment_gateway_percent / 100));
    return totalCost.toFixed(2);
  };


  useEffect(() => {
    setLicenseFieldValue("licenseCost", licenseFormValue.acLicenseCost + licenseFormValue.dcLicenseCost);
    setLicenseFieldValue("totalCost", calculateTotalCost());
  }, [licenseFormValue])

  const formikLicense = useFormik({
    initialValues: getLicenseSettingDefaultValues,
    onSubmit: onSubmitLicense
  });

  const {
    errors: licenseErrors,
    touched: licenseTouched,
    handleSubmit: handleSubmitLicenseSetting,
    getFieldProps: licenseGetFieldProps,
    setFieldValue: setLicenseFieldValue
  } = formikLicense;

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


  useEffect(() => {
    if (!isFetching && data) {
      const profileData = { ...data, ...data.address };
      delete profileData.company;
      delete profileData.role;
      for (let [k, v] of Object.entries(profileData)) {
        "role" !== k && setFieldValue(k, v);
      }
    }
  }, [data, isFetching]);

  const rowOrCellClickRedirect = ({ id }) => {
    navigate(`/team/${id}`);
  };
  if (isFetching) {
    return <Loader />;
  }

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  }

  return (
    <TabContext value={selectedTab}>
      <Stack direction="row" justifyContent="center" alignItem="center" sx={{ width: "100%" }}>
        <RootTabStyle
          indicatorColor="transparent"
          centered
          length={tabsToShow.length}
          onChange={handleTabChange}
        >
          {
            updatedTeamsFormTab.map((tab) => <RootTabItemStyles value={tab.value} label={tab.label} />)
          }
        </RootTabStyle>
      </Stack >
      <Card sx={{ p: 4 }}>
        <TabPanel value="general">
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} autoComplete="off" id="profile" noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Typography variant="subtitle1" className="mb-2">
                    User Detail
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      disabled
                      label="Username"
                      size="small"
                      {...getFieldProps("username")}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Typography variant="subtitle1" className="mb-2">
                    User Info
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="First Name"
                      size="small"
                      {...getFieldProps("firstName")}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="Last Name"
                      size="small"
                      {...getFieldProps("lastName")}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="Email"
                      size="small"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="Phone"
                      size="small"
                      {...getFieldProps("phone")}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="mb-5">
                <Grid item xs={12} lg={12}>
                  <Typography variant="subtitle1" className="mb-2 text-uppercase">
                    User Address
                  </Typography>
                  <Divider className="text-muted" />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="Street"
                      size="small"
                      disabled={isReadOnly}
                      {...getFieldProps("street")}
                      error={Boolean(touched.street && errors.street)}
                      helperText={touched.street && errors.street}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="House number"
                      size="small"
                      disabled={isReadOnly}
                      {...getFieldProps("houseNumber")}
                      error={Boolean(touched.houseNumber && errors.houseNumber)}
                      helperText={touched.houseNumber && errors.houseNumber}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="Zip code"
                      size="small"
                      disabled={isReadOnly}
                      {...getFieldProps("zipCode")}
                      error={Boolean(touched.zipCode && errors.zipCode)}
                      helperText={touched.zipCode && errors.zipCode}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="City"
                      size="small"
                      disabled={isReadOnly}
                      {...getFieldProps("city")}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    error={Boolean(touched.country && errors.country)}
                  >
                    <InputLabel id="country">Country</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      disabled={isReadOnly}
                      label="Country"
                      {...getFieldProps("country")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {Countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Typography variant="subtitle1" className="mb-2">
                    User Roles
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <BaseTable
                    rows={data?.role ?? []}
                    columns={roleColumns}
                    loading={isFetching}
                    getRowId={(row) => row.teamId}
                    rowOrCellClickRedirect={rowOrCellClickRedirect}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={6} lg={6}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={isReadOnly}
                    loading={isLoading}
                  >
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </TabPanel>
        <TabPanel value="license">
          <Box>
            <FormikProvider value={formikLicense}>
              <Form onSubmit={handleSubmitLicenseSetting} autoComplete="off" id="profile" noValidate>
                <Box>
                  <Typography mt={4} sx={{ borderBottom: "2px solid #00000036", paddingBottom: "12px" }}>LICENSE SETTINGS</Typography>
                </Box>
                <Box>
                  <Stack>
                    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: "1rem", marginTop: "1rem" }}>
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Existing No. of  Charger License(AC)" variant="outlined"
                        defaultValue={`${tableData[0]?.num_ac_charger_req ? tableData[0]?.num_ac_charger_req : "N/A"} (Disabled by Default)`}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Valid Till Date" variant="outlined"
                        defaultValue={`${tableData[0]?.num_ac_charger_req_valid_date ? format(new Date(tableData[0]?.num_ac_charger_req_valid_date), "yyyy-MM-dd") : "N/A"} (Disabled by Default)`}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="License Cost" variant="outlined"
                        {...licenseGetFieldProps("licenseCost")}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Existing No. of  Charger License(DC)" variant="outlined"
                        defaultValue={`${tableData[0]?.num_dc_charger_req ? tableData[0]?.num_dc_charger_req : "N/A"} (Disabled by Default)`}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Valid Till Date" variant="outlined"
                        defaultValue={`${tableData[0]?.num_dc_charger_req_valid_date ? format(new Date(tableData[0]?.num_dc_charger_req_valid_date), "yyyy-MM-dd") : "N/A"} (Disabled by Default)`}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Plus GST(18%)" variant="outlined"
                        defaultValue={licensePricing?.tax}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="No. of  Charger License Required(AC)" variant="outlined"
                        {...licenseGetFieldProps("numAcChargerReq")}
                        onChange={handleLicenseChange}
                        error={Boolean(licenseTouched.numAcChargerReq && licenseErrors.numAcChargerReq)}
                        helperText={licenseTouched.numAcChargerReq && licenseErrors.numAcChargerReq}
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Valid Till Date" variant="outlined"
                        {...licenseGetFieldProps("numAcChargerReqValidDate")}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Payment Gateway Charges" variant="outlined"
                        defaultValue={licensePricing?.payment_gateway_percent}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="No. of  Charger License Required(DC)" variant="outlined"
                        {...licenseGetFieldProps("numDcChargerReq")}
                        onChange={handleLicenseChange}
                        error={Boolean(licenseTouched.numDcChargerReq && licenseErrors.numDcChargerReq)}
                        helperText={licenseTouched.numDcChargerReq && licenseErrors.numDcChargerReq}
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Valid Till Date" variant="outlined"
                        {...licenseGetFieldProps("numDcChargerReqValidDate")}
                        disabled
                      />
                      <TextField sx={{ width: "100%" }} id="outlined-basic" label="Total Cost" variant="outlined"
                        {...licenseGetFieldProps("totalCost")}
                        disabled
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ display: "flex", placeContent: "center" }}>
                  <Button type="submit" variant="contained" sx={{ marginTop: "2rem" }}>Proceed To Payment</Button>
                </Box>
              </Form>
            </FormikProvider>
            <Box>
              <Typography mt={4} sx={{ borderBottom: "2px solid #00000036", paddingBottom: "12px" }}>PAYMENT HISTORY</Typography>
            </Box>
            <Box>
              {tableData && <BaseTable
                isReadOnly={isReadOnly}
                rows={tableData ?? []}
                columns={finalTableColumns}
                loading={false}
                getRowId={(row) => row.license_payment_pk}
                pageSize={10}
                showExportCsvBtn={false}
                rowOrCellClickRedirect={rowOrCellClickRedirect}
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": {
                    overflow: "hidden   !important",
                    lineHeight: "20px   !important",
                    whiteSpace: "normal  !important",
                  },
                }}
              />}
            </Box>
          </Box>
        </TabPanel>
      </Card>
    </TabContext>
  );
}

export default UserProfile;
