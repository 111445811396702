import { useContext, forwardRef } from "react";
import { Snackbar, Alert } from "@mui/material";
import { Context } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MuiSnackBar({ message, severity, open, ...props }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(alertSlice.actions.resetAlert());
  };

  return (
    <Snackbar open={open} {...props} onClose={handleClose}>
      <MuiAlert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

MuiSnackBar.defaultProps = {
  autoHideDuration: 6000,
  open: !1,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default MuiSnackBar;
