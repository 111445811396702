import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import visuallyHidden from '@mui/utils/visuallyHidden';

function DatatableHeader(props) {
    const { 
        rowCount, numSelected, order, orderBy, cellHeader, onRequestSort, onAllSelect, actions
    } = props;

    const createSortHandler = (property) => (e) => {
        onRequestSort(e, property);
    }
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox 
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onAllSelect}
                    />
                </TableCell>
                {cellHeader.map((cell) => (
                    <TableCell
                        key={cell.id}
                        align={cell.align}
                        sortDirection={orderBy === cell.id ? order : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === cell.id}
                            direction={orderBy === cell.id ? order : 'asc'}
                            onClick={createSortHandler(cell.id)}
                        >
                            <Typography variant="subtitle2">{cell.label}</Typography>
                            {orderBy === cell.id && (
                            <Box sx={{ ...visuallyHidden }}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {actions && 
                <TableCell></TableCell>
                }
            </TableRow>
        </TableHead>
    )
}

DatatableHeader.defaultProps = {
    onAllSelect: () => {},
    onRequestSort: () => {},
    cellHeader: [],
    order: 'asc',
    numSelected: 0,
    orderBy: '',
    actions: false
}

export default memo(DatatableHeader);