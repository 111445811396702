import { createSlice } from "@reduxjs/toolkit";
import { chargingProfileReducerKey } from "./chargingProfile.constants";

export const chargingProfileSlice = createSlice({
  name: chargingProfileReducerKey,
  initialState: {allData: []},
  reducers: {
    setChargingProfileList(state, { payload }) {
      state.allData = payload;
    },
  },
});
