import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DataGrid, GridFooterContainer, GridNoRowsOverlay,GridPagination } from "@mui/x-data-grid";
import ExportCSV from "../ExportCsv/ExportCSV";

const  CustomFooter=(props)=> {
  const { title,data } = props;
  return (
    <GridFooterContainer>
      <div style={{ marginLeft: '10px' }}>
        {title}: {data}
      </div>
      <GridPagination {...props} />
    </GridFooterContainer>
  );
}



export const BaseTable = ({
  rows,
  columns,
  rowOrCellClickRedirect,
  pagination = true,
  pageSize = 10,
  height,
  sx,
  rowsPerPageOptions = [10, 20, 30],
  showExportCsvBtn,
  csvMorphemes,
  csvColumns,
  sortModel,
  onSortModelChange,
  isReadOnly,
  onPageSizeChange,
  customFooterData={},
  totalRows,
  serverRowFetcher,
  paginationModel,
  ...restProps
}) => {
  const [pagesize, setPageSize] = useState(pageSize);
  const [tableColumns] = useState(()=>!isReadOnly?columns:columns.filter(({field})=>field.toLowerCase()!=="actions"));

  
  //TO SPECIFICALLY NOT SHOW COLUMNS; STATUS AND NOTES FOR TRANSCATIONS TABLE
  // const [updatedColumnsForTransactionsTable] = useState(()=>csvMorphemes==="transactions"?tableColumns.filter(({field})=>field!=="status" && field!=="notes"):[])
  const rowClickHandler = (data, event) => {
    event.stopPropagation();
    const correspondingColumnName = data.field;
    if (correspondingColumnName) {
      if (correspondingColumnName.toLowerCase() === "actions") return;
      else {
        if (rowOrCellClickRedirect) rowOrCellClickRedirect(data);
        return;
      }
    }
  };

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, rows]);

  return (
    <>
      {showExportCsvBtn && rows && rows.length > 0 && (
        <ExportCSV rows={rows} columns={csvColumns} csvMorphemes={csvMorphemes} />
      )}
      <Stack alignItems="flex-end" spacing={2}>
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={tableColumns}
            {...(paginationModel && {page: paginationModel.page})}
            pageSize={pagesize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              if (onPageSizeChange) onPageSizeChange(newPageSize);
            }}
            {...(serverRowFetcher && {paginationMode: "server"})}
            rowCount={totalRows}
            rowsPerPageOptions={rowsPerPageOptions}
            pagination={pagination}
            disableSelectionOnClick
            components={{ NoRowsOverlay: GridNoRowsOverlay,Footer:CustomFooter }}
            componentsProps={{footer:customFooterData}}
            autoHeight={true}
            onCellClick={rowClickHandler}
            {...(pagination && { pageSize: pagesize })}
            {...(pagination && { rowsPerPageOptions: rowsPerPageOptions })}
            {...(!pagination && { hideFooter: true })}
            {...(sortModel && { sortModel })}
            {...(onSortModelChange && { onSortModelChange })}
            {...(sx && { sx })}
            {...restProps}
          />
        </Box>
      </Stack>
    </>
  );
};
