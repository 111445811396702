import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { commonSlice } from "src/store/common/common.slice";

export const AccessCheckBox = ({ value, field, id }) => {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch(
      commonSlice.actions.setFeatureAccess({
        value: event.target.checked ? "Y" : "N",
        accessName: field,
        id,
      })
    );
  };
  return <Checkbox checked={value === "Y"} onChange={handleChange} />;
};
