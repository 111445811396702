import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import { alertSlice } from "src/store/alert/alert.slice";
import { FEATURE } from "src/util/enums";
import Page from "../Page";
import { PriceGroupForm } from "./PriceGroupForm";

export const PriceGroup = () => {
  const navigate = useNavigate();
  const isCreatePriceGroup = useMatch("/pricegroup/new");
  const isReadOnly = useAccess(FEATURE.PRICEGROUP, "R");
  const notAuthorized = useAccess(FEATURE.PRICEGROUP, "N");
  const createAccess = useAccess(FEATURE.PRICEGROUP, "C");
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (isCreatePriceGroup && !createAccess) ||
      (!isCreatePriceGroup && notAuthorized)
    ) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate(sidebarConfig[FEATURE.PRICEGROUP].path);
    }
  }, [dispatch, isCreatePriceGroup, createAccess, navigate, notAuthorized]);
  return (
    <Page title="Price Group | Smart-CMS">
      <BackButton
        text={
          isCreatePriceGroup
            ? "Create Price Group"
            : isReadOnly
            ? "Price Group Details"
            : "Edit Price Group"
        }
        path="/pricegroup"
      />

      <PriceGroupForm />
    </Page>
  );
};
