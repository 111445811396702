import { createSlice } from "@reduxjs/toolkit";
import {tabsCurrentlyOpenedForInitialState,tabsCurrentlyOpenedForReducerKey} from "./tabsOpened.constants";

export const tabsCurrentlyOpenedForSlice = createSlice({
    name:tabsCurrentlyOpenedForReducerKey,
    initialState:tabsCurrentlyOpenedForInitialState,
    reducers:{
        setTabsOpened(state,{payload}){
            // console.log(payload)
            state.detailsId =payload.detailsId;
            state.tabOpenedFor = payload.tabOpenedFor;
            state.tabOpened = payload.tabOpened;
            state.data = payload.data ? payload.data : {};
        }
    }
})