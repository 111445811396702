import { useQuery } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { fetchWithError } from "src/react-query/fetchWithError"
import { queryKeys } from "src/react-query/queryKeys"
import { selector } from "src/react-query/select"
import { chargingProfileSlice } from "src/store/chargingProfile/chargingProfile.slice"
import { is } from "src/util/is"

export const useChargingProfile = ({teamId, chargingProfileId}) => {
  const dispatch = useDispatch();
    const queryResult = useQuery(queryKeys.chargingProfileList(teamId, chargingProfileId), fetchWithError, {
      enabled: !is.empty(teamId),
      select: selector,
      onSuccess: (data) => {
        dispatch(chargingProfileSlice.actions.setChargingProfileList(data));
      }
    });
  
    const chargingProfileOptions = queryResult.data?.map(({ charging_profile_pk }) => (
        charging_profile_pk
      )) || [];
  
    return { ...queryResult, chargingProfileOptions };
  };