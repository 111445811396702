//react
import { useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";

// endpoints, components
import { tagChargerUrl, getTeamUrl } from "src/react-query/endPoints";
import { tagChargersColumns } from "./Tags.constants";
import { BaseTable } from "../BaseTable/BaseTable";
//hooks
import { useList, useGetDetail, useMutate } from "src/hooks/useFetch.hook";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "500px",
};

function TagAttachChargerModal({ open, onClose, data }) {
  const attachRef = useRef({ chargersList: [] });
  const { teamId } = useSelectedTeam();
  const { data: chargers } = useList("chargers");
  const {
    data: tagsChargerList,
    isLoading: isTagsChargerFetching,
    refetch,
  } = useGetDetail("tagsCharger", { id: data?.row.id });
  const { mutateAsync, isLoading } = useMutate("tagsCharger");

  const handleSubmit = async () => {
    const {
      row: { id },
    } = data;
    const { chargersList } = attachRef.current;
    const ids = chargersList.map((c) => c.chargepoint_id);
    await mutateAsync(
      {
        url: getTeamUrl(teamId, tagChargerUrl(id)),
        method: "POST",
        body: { chargerIds: ids },
      },
      { onSuccess: () => onClose() }
    );
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h4" gutterBottom>
          {" "}
          {`Attach charger to tag (${data?.row.tag})`}{" "}
        </Typography>
        <FormControl sx={{ m: 1, width: 400 }}>
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={chargers ?? []}
            onChange={(event, newValue) => {
              attachRef.current.chargersList = newValue;
            }}
            filterOptions={(options) =>
              options?.filter(
                (charger) =>
                  !tagsChargerList?.some(
                    (attachedCharger) =>
                      attachedCharger?.charge_box_id === charger?.chargepoint_id
                  )
              )
            }
            filterSelectedOptions={true}
            getOptionLabel={(option) => option.chargepoint_id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chargers"
                placeholder="Select Charger"
              />
            )}
            sx={{ width: "500px" }}
          />
        </FormControl>
        <div style={{ margin: "5px" }}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            size="large"
            sx={{ margin: 1 }}
            loading={isLoading}
          >
            Attach Chargers
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={onClose}
            size="large"
            sx={{ margin: 1 }}
          >
            Cancel
          </Button>
        </div>
        <BaseTable
          rows={tagsChargerList ?? []}
          columns={tagChargersColumns(refetch)}
          getRowId={(row) => row.charge_box_id}
          loading={isTagsChargerFetching}
        />
      </Box>
    </Modal>
  );
}

TagAttachChargerModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default TagAttachChargerModal;
