import { useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Stack, TextField, FormControl, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { userFormValidationSchema } from "../../util/validationSchemas";
import "../../styles/charger.css";
import { useAccess } from "src/hooks/useAccess.hook";
import { getUserDefaultValues } from "./user.constants";
//import { useCharger, useMutateCharger } from "./useCharger.hook";
import { useMutate, useGetDetail } from "src/hooks/useFetch.hook";
import { addUserUrl, getUserDetailUrl } from "src/react-query/endPoints";
import { Loader } from "../Loader";
import { is } from "src/util/is";
import { FEATURE } from "src/util/enums";

export default function UserForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const newUserMatch = useMatch("/users/new");
  const hasReadOnlyAccess = useAccess(FEATURE.CHARGER, "R");
  const isReadOnly = hasReadOnlyAccess && !newUserMatch;
  const { data, isFetching } = useGetDetail("userDetail", { id });
  const { mutate, isLoading } = useMutate("users");
  const onSuccess = () => navigate("/users");

  const onSubmit = async (userData, { setSubmitting }) => {
    const mutateObject = {
      url: addUserUrl,
      method: "POST",
    };
    if (!is.nullOrUndefined(id) && !newUserMatch) {
      mutateObject.method = "PUT";
      mutateObject.url = getUserDetailUrl(id);
    }
    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: userData,
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: getUserDefaultValues(),
    validationSchema: userFormValidationSchema,
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = formik;

  useEffect(() => {
    if (data) {
      resetForm({ values: data });
    }
  }, [data, resetForm]);

  if (isFetching) {
    return <Loader />;
  }

  if (!newUserMatch && is.empty(data)) {
    navigate("/dashboard");
    return null;
  }

  return (
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          id="charger"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3} className="mb-5">
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="mb-2">
                User detail
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="User Name"
                  size="small"
                  {...getFieldProps("username")}
                  disabled={newUserMatch ? false : true}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={6} lg={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="connectorTypeLabel">User Role</InputLabel>
              <Select
                labelId="connectorTypeLabel"
                id="connectorType"
                value={values.connectorType}
                label="User Role"
                {...getFieldProps("connectorType")}
              >
                <MenuItem selected value="">
                  <em>None</em>
                </MenuItem>
                {UserRole.map((connector) => (
                  <MenuItem key={connector.id} value={connector.value}>
                    {connector.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          </Grid>
          <Grid container spacing={3} className="mb-5">
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="mb-2 text-uppercase">
                User Info
              </Typography>
              <Divider className="text-muted" />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="First Name"
                  size="small"
                  {...getFieldProps("firstName")}
                  disabled={isReadOnly}
                  error={Boolean(touched.firstName && errors.firstName)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Last Name"
                  size="small"
                  {...getFieldProps("lastName")}
                  disabled={isReadOnly}
                  error={Boolean(touched.lastName && errors.lastName)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Email"
                  size="small"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={isReadOnly}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Phone"
                  type="number"
                  size="small"
                  {...getFieldProps("phone")}
                  disabled={isReadOnly}
                  error={Boolean(touched.phone && errors.phone)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={6} lg={6}>
              <Stack direction="row" spacing={2}>
                {!isReadOnly && (
                  <>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={isReadOnly}
                      loading={isSubmitting || isLoading}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      component={RouterLink}
                      to="/users"
                    >
                      Cancel
                    </LoadingButton>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Card>
  );
}
