import { createSlice } from "@reduxjs/toolkit";
import { teamsInitialState, teamsReducerKey } from "./teams.constants";

export const teamsSlice = createSlice({
  name: teamsReducerKey,
  initialState: teamsInitialState,
  reducers: {
    setTeamId(state, { payload }) {
      state.teamId = payload;
    },
  },
});
