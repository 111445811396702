import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import { alertSlice } from "src/store/alert/alert.slice";
import { FEATURE } from "src/util/enums";
import Page from "../Page";
import { RoleForm } from "./RoleForm";

export const Role = () => {
  const navigate = useNavigate();
  const isCreateRole = useMatch("/roles/new");
  const isReadOnly = useAccess(FEATURE.ROLES, "R");
  const notAuthorized = useAccess(FEATURE.ROLES, "N");
  const createAccess = useAccess(FEATURE.ROLES, "C");
  const dispatch = useDispatch();
  useEffect(() => {
    if ((isCreateRole && !createAccess) || (!isCreateRole && notAuthorized)) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate(sidebarConfig[FEATURE.ROLES].path);
    }
  }, [dispatch, isCreateRole, createAccess, navigate, notAuthorized]);
  return (
    <Page title="Role | Smart-CMS">
      <BackButton
        text={
          isCreateRole
            ? "Create Role"
            : isReadOnly
            ? "Role Details"
            : "Edit Role"
        }
        path="/roles"
      />
      <RoleForm />
    </Page>
  );
};
