import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { is } from "src/util/is";
import { useChargingProfile } from "src/hooks/useChargingProfile.hook";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 10,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const team = useSelectedTeam();
  const { teamId } = useSelectedTeam()
  useChargingProfile({teamId});

  /**
   * First thing to call after login or page refresh
   * This function will check if JWT token is set or not
   * If not it will redirect user to login
   * If yes, fetch JWT token from local storage and parse it. set userId to state
   */
  // useEffect(() => {
  //   const userData = Token.parsedToken();
  //   if (userData && userData.success && userData.data) {
  //     setUsername(userData.data.username);
  //     setUserId(userData.data._id);
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  /**
   * First thing to call after login or page refresh
   * return axios.get("/user/getUserDetail", {
      params: {
        username: userData.data.username
      }
   */
  // useEffect(() => {
  //   if (!username) {
  //     return;
  //   }
  //   (async function () {
  //     try {
  //       const getUserDetail = async () => {
  //         const response = await axios(
  //           `${process.env.REACT_APP_SERVER_URL}user/${userId}`,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${Token.get()}`,
  //             },
  //           }
  //         );
  //         const userDetailRes = response.data.data;
  //         if (userDetailRes) {
  //           setUserDetail(userDetailRes);
  //         }
  //       };

  //       await getUserDetail();
  //     } catch (error) {
  //       console.error(error.response);
  //       navigate("/login");
  //     }
  //   })();
  // }, [username]);

  // useEffect(() => {
  //   if (userDetail && userDetail._id) {
  //     setIsAuthenticated(true);
  //   }
  // }, [userDetail]);
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>{!is.empty(team) && <Outlet />}</MainStyle>
    </RootStyle>
  );
}
