import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  useTheme,
  Stack,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  InputLabel,
} from "@mui/material";
// components
import Page from "../components/Page";
import { DashboardCard } from "../components/_dashboard/app";
import { useAuth } from "src/hooks/useAuth.hook";
import { dashboardService } from "src/services";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { useList } from "src/hooks/useFetch.hook";
import Map from "src/common/components/Map";
import {
  DashboardBarGraphStatsCard,
  DashboardDonutStatsCard,
  DashboardNumberStatsCard,
  DashboardMapCard,
  StyledFormControl,
} from "src/components/_dashboard/app/DashboardCard";
import {
  STATISTICS_URL,
  addDuplicatesValues,
  customDateDropdownValidationSchema,
  dashboardCards,
  requiredDateFormatForGraphXaxis,
  statisticValueFormat,
} from "./dashboardConfig";
import { Form, FormikProvider, useFormik } from "formik";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  dashboardDateFormatter,
  isValidTimeInterval,
  timeIntervalFormatter,
  withinTimeBounds,
} from "src/utils/formatTime";
import { is } from "src/util/is";
import { Loader } from "src/components/Loader";
import { alertSlice } from "src/store/alert/alert.slice";
import { useDispatch } from "react-redux";
import enums from "src/util/enums";
import { isSameDay } from "date-fns";

const DashboardAppNew = () => {
  const { user } = useAuth();
  const { data: chargers, isFetching: chargersListFetching } =
    useList("chargers");
  const theme = useTheme();
  const { teamId } = useSelectedTeam();
  const {
    actions: { setAlert },
  } = alertSlice;
  const dispatch = useDispatch();
  const [isFetchingStats, setIsFetchingStats] = useState(true);
  const [customTimeInterval, setCustomTimeInterval] = useState({});
  const [ customSelectedTime, setCustomSelectedTime] = useState({});
  const [statsCards, setStatsCards] = useState(() => {
    const cardsArr = [...dashboardCards.get("statisticCards")].map((card) => ({
      ...card,
      bgColor: theme.palette[card.colorTheme][card.bgColor],
      fontColor: theme.palette[card.colorTheme][card.fontColor],
      colorTheme: theme.palette[card.colorTheme],
    }));
    return cardsArr;
  });

  const [donutStatsCard, setDonutStatsCard] = useState(() => {
    const statsArr = [...dashboardCards.get("donutStatsCards")].map((card) => ({
      ...card,
      svgColor: theme.palette[card.colorTheme][card.svgColor],
      fontColor: theme.palette[card.colorTheme][card.fontColor],
      colorTheme: theme.palette[card.colorTheme],
    }));
    return statsArr;
  });

  const [barStatsCard, setBarStatsCard] = useState({
    xaxis: [],
    yaxis: [],
  });
  const [customTimeRangeArr, customTimeRangeInitial] = useMemo(() => {
    const customTimeRangeArr = [...dashboardCards.get("topCustomRangeCard")];
    const customTimeRangeInitial = customTimeRangeArr
      .filter((component) => component.fieldType !== "LABEL")
      .reduce(
        (valObj, { fieldName, value }) => ({
          ...valObj,
          [fieldName]: value ?? "",
        }),
        {}
      );
    return [customTimeRangeArr, customTimeRangeInitial];
  }, []);

  //ERROR NOTIFICATION
  const errorNotification = () => {
    return dispatch(
      setAlert({
        open: true,
        message: "Please select a valid time interval",
        severity: enums.SEVERITY.ERROR,
      })
    );
  };

  //ONSUBMIT
  const onSubmit = (values, { setSubmitting }) => {
    // console.log(values);
    // setSubmitting(false);
  };

  //ONBLUR
  const handleBlurEvent = (key) => {
    setFieldTouched(key, true);
  };

  const formik = useFormik({
    initialValues: customTimeRangeInitial,
    validationSchema: customDateDropdownValidationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    handleReset,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    setErrors,
    setTouched,
    setFieldTouched,
    resetForm,
  } = formik;

  const timeDropdownOnChangeHandler = (e) => {
    const selectedTimeFrame = e.target.value;
    let timeInterval = {...customTimeInterval};
    switch (selectedTimeFrame) {
      case "Current":
        timeInterval = {
          ...timeIntervalFormatter(new Date(), 0),
          type: selectedTimeFrame,
        };
        break;
      case "Week":
        timeInterval = {
          ...timeIntervalFormatter(new Date(), 7),
          type: selectedTimeFrame,
        };
        break;
      case "Month":
        timeInterval = {
          ...timeIntervalFormatter(new Date(), 31),
          type: selectedTimeFrame,
        };
        break;
      case "Year":
        timeInterval = {
          ...timeIntervalFormatter(new Date(), 365),
          type: selectedTimeFrame,
        };
        break;
      case "Custom":
        timeInterval = {
          ...customSelectedTime
        };
        break;
      default:
        break;
    }
    setCustomTimeInterval(timeInterval);
    setFieldValue("custom_time_dropdown", selectedTimeFrame);
  };

  const timeIntervalTextChangeHandler = (value, fieldName) => {
    if(value && !isNaN(value.getTime())){
      const formatttedTime = dashboardDateFormatter(value);
      let updatedCustomTimeInterval = { ...customTimeInterval, type: "Custom" };
      if (fieldName === "custom_time_start") {
        updatedCustomTimeInterval = {
          ...updatedCustomTimeInterval,
          start: formatttedTime,
        };
      } else {
        updatedCustomTimeInterval = {
          ...updatedCustomTimeInterval,
          end: formatttedTime,
        };
      }
      setCustomSelectedTime(updatedCustomTimeInterval);
      setCustomTimeInterval(updatedCustomTimeInterval)
      setFieldValue(fieldName, value);
    } else return;
  };

  useEffect(() => {
    (async function () {
      try {
        if (
          !is.empty(customTimeInterval.start) &&
          !is.empty(customTimeInterval.end) &&
          !is.empty(customTimeInterval.type)
        ) {
          const getDashboardStatistics = async () => {
            const fetchPromisesArr = [
              ...statsCards,
              {
                url: STATISTICS_URL["CUMULATIVE_ENERGY_USAGE"],
                field: "cumulativeEnergyUsage",
              },
            ].map(({ url, field }) =>
              dashboardService.getIndividualStatistics(
                teamId,
                url,
                customTimeInterval,
                field
              )
            );

            Promise.all(fetchPromisesArr)
              .then((responses) => {
                const formattedResponse = responses.reduce(
                  (formatted, response) => {
                    let formattedResponse = { ...response };
                    delete formattedResponse.field;
                    formatted[response.field] = formattedResponse;
                    return formatted;
                  },
                  {}
                );
                if (!is.empty(formattedResponse)) {
                  const updatedStatsCards = statsCards.reduce(
                    (updatedStatsCards, statsCard) => {
                      let updatedCard = {
                        ...statsCard,
                        isFetching: false,
                        count: !is.nullOrUndefined(
                          formattedResponse[statsCard.field]?.count
                        )
                          ? statisticValueFormat(
                              "count",
                              formattedResponse[statsCard.field]?.count,
                              statsCard.field
                            )
                          : 0,
                        footer: !is.nullOrUndefined(
                          formattedResponse[statsCard.field]?.footer
                        )
                          ? statisticValueFormat(
                              "footer",
                              formattedResponse[statsCard.field]?.footer
                            )
                          : "",
                        percentChange: !is.nullOrUndefined(
                          formattedResponse[statsCard.field]?.percentChange
                        )
                          ? statisticValueFormat(
                              "percentChange",
                              formattedResponse[statsCard.field]?.percentChange,
                              customTimeInterval.type
                            )
                          : null,
                        icon: !is.nullOrUndefined(
                          formattedResponse[statsCard.field]?.percentChange
                        )
                          ? statisticValueFormat(
                              "icon",
                              formattedResponse[statsCard.field]?.percentChange
                            )
                          : "",
                      };
                      updatedStatsCards = [...updatedStatsCards, updatedCard];
                      return updatedStatsCards;
                    },
                    []
                  );
                  const dateFormat =
                    requiredDateFormatForGraphXaxis(customTimeInterval);
                  const updatedBarStatsCard = formattedResponse[
                    "cumulativeEnergyUsage"
                  ].cumulativeEnergyUsage.reduce(
                    (
                      updatedBarStatsCard,
                      { energyConsumption, chargingEnd }
                    ) => {
                      updatedBarStatsCard.xaxis.push(
                        dashboardDateFormatter(
                          new Date(chargingEnd),
                          dateFormat
                        )
                      );
                      updatedBarStatsCard.yaxis.push(energyConsumption);
                      return updatedBarStatsCard;
                    },
                    { xaxis: [], yaxis: [] }
                  );
                  const formattedStats =
                    addDuplicatesValues(updatedBarStatsCard);
                  // console.log(updatedBarStatsCard);
                  // console.log(formattedStats);
                  setStatsCards(updatedStatsCards);
                  setBarStatsCard(formattedStats);
                  // setCustomTimeInterval({});
                }
              })
              .catch((error) => {
                console.log(error);
              });
          };
          if (customTimeInterval.type === "Custom") {
            if (isValidTimeInterval(customTimeInterval)) {
              const updatedStats = statsCards.map((card) => ({
                ...card,
                isFetching: true,
              }));
              setStatsCards(updatedStats);
              await getDashboardStatistics();
            } else errorNotification();
          } else {
            const updatedStats = statsCards.map((card) => ({
              ...card,
              isFetching: true,
            }));
            setStatsCards(updatedStats);
            await getDashboardStatistics();
          }
        }
      } catch (error) {
        console.error(error.response);
      }
    })();
  }, [customTimeInterval]);

  useEffect(() => {
    if (!chargersListFetching) {
      const chargerStats = chargers.reduce(
        (chargerStats, charger) => {
          // const currentDateTime = new Date();
          // const dateToCompare = new Date(charger.lastHeartbeatTimestamp);
          // const isBothDateSame = isSameDay(currentDateTime, dateToCompare);
          // if (isBothDateSame) {
          //   const isWithinTimeLimit = withinTimeBounds(
          //     dateToCompare,
          //     currentDateTime,
          //     15
          //   );
          //   if (isWithinTimeLimit)
          //     chargerStats = {
          //       ...chargerStats,
          //       totalChargers: chargerStats.totalChargers + 1,
          //       activeChargers: chargerStats.activeChargers + 1,
          //     };
          // } else
          if(charger.status==="Active"){
            chargerStats = {
                    ...chargerStats,
                    totalChargers: chargerStats.totalChargers + 1,
                    activeChargers: chargerStats.activeChargers + 1,
                  };
          }else
            chargerStats = {
              ...chargerStats,
              totalChargers: chargerStats.totalChargers + 1,
              inActiveChargers: chargerStats.inActiveChargers + 1,
            };
          return chargerStats;
        },
        { totalChargers: 0, activeChargers: 0, inActiveChargers: 0 }
      );
      const updatedDonutStatsCards = donutStatsCard.reduce(
        (updatedDonutStatsCards, statsCard) => {
          let updatedCard = {
            ...statsCard,
            isFetching: false,
            count: chargerStats[statsCard.field] ?? 0,
          };
          updatedDonutStatsCards = [...updatedDonutStatsCards, updatedCard];
          return updatedDonutStatsCards;
        },
        []
      );
      setDonutStatsCard(updatedDonutStatsCards);
    }
  }, [chargersListFetching]);

  useEffect(() => {
    setFieldValue("custom_time_dropdown", "Current");
    setCustomTimeInterval({
      ...timeIntervalFormatter(new Date(), 0),
      type: "Current",
    });
    setDonutStatsCard((donutStatsCard) =>
      donutStatsCard.map((stats) => ({ ...stats, isFetching: true }))
    );
  }, [teamId]);

  return (
    <Page title="Dashboard | Smart-CMS">
      <Container
        maxWidth="xl"
        sx={{ "@media screen and (min-width:600px)": { padding: 0 } }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4">
              Hi {user.firstName && user.firstName !== "undefined" ? user.firstName : "User"}, Welcome back
            </Typography>
          </Box>
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              id="customDatePicker"
              noValidate
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1} alignItems="center">
                {customTimeRangeArr.length > 0 &&
                  customTimeRangeArr.map(
                    (
                      {
                        fieldName,
                        fieldType,
                        label,
                        placeholder,
                        valueType,
                        options,
                      },
                      index,
                      array
                    ) => {
                      switch (fieldType) {
                        case "DROPDOWN":
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              lg={3}
                              key={`${fieldName}-${label}-${index}`}
                            >
                              <StyledFormControl
                                maxwidth="105px"
                                error={
                                  !!(touched[fieldName] && errors[fieldName])
                                }
                              >
                                <Select
                                  id={`${fieldName}-select`}
                                  labelId={`${fieldName}-select`}
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                  // disableUnderline
                                  // {...getFieldProps(fieldName)}
                                  defaultValue={() =>
                                    options?.find((value) => value.selected)
                                      .value
                                  }
                                  value={values[fieldName]}
                                  onChange={timeDropdownOnChangeHandler}
                                >
                                  {options.map(
                                    ({ label, value, selected }, index) => {
                                      return (
                                        <MenuItem
                                          value={value}
                                          selected={selected}
                                          key={`${label}-${index}`}
                                        >
                                          {label}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </StyledFormControl>
                            </Grid>
                          );
                        case "DATE/TIME":
                          return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                md={3}
                                lg={3}
                                key={`${fieldName}-${label}-${index}`}
                              >
                                <FormControl
                                  sx={{
                                    width: "145px",
                                    marginRight: label === "Start" ? 10 : 0,
                                  }}
                                >
                                  <DatePicker
                                    renderInput={(props) => (
                                      <TextField
                                        {...props}
                                        sx={{
                                          fontWeight: "bold",
                                          "& svg": {
                                            width: "15px",
                                            height: "15px",
                                          },
                                        }}
                                        onBlur={() =>
                                          handleBlurEvent(fieldName)
                                        }
                                        error={
                                          !!(
                                            touched[fieldName] &&
                                            errors[fieldName]
                                          )
                                        }
                                        helperText={
                                          touched[fieldName] &&
                                          errors[fieldName] &&
                                          "Please enter valid date"
                                        }
                                      />
                                    )}
                                    {...getFieldProps(fieldName)}
                                    onClose={() => handleBlurEvent(fieldName)}
                                    name={fieldName}
                                    label={label}
                                    size="small"
                                    sx={{ fontSize: "8px" }}
                                    disabled={
                                      values["custom_time_dropdown"] !==
                                      "Custom"
                                    }
                                    PopperProps={{ placement: "auto" }}
                                    // disablePast={true}
                                    inputFormat="dd/MM/yyyy"
                                    value={
                                      values[fieldName] === ""
                                        ? null
                                        : values[fieldName]
                                    }
                                    onChange={(newValue) =>
                                      timeIntervalTextChangeHandler(
                                        newValue,
                                        fieldName
                                      )
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            </LocalizationProvider>
                          );
                        case "LABEL":
                          return (
                            <InputLabel
                              id={`${fieldName}-label`}
                              sx={{ marginLeft: 3, marginTop: 1 }}
                              key={`${fieldName}-${label}-${index}`}
                            >
                              {label}
                            </InputLabel>
                          );
                        default:
                          return <></>;
                      }
                    }
                  )}
              </Grid>
            </Form>
          </FormikProvider>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Grid container spacing={1}>
            {statsCards &&
              statsCards.length > 0 &&
              statsCards.map((card, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={`${card?.title}-${index}`}
                  >
                    <DashboardNumberStatsCard {...card} />
                  </Grid>
                );
              })}
          </Grid>
          <DashboardDonutStatsCard
            stats={donutStatsCard}
            success={theme.palette.success.main}
            error={theme.palette.error.main}
            isFetchingChargerList={donutStatsCard.every(
              (stats) => stats.isFetching === true
            )}
          />
        </Stack>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} sm={7} md={6}>
            <DashboardBarGraphStatsCard
              title="Cumulative Usage"
              xaxis={barStatsCard.xaxis}
              xaxisType="dateTime"
              yaxis={barStatsCard.yaxis}
              colorTheme={theme.palette.success}
              bgColor={theme.palette.success.lighter}
              fontColor={theme.palette.success.darker}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            <DashboardMapCard
              title="Map - Charging Location"
              bgColor={theme.palette.secondary.lighter}
              fontColor={theme.palette.grey.A700}
            >
              <Map searchBar={!1} data={chargers} />
            </DashboardMapCard>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardAppNew;
