import { useMatch, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  CircularProgress,
  ImageList,
  ImageListItem,
  Button,
  Dialog,
  IconButton,
  DialogContent,
  CardContent
} from "@mui/material";
import PersonOutline from "@iconify/icons-eva/person-outline";
import DeleteIcon from "@iconify/icons-eva/minus-fill";
import AddIcon from "@iconify/icons-eva/plus-fill";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { is } from "src/util/is";
import { BaseTable } from "../BaseTable/BaseTable";
import { teamsFormTabs, getTeamDefaultValues, teamMembersColumns, possibleBusinessTypes, initialPayoutAccountObj, initialFinanceConfigObj } from "./teams.constants";
import { Form, FormikProvider, useFormik } from "formik";
import {
  useGetDetail,
  useGetSettlementPercentage,
  useMutate,
  usePaymentDetailsOfCompany,
} from "src/hooks/useFetch.hook";
import { teamUrl, teamUrlId } from "src/react-query/endPoints";
import { useAccess } from "src/hooks/useAccess.hook";
import { Loader } from "../Loader";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import { queryKeys } from "src/react-query/queryKeys";
import { fetchWithError } from "src/react-query/fetchWithError";
import { useQuery } from "@tanstack/react-query";
import { selector } from "src/react-query/select";
import enums, { FEATURE } from "src/util/enums";
import Address from "src/common/components/Address";
import { teamFormValidationSchema } from "src/util/validationSchemas";
import { RootTabItemStyles, RootTabStyle } from "src/theme/overrides/Tabs";
import { Icon } from "@iconify/react";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import { useDispatch, useSelector } from "react-redux";
import { TeamUserModal } from "./TeamUserModal";
import { alertSlice } from "src/store/alert/alert.slice";
import { isObjectWithNonNullValues } from "src/utils/utility";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import TeamFinanceForm from "./TeamFinanceForm";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
import { useMutation } from "@tanstack/react-query";
import { fileToBase64 } from "src/utils/fileConvertBase64";
import { issueImgUpload, issueSubmission } from "../ServiceRequest/serviceRequest.hook";
import ImgPreviewModal from "src/components/ImagePreviewModal/ImgPreviewModal";
import { GridCloseIcon } from "@mui/x-data-grid";

export const TeamForm = () => {
  // const [chargemilesPayoutIdDisabled,setChargemilesPayoutIdDisabled] = useState(true);
  const { teamId: parentCompanyId } = useSelectedTeam()
  const [selectedTab, setSelectedTab] = useState("general");
  const [addPayoutAccounts, setAddPayoutAccounts] = useState(false)
  const { id } = useParams();
  const { open: modalOpen } = useSelector(({ dialog }) => dialog);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    actions: { setAlert },
  } = alertSlice;
  const isCreateTeam = useMatch("/team/new");
  const hasReadOnlyAccess = useAccess(FEATURE.TEAM, "R");
  const hasFinanceConfigAccess = useAccess(FEATURE.FINANCE, "FA");
  const [imageUploadPending, setImageUploadPending] = useState(false);
  const [uploadedImgUrl, setUploadedImageUrl] = useState(false);
  const imageUploadInputRef = useRef();
  const imgUpload = useMutation(issueImgUpload);
  const submission = useMutation(issueSubmission);
  const [photoModal, setPhotoModal] = useState({ open: false, src: "" });

  const isReadOnly = hasReadOnlyAccess && !isCreateTeam;

  const [teamUsersData, setTeamUsers] = useState([]);

  const tabsToShow = useMemo(() => {
    const updatedTeamsFormTab = hasFinanceConfigAccess ? [...teamsFormTabs] : teamsFormTabs.filter(
      (tab) => tab.value !== "finance" && tab.value !== "payout accounts"
    );

    if (id) {
      return updatedTeamsFormTab;
    }

    return updatedTeamsFormTab.filter(
      (tab) => tab.value !== "members"
    );
  }, [hasFinanceConfigAccess, id]);

  const { data: teamUsers, isFetching: isUserFetching, refetch: refetchMembers, isRefetching: isRefetchingMembers } = useGetDetail(
    "usersByTeam",
    { id }
  );

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const updatedImagePreview = { ...imagePreview, open: true, value: file };
    let updatedAttachmentObj = { ...values.attachment, file, isUploading: true, };
    formik.setFieldValue("attachment", updatedAttachmentObj);
    setImagePreview(updatedImagePreview);
  };

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const uploadImageHandler = async (file) => {

    if (file.size > 1048576) {
      dispatch(
        setAlert({
          open: true,
          message: "File size should not exceed 1 MB.",
          severity: enums.SEVERITY.ERROR,
        })
      );
      return;
    }

    // Validate image dimensions
    const imageDimensions = await getImageDimensions(file);
    if (imageDimensions.width > 80 || imageDimensions.height > 80) {
      dispatch(
        setAlert({
          open: true,
          message: "Image dimensions should not exceed 80 x 80 pixels.",
          severity: enums.SEVERITY.ERROR,
        })
      );
      return;
    }

    const fileInBase64 = await fileToBase64(file);
    imgUpload.mutate(
      {
        image: fileInBase64,
        folder: "logo"
      },
      {
        onSuccess: (response) => {
          // console.log(response)
          setImageUploadPending(false);
          const updatedAttachmentObj = {
            ...values.attachment,
            url: response.data.data,
          };
          formik.setFieldValue("attachment", updatedAttachmentObj);
        },
      }
    );
    closePreviewModal();
    setImageUploadPending(true);
  };

  const cancelImageUpload = (currentSavedImg) => {
      if (currentSavedImg) {
        formik.setFieldValue("attachment", { "url": currentSavedImg });
      } else formik.setFieldValue("attachment", {});
      closePreviewModal();
  };

  const closePreviewModal = () =>
    setImagePreview({ ...imagePreview, open: false });

  const [imagePreview, setImagePreview] = useState({
    open: false,
    value: "",
    imagePreviewBtns: [
      {
        btnLabel: "Upload",
        action: uploadImageHandler,
        params: true,
      },
      {
        btnLabel: "Cancel",
        action: cancelImageUpload,
        params: true
      },
    ],
  });

  const {
    data: paymentDetails,
    isFetching: isPaymentDetailsFetching,
  } = usePaymentDetailsOfCompany(hasFinanceConfigAccess);
  // const {
  //   data: settlementPercentage,
  //   isFetching: isSettlementPercentageFetching,
  // } = useGetSettlementPercentage(isCreateTeam);
  const { data: teams, isFetching } = useQuery(
    queryKeys.team(id),
    fetchWithError,
    { enabled: !is.empty(id), select: selector }
  );
  const { mutate, isLoading } = useMutate("team");

  const onSubmit = async (teamData, { setSubmitting }) => {
    const mutateObject = {
      url: teamUrl,
      method: "POST",
    };
    const {
      name,
      description,
      active,
      city,
      country,
      state,
      street,
      zipCode,
      address,
      locationLongitude,
      locationLatitude,
      payoutAccounts,
      financeConfig,
      attachment
    } = teamData;


    // if(!noNullValues && hasFinanceConfigAccess){

    //   dispatch(
    //     setAlert({
    //       open: true,
    //       message: "For every payout bank account, please specify full information (GST number is optional)",
    //       severity: enums.SEVERITY.ERROR,
    //     })
    //   );
    //   setSubmitting(false);
    //   return;
    // }

    if (is.empty(locationLatitude) || is.empty(locationLongitude)) {
      dispatch(
        setAlert({
          open: true,
          message: "Please specify an address, lattitude and longitude cant be empty",
          severity: enums.SEVERITY.ERROR,
        })
      );
      setSubmitting(false);
      return;
    }

    const finalPayoutAccounts = hasFinanceConfigAccess ? payoutAccounts.reduce(
      (finalPayoutAccounts, payoutAccount) => {


        const noNullValues = isObjectWithNonNullValues(payoutAccount, ["gst"]);

        if (noNullValues) {
          const {
            business_name,
            business_type,
            beneficiary_name,
            email,
            phone,
            account_no,
            ifsc_code,
            gst,
            payoutDetailId,
            tax_percentage,
            payout_percentage,
            account_type
          } = payoutAccount;
          const updatedPayoutAccount = {
            business_name: business_name.trim(),
            business_type: business_type.trim(),
            beneficiary_name: beneficiary_name.trim(),
            email: email.trim(),
            phone: phone.trim(),
            account_no: account_no.trim(),
            ifsc_code: ifsc_code.trim(),
            gst: gst.trim(),
            account_type: account_type.trim(),
            payout_percentage,
            tax_percentage,
            payoutDetailId: payoutDetailId ?? null
          };

          finalPayoutAccounts = [...finalPayoutAccounts, updatedPayoutAccount]
        }
        return finalPayoutAccounts
      }, []
    ) : [];
    const teamReqBody = {
      name,
      description,
      active,
      parentCompanyId,
      address: {
        ...address,
        city,
        country,
        zipCode,
        state,
        street,
        lat: locationLatitude,
        lng: locationLongitude,
      },
      attachment
    };


    if (hasFinanceConfigAccess) {
      teamReqBody.financeConfig = financeConfig
      teamReqBody.payoutAccounts = finalPayoutAccounts
    };

    if (!is.nullOrUndefined(id) && !isCreateTeam) {
      mutateObject.method = "PUT";
      mutateObject.url = teamUrlId(id);
    }
    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: teamReqBody,
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const onSuccess = () => {
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId: "",
      tabOpenedFor: "",
      tabOpened: ""
    }));
    navigate("/team");
  };

  const formik = useFormik({
    initialValues: getTeamDefaultValues(),
    validationSchema: teamFormValidationSchema,
    onSubmit,
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
    handleChange
  } = formik;

  const rowOrCellClickRedirect = ({ id }) => {
    navigate(`/team/${id}`);
  };

  useEffect(() => {
    !is.empty(teamUsers) && setTeamUsers(teamUsers);
  }, [teamUsers]);

  // useEffect(() => {
  //   if (
  //     isCreateTeam &&
  //     !isSettlementPercentageFetching &&
  //     !is.empty(settlementPercentage)
  //   ) {
  //     const [settlementPercObj] = settlementPercentage;
  //     setFieldValue("settlementPerc", settlementPercObj?.settlementPerc);
  //   }
  // }, [isSettlementPercentageFetching]);

  useEffect(() => {
    if (!is.empty(teams)) {
      const [team] = teams;
      const { address, payoutAccounts, financeConfig, url } = team;
      const { lat, lng } = address;

      //   const keepDisabled = team.chargemilesPayoutId
      //  setChargemilesPayoutIdDisabled(keepDisabled)
      if (!isCreateTeam && is.empty(payoutAccounts)) {
        setAddPayoutAccounts(true)
      } else {
        setAddPayoutAccounts(false)
      }
      dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
        detailsId: team?.name,
        tabOpenedFor: "team",
        tabOpened: "general"
      }))
      resetForm({
        values: {
          ...team,
          ...address,
          locationLatitude: lat,
          locationLongitude: lng,
          payoutAccounts: is.empty(payoutAccounts) ? [initialPayoutAccountObj] : payoutAccounts,
          financeConfig: is.empty(financeConfig) ? initialFinanceConfigObj : financeConfig,
          attachment: { "url": url }
        },
      });
    }
  }, [teams, resetForm]);

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  }

  const handleManageUsersOpen = useCallback(() => {
    // console.log("open modal")
    dispatch(dialogSlice.actions.setDialog({ open: true, id }));
  }, [dispatch, id]);

  const handleMangageUsersClose = useCallback(() => {
    // console.log("close modal")
    refetchMembers()
    dispatch(dialogSlice.actions.setDialog({ open: false, id: null }));
  }, [dispatch, refetchMembers])

  const handleBankAccountDelete = useCallback((index) => {
    const numberOfBankDetails = values.payoutAccounts.length;
    if (numberOfBankDetails === 1) {
      dispatch(
        setAlert({
          open: true,
          message: "At least one payout account shall be defined",
          severity: enums.SEVERITY.ERROR,
        })
      );
      return
    }
    const updatedBankDetailsFields = values.payoutAccounts.filter((_, ind) => index !== ind);
    setFieldValue("payoutAccounts", updatedBankDetailsFields)
    // setFieldValue("disabled",true);
    return

  }, [values.payoutAccounts, setFieldValue, dispatch, setAlert])

  const handleBankAccountAddition = useCallback(() => {
    const updatedBankDetailsFields = [...values.payoutAccounts, initialPayoutAccountObj];
    setFieldValue("payoutAccounts", updatedBankDetailsFields)
    return

  }, [values.payoutAccounts, setFieldValue])

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreateTeam && is.empty(teams)) {
    navigate("/team");
    return null;
  }

  return (
    <>
      <TabContext value={selectedTab}>
        <Stack direction="row" justifyContent="center" alignItem="center" sx={{ width: "100%" }}>
          <RootTabStyle
            indicatorColor="transparent"
            centered
            length={tabsToShow.length}
            onChange={handleTabChange}
          >
            {
              tabsToShow.map((tab) => <RootTabItemStyles key={tab.value} value={tab.value} label={tab.label} />)
            }
          </RootTabStyle>
        </Stack >

        <Card sx={{ p: 2 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" id="team" noValidate onSubmit={handleSubmit}>
              <TabPanel value="general">
                <Grid container spacing={3} className="cms-grid-mb">
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth size="small">
                      <TextField
                        id="name"
                        label="Team Name"
                        size="small"
                        variant="outlined"
                        {...getFieldProps("name")}
                        disabled={isReadOnly}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label"> Status </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        defaultValue={values.active}
                        disabled={isReadOnly}
                        {...getFieldProps("active")}
                      >
                        <MenuItem value={"Y"}>Active</MenuItem>
                        <MenuItem value={"N"}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth size="medium" >
                      <TextField
                        multiline
                        rows={6}
                        variant="outlined"
                        id="description"
                        label="Description"
                        {...getFieldProps("description")}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ padding: "10px" }}
                      >
                        {imageUploadPending ? (
                          <Box
                            sx={{
                              height: "170px",
                              width: "250px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        ) : values.attachment &&
                          is.empty(values.attachment.url) ? (
                          <TextField
                            label="Upload WebApp icon"
                            value={!imageUploadPending ? "" : "LOADING...."}
                            placeholder={"No file chosen"}
                            onChange={handleChange}
                            helperText={`This Image will show as icon on WebApp.`}
                            disabled
                          />
                        ) : (
                          <div>
                            <Typography variant="body2" color="text.secondary">
                              Upload WebApp icon
                            </Typography>
                            {values?.attachment?.url && (
                              <Card style={{ border: '1px solid #ddd', marginBottom: 10, boxShadow: "none", width: "400px" }}>
                                <CardContent sx={{display: "flex", placeContent: "center"}}>
                                  <ImageList cols={1}>
                                    <ImageListItem key={`${values?.attachment?.url}-key`}>
                                      <img
                                        src={values?.attachment?.url}
                                        alt={"attachment"}
                                        style={{ maxHeight: "100px", maxWidth: "100px" }}
                                        loading="eager"
                                        onClick={() => { setPhotoModal({ open: true, src: values?.attachment?.url }) }}
                                      />
                                    </ImageListItem>
                                  </ImageList>
                                </CardContent>
                              </Card>
                            )}
                            <Typography variant="body2" color="text.secondary">
                            This Image will show as icon on WebApp.
                            </Typography>
                          </div>
                        )}
                        <LoadingButton
                          variant="contained"
                          onClick={() => imageUploadInputRef.current.click()}
                          loading={imageUploadPending}
                          loadingIndicator="Loading…"
                          endIcon={<Icon icon="uil:image-upload"></Icon>}
                        >
                          Upload
                          <input
                            accept="image/jpeg,image/png,image/jpg"
                            type="file"
                            hidden
                            id="issue-image"
                            ref={imageUploadInputRef}
                            onChange={handleImageUpload}
                          />
                        </LoadingButton>
                      </Stack>
                      {/* <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      m={2}
                    >
                      <Button
                        variant="contained"
                        disabled={imageUploadPending}
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button variant="outlined" type="reset">
                        Reset
                      </Button>
                    </Stack> */}
                    </Box>
                  </Grid>

                </Grid>

              </TabPanel>
              <TabPanel value="payout accounts">
                {(isCreateTeam || addPayoutAccounts) &&
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="right"
                    mb={3}
                  >
                    <LoadingButton
                      size="medium"
                      variant="contained"

                      startIcon={<Icon icon={AddIcon}></Icon>}
                      // loading={isSubmitting || isLoading}
                      onClick={handleBankAccountAddition}
                    >
                      Add Account
                    </LoadingButton>
                  </Stack>
                }
                <Stack>
                  {
                    values.payoutAccounts.map((payoutAccountObj, index, arr) => {

                      const {
                        business_type,
                        creation,
                        deleteBtn
                      } = payoutAccountObj;

                      // if(disabled) return<></>;

                      return (
                        <Grid
                          container
                          spacing={3}
                          my={3}
                          className="cms-grid-mb"
                          key={`payout-account-${index}`}
                        >
                          <Grid item xs={12} lg={12} >
                            <Stack
                              direction="row"
                              alignItems="baseline"
                              justifyContent="space-between"
                              mb={1}
                              // padding={1}
                              sx={{ borderBottom: "1px solid grey" }}
                            >
                              <Typography variant="subtitle1">
                                {`PAYOUT ACCOUNT ${index + 1}`}
                              </Typography>
                              {deleteBtn && arr.length > 1 &&
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  startIcon={<Icon icon={DeleteIcon}></Icon>}
                                  // loading={isSubmitting || isLoading}
                                  onClick={() => handleBankAccountDelete(index)}
                                >
                                  Delete
                                </LoadingButton>
                              }
                            </Stack>
                            {/* <Divider variant="middle" /> */}
                          </Grid>
                          {creation && <Grid item xs={12} lg={12} md={12}>

                            <Typography variant="caption">
                              **Either fill the form completely (excluding GST) or keep form completely empty
                            </Typography>
                          </Grid>}
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`business-name-${index}`}
                                label="Business / Individual Name"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].business_name`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].business_name && errors.payoutAccounts[index].business_name
                                )}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].business_name && errors.payoutAccounts[index].business_name
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl
                              size="small"
                              fullWidth
                              error={touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].business_type && errors.payoutAccounts[index].business_type}
                            >
                              <InputLabel id={`business-type-input-label-${index}`} >

                                Business Type
                              </InputLabel>
                              <Select
                                labelId={`business-type-input-label-${index}`}
                                id={`business-type-${index}`}
                                disabled={isReadOnly || !creation}
                                value={business_type}
                                onChange={(e) =>
                                  setFieldValue(`payoutAccounts[${index}].business_type`, e.target.value)
                                }
                                label="Business Type"
                                {...getFieldProps(`payoutAccounts[${index}].business_type`)}
                              >
                                <MenuItem selected value="None" disabled>
                                  <em>None</em>
                                </MenuItem>
                                {possibleBusinessTypes.map(
                                  ({ label, value, disabled }, index) => {
                                    return (
                                      <MenuItem
                                        key={`transaction-status-${index}-${label}`}
                                        value={value}
                                        disabled={disabled}
                                      >
                                        {label}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`beneficiary-name-${index}`}
                                label="Beneficiary Name"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].beneficiary_name`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].beneficiary_name &&
                                  errors.payoutAccounts[index].beneficiary_name
                                )}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].beneficiary_name &&
                                  errors.payoutAccounts[index].beneficiary_name
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`email-${index}`}
                                label="Email"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].email`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].email && errors.payoutAccounts[index].email)}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].email && errors.payoutAccounts[index].email}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`phone-${index}`}
                                label="Phone Number"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].phone`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].phone && errors.payoutAccounts[index].phone)}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].phone && errors.payoutAccounts[index].phone}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`account-no-${index}`}
                                label="Account Number"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].account_no`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].account_no && errors.payoutAccounts[index].account_no
                                )}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].account_no && errors.payoutAccounts[index].account_no}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`account-no-${index}`}
                                label="Account Type"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].account_type`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].account_type && errors.payoutAccounts[index].account_type
                                )}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].account_type && errors.payoutAccounts[index].account_type}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`ifsc-code-${index}`}
                                label="IFSC Code"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].ifsc_code`)}
                                disabled={isReadOnly || !creation}

                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].ifsc_code && errors.payoutAccounts[index].ifsc_code
                                )}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].ifsc_code && errors.payoutAccounts[index].ifsc_code}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4} md={4}>
                            <FormControl fullWidth size="small">
                              <TextField
                                id={`bank-address-${index}`}
                                label="GST Number"
                                size="small"
                                variant="outlined"
                                {...getFieldProps(`payoutAccounts[${index}].gst`)}
                                disabled={isReadOnly || !creation}
                                error={Boolean(touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                  touched.payoutAccounts[index].gst && errors.payoutAccounts[index].gst
                                )}
                                helperText={touched && touched.payoutAccounts && errors && errors.payoutAccounts && touched.payoutAccounts[index] && errors.payoutAccounts[index] && touched.payoutAccounts[index].gst && errors.payoutAccounts[index].gst}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                </Stack>
              </TabPanel>
              <TabPanel value="address">
                <Address
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  formValues={values}
                  notDisplayTitle={true}
                  displayedFor="team"
                />
                {/* <Grid container mt={3}>
            <Grid item xs={6} lg={4} md={4}> */}

                {/* </Grid>
          </Grid> */}
              </TabPanel>
              <TabPanel value="members">
                {!isCreateTeam && (
                  <Card
                    variant="outlined"
                    sx={{ p: 1 }}
                    style={{ marginBottom: "20px", padding: "35px 20px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "20px",
                      }}
                    >
                      <h2 style={{ margin: "5px" }}>
                        {" "}
                        Team members ({teamUsersData.length}){" "}
                      </h2>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        endIcon={<Icon icon={PersonOutline}></Icon>}
                        disabled={isReadOnly}
                        onClick={handleManageUsersOpen}
                      >
                        Manage members
                      </LoadingButton>
                    </div>
                    <BaseTable
                      rows={teamUsersData ?? []}
                      columns={teamMembersColumns(false, false)}
                      getRowId={(row) => row.userId}
                      loading={isUserFetching || isRefetchingMembers}
                      // rowOrCellClickRedirect={rowOrCellClickRedirect}
                      pageSize={15}
                    />
                  </Card>
                )}
              </TabPanel>
              <TabPanel value="finance">
                {!isPaymentDetailsFetching ?
                  <TeamFinanceForm formik={formik} permissions={isReadOnly} paymentDetails={paymentDetails ?? {}} />
                  : <Loader />
                }
              </TabPanel>
              {
                selectedTab !== "members" && <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  {!isReadOnly && (
                    <>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={isReadOnly}
                        loading={isSubmitting || isLoading}
                      >
                        Save
                      </LoadingButton>
                      <LoadingButton
                        size="large"
                        type="button"
                        variant="outlined"
                        component={RouterLink}
                        to="/team"
                      >
                        Cancel
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              }
            </Form>
          </FormikProvider>
        </Card>
      </TabContext>
      {modalOpen && (
        <TeamUserModal open={modalOpen} teamId={id} onClose={handleMangageUsersClose} enableRedirection={false} />
      )}
      {imagePreview.open && (
        <ImgPreviewModal
          imageObj={imagePreview}
          buttonsArray={imagePreview.imagePreviewBtns}
          currentSavedImg = {values?.attachment?.url}
        />
      )}

      {photoModal &&
        <Dialog
          onClose={() => { setPhotoModal({ open: false, src: "" }) }}
          aria-labelledby="customized-dialog-title"
          open={photoModal.open}
        >
          <IconButton
            aria-label="close"
            onClick={() => { setPhotoModal({ open: false, src: "" }) }}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <GridCloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: "300px", minWidth: "600px", display: "flex", placeItems: "center", placeContent: "center", padding: "32px 40px" }}>
            <img src={photoModal.src} alt="Review" />
          </DialogContent>
        </Dialog>
      }
    </>
  );
};
