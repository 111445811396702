import React from "react";
import { Link as RouterLink } from "react-router-dom";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";

const AddNewBtn = ({ label, to , action }) => (
  <Button
    variant="contained"
    {...(to && {to,component:RouterLink})}
    {...(action && {onClick:action})}
    startIcon={<Icon icon={plusFill} />}
  >
    {`${label}`}
  </Button>
);

export default AddNewBtn;
