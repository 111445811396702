import { useAccess } from "src/hooks/useAccess.hook";
import editFill from "@iconify/icons-eva/edit-fill";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import Flip2Fill from "@iconify/icons-eva/flip-2-fill";
import viewFill from "@iconify/icons-eva/eye-fill";
import remoteOperations from "../../image/remoteOperations.svg"
import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { chargerIdUrl, getTeamUrl } from "src/react-query/endPoints";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { useCallback, useMemo } from "react";
import { FEATURE } from "src/util/enums";
import { useDispatch } from "react-redux";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import {tabsCurrentlyOpenedForSlice} from "src/store/tabsOpened/tabsOpened.slice";
import { getImageIcon } from "src/utils/utility";
import { useNavigate } from "react-router-dom";

export const ChargerActionMenu = ({ id, row }) => {
  const isReadOnly = useAccess(FEATURE.CHARGER, "R");
  const hasEditAccess = useAccess(FEATURE.CHARGER, "U");
  const hasDeleteAccess = useAccess(FEATURE.CHARGER, "D");
  const { teamId } = useSelectedTeam();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate } = useMutate("chargers");

  const onDelete = useCallback(() => {
    mutate({ url: getTeamUrl(teamId, chargerIdUrl(id)), method: "DELETE" });
  }, [teamId, id, mutate]);

  const onRemoteOperation = useCallback(() => {
    // dispatch(
    //   dialogSlice.actions.setDialog({
    //     open: true,
    //     id: {
    //       modalType: "remoteoperation",
    //       id: row?.chargepoint_id,
    //       pk: row?.chargepoint_pk,
    //     },
    //   })
    // );
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:row?.chargepoint_id,
      tabOpenedFor:"charger",
      tabOpened:"remote operations"
    }))
    navigate(`/charger/${row?.chargepoint_pk}`)
  }, [row, dispatch,navigate]);

  const viewLogs = useCallback(() => {
    // dispatch(
    //   dialogSlice.actions.setDialog({
    //     open: true,
    //     id: { modalType: "viewlogs", id: row?.chargepoint_id },
    //   })
    // ); 
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:row?.chargepoint_id,
      tabOpenedFor:"charger",
      tabOpened:"logs"
    }))
    navigate(`/charger/${row?.chargepoint_pk}`)
  }, [row,navigate,dispatch]);

  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        routerLink: `${id}`,
        hidden: !isReadOnly,
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        routerLink: `${id}`,
        hidden: !hasEditAccess,
      },
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onDelete,
        hidden: !hasDeleteAccess,
      },
      {
        id: "transactions",
        title: "View Transactions",
        icon: Flip2Fill,
        routerLink: `/transactions?charger=${id}`,
      },
      {
        id: "remoteOperation",
        title: "Remote Operation",
        iconContainer: getImageIcon(remoteOperations),
        action: onRemoteOperation,
        hidden: !hasEditAccess,
      },
      {
        id: "viewLogs",
        title: "View  Logs",
        icon: viewFill,
        action: viewLogs,
      },
    ],
    [
      id,
      isReadOnly,
      hasEditAccess,
      hasDeleteAccess,
      onDelete,
      onRemoteOperation,
      viewLogs,
    ]
  );

  return <ActionsMenu menuList={actionList} />;
};
