import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useSelectedTeam = () => {
  const { teamId, user } = useSelector(({ teams, auth }) => ({
    teamId: teams.teamId,
    user: auth.user,
  }));

  const team = useMemo(() => {
    return user?.role?.find((name) => name.teamId === teamId) ?? {};
  }, [teamId, user]);
  return team;
};

export const useSelectedCompany = () => {
  const { user } = useSelector(({ auth }) => ({
    user: auth.user
  }));
  const company = useMemo(() => {
    return user.company
  }, [user]);
  return company;
}

export const useTeamListRedux =()=>{
  const {teams} = useSelector(({auth})=>({
    teams:auth.user.role.map(({teamId,teamName})=>({teamId,teamName}))
  }))

  return teams
}