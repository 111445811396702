import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { yearFirstDateTimeFormat} from "src/utils/formatTime";
import { Refund } from "./RefundActionMenu";

export const refundColumns = (refetch = () => {}, readAccess = !1,isReadOnly) => [
    {
      field: "transactionNumber",
      headerName: "Transaction Number",
      width: 150,
      renderCell: ({ value, id }) => (
        <>
        { isReadOnly?value:<Link underline="none" component={RouterLink} to={`/transactions/${id}`}>
          {value}
        </Link>}
        </>
      ),
    },
    {
      field: "customerEmail",
      headerName: "Customer Email",
      width: 200
    },
    {
      field: "companyName",
      headerName: "Company",
      width: 180
    },
    {
      field: "amountCharged",
      headerName: "Amount Charged",
      width: 160
    },
    {
      field: "totalPaid",
      headerName: "Amount Paid",
      width: 160
    },
    {
      field: "totalRefund",
      headerName: "Refund Amount",
      width: 160
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "date",
      width: 180,
      renderCell: ({ value }) => (
        <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
          <span>{yearFirstDateTimeFormat(value)}</span>
        </Tooltip>
      )
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "date",
      width: 180,
      renderCell: ({ value }) => (
        <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
          <span>{yearFirstDateTimeFormat(value)}</span>
        </Tooltip>
      )
    },
    {
      field: "Refund",
      headerName: "Payment Refund",
      width: 150,
      hide: !readAccess,
      renderCell: ({ row }) => <Refund row={row} refetch={refetch} /> 
    },
  ];