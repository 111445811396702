import { createSlice } from "@reduxjs/toolkit";
import { alertInitialState, alertReducerKey } from "./alert.constants";

export const alertSlice = createSlice({
  name: alertReducerKey,
  initialState: alertInitialState,
  reducers: {
    setAlert(state, { payload }) {
      state.open = payload?.open ?? false;
      state.severity = payload?.severity ?? "error";
      state.message = payload?.message ?? "";
    },
    resetAlert(state) {
      state.open = false;
      state.severity = "error";
      state.message = "";
    },
  },
});
