import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography,Grid, Stack, CssBaseline} from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { SignUpForm } from '../components/authentication/register';
import Logo from "src/components/Logo";
import loginLogo from "src/image/logo/loginPageLogo.jpg"
import "./pages.css"


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  maxHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function SignUp() {
  return (
    <RootStyle title="SignUp | Smart-CMS">
      <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100vh" }}>
      <CssBaseline />

      <Box container className="container" spacing={2} sx={{ display: "grid", gridTemplateColumns: "60% 40%", placeItems: "center"}}> 
        
        <Box sx={{ position: "relative", width: "100%", height: { xs: "auto", md: "100%" }, display: "flex", justifyContent: "center", alignItems: "center", minHeight: '100vh' }}>
        <img
          src={loginLogo}
          alt="login"
          style={{height: "inherit"}}
        />
          <Box as="div" sx={{
              position: 'absolute',
              top: 0,
              left: 50,
              height: '100%',
              width: '46.5%',
              backgroundColor: 'rgba(0, 85, 164, 0.56)',
              color: 'white',
              padding: '0 15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontFamily: 'Poppins, sans-serif',
            }} className="transparent_container">
            <Typography variant="h2" className="logo_heading">SMART Charging Management System (SMART CMS)</Typography>
            <Typography variant="body1" className="logo_para">Smart Charging Management System provides you complete control over operating your EV Charging infrastructure, eases the management of your charging networks through one control panel, and offers comprehensive insights into EV drivers' charging activities. This enables you to analyze usage patterns and optimize operations to maximize profitability.</Typography>
          </Box>
        </Box>
        
        <Grid className='form_container' item xs={12} sx={{width: "70%", backgroundColor:'white', padding: '40px 20px', borderRadius:'15px', minHeight: '85vh', display: 'flex', flexDirection: 'column'}}> 
            <ContentStyle>
          <Box sx={{ mb: 5}} >
          <Stack sx={{ mb: 2 }}>
              <Typography className="header_signup" sx={{ display: 'flex', alignItems: 'center', fontSize: '30px', fontWeight:'bold' }}>
                Welcome - <Logo />
              </Typography>
            </Stack>
            <Typography sx={{ color: 'text.secondary' }}>
            Create your account in seconds.
            </Typography>
          </Box>

      {/* <AuthSocial /> */}

          <SignUpForm />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3}}>
            By registering, I agree to Minimal&nbsp;
            <Box component="a" href="https://oaza.energy/terms-of-services" target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary', textDecoration: 'underline' }}>
              Terms of Service
            </Box>
            &nbsp;and&nbsp;
            <Box component="a" href="https://oaza.energy/privacy-policy" target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary', textDecoration: 'underline' }}>
              Privacy Policy
            </Box>
            .
          </Typography>

          <Box width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, color:"#818181" }}>
              Already a member?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </Box>
        </ContentStyle>
        </Grid>

      </Box>


      {/* <MHidden width="mdDown">
        <SectionStyle>
                 </SectionStyle>
      </MHidden>
    */}
    {/* <Container>
        <ContentStyle></ContentStyle>
      </Container> */}
      </Box>
    </RootStyle>
  );
}
