import axios from "../util/axios";
import Token from "../util/Token";

// list charger point
async function getusers() {
  Token.setAuthToken();
  return await axios.get("/cms/v1/users");
}

const chargerService = {
  getusers,
};
export default chargerService;
