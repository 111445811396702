/**
 * @author AshutoshMaurya
 */

import setAuthorizationToken from './setAuthorizationToken';

const Token = {
  set: (val) => {
    localStorage.setItem('_flst', JSON.stringify(val));
  },
  setTeam: (val) => {
    localStorage.setItem('_tms', JSON.stringify(val));
  },
  getTeam: () => {
    const item = localStorage.getItem('_tms');
    return JSON.parse(item);
  },
  get: (key) => {
    const item = localStorage.getItem('_flst');
    return JSON.parse(item);
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
  parsedToken: () => {
    const res = {
      success: false,
      data: null,
      message: ''
    };
    let tokenArray = [];
    const token = Token.get('_flst');

    if (!token) {
      res.success = false;
      res.message = 'Invalid or No tiken passed';
      res.data = '';
      return res;
    }
    tokenArray = token.split('.');
    if (tokenArray.length < 2) {
      res.success = false;
      res.message = 'Invalid or No tiken passed';
    }
    res.success = true;
    res.message = 'Token parsed successfully';
    res.data = JSON.parse(window.atob(tokenArray[1]));
    return res;
  },

  setAuthToken: () => {
    setAuthorizationToken(Token.get('_flst'));
  }
};

export default Token;
